import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Link } from 'react-router-dom';
import logo from '../../specto_logos/Single_logo.png'
import Footer from './Footer';
import SearchBar from '../Articles/SearchBar';
import Header from './Header';

const ContactSupportForm = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [product, setProduct] = useState('');
  const [priority, setPriority] = useState('');
  const [message, setMessage] = useState('');

  const products = ['Claims', 'Patient Files', 'AI', 'Auth', 'Other'];
  const priorities = ['Low', 'Medium', 'High'];


    // Handle form submission within the component
    const handleSubmit = (event) => {
      event.preventDefault();
      // Call the onSubmit passed from parent with the form data
      onSubmit(event, { email, subject, product, priority, message });
    };

  return (
    <div className="modal">
    <div className="modal-content">
    <button onClick={onClose} className="filesCloseButton">X</button>
      <form onSubmit={handleSubmit}>
      <div className="input-group-row">
            <div className="input-field">
        <label htmlFor="email">Your Email:</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <br />
        </div>
                </div>
                <div className="input-group-row">
            <div className="input-field">
        <label htmlFor="subject">Subject:</label>
        <input
          id="subject"
          type="text"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          required
        />
        <br />
        </div>
                </div>
        <label htmlFor="product">Product:</label>
        <select id="product" value={product} onChange={(event) => setProduct(event.target.value)} required>
          <option value="">Select a product</option>
          {products.map((prod) => (
            <option key={prod} value={prod}>
              {prod}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="priority">Priority:</label>
        <select id="priority" value={priority} onChange={(event) => setPriority(event.target.value)} required>
          <option value="">Select priority level</option>
          {priorities.map((prio) => (
            <option key={prio} value={prio}>
              {prio}
            </option>
          ))}
        </select>
        <br />
                <div className="input-group-row">
            <div className="input-field">
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          rows="4"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          required
        ></textarea>
        <br />
        </div>
                </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
    </div>
  );
};

const SupportPage = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleOpenForm = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  const handleSubmit = (event, formData) => {
    event.preventDefault();
    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, 'sendEmailSupport'); 

    // Immediately close the form
    handleCloseForm();

    // Send the email without waiting for it to complete
    sendEmail(formData)
      .then(() => {
        setSubmitSuccess(true);
      })
      .catch((error) => {
        console.error('Error sending email: ', error);
        setSubmitSuccess(false);
      });
  };

  return (
    <>
     <Header />
      <div className="support-container">
        <h2>Search for support topics</h2>
        <SearchBar category="support" />
        <h4>Find some help by searching for useful articles or tutorials.</h4>
        <button onClick={handleOpenForm}>Contact Support</button>
        {isFormVisible && ReactDOM.createPortal(
          <ContactSupportForm onClose={handleCloseForm} onSubmit={handleSubmit} />,
          document.body
        )}
        {submitSuccess && <p>Thank you for contacting us. Our support team will get back to you shortly.</p>}
      </div>
      <Footer />
    </>
  );
};

export default SupportPage;
