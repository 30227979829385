import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../../firebase.js";
import Hex from '../Hex.jsx';
import AuthDetails from '../../Auth/AuthDetails.jsx';
import styles from './HomePage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faThumbsUp, faFileCircleCheck, faHeart, faBrain, faFile, faTablet, faBedPulse, faDollar } from '@fortawesome/free-solid-svg-icons';
import Footer from "./Footer.jsx";
import Topbar from "../Topbar.jsx";
import HexagonSpinner from "../Animations/Hexspinner.jsx";
import useUID from '../useUID.jsx'


const HomePage = ({ onToggleTheme }) => {
  const [uid, subUserUID] = useUID();
  const [userRole, setUserRole] = useState(null);
  const [state] = React.useReducer(reducer, {
    board: createBoard(),
    currentSide: "A"
  });
  const navigate = useNavigate();
  const user = auth.currentUser;
  const now = new Date();
  const currentHour = now.getHours();
  let greeting;
  if (currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Name, setName] = useState('');

  
function createBoard() {
  const rowLengthList = userRole === 'nurse' ? [2, 2] : [2, 3];
  return rowLengthList.map(length => new Array(length).fill(0));
}

function put(board, rowIndex, cellIndex, side) {
  const newBoard = board.map(row => [...row]);
  newBoard[rowIndex][cellIndex] = side;
  return newBoard;
}

function changeSide(side) {
  return side === "A" ? "B" : "A";
}

function reducer(state, action) {
  switch (action.type) {
    case "put":
      return {
        ...state,
        board: put(
          state.board,
          action.payload.rowIndex,
          action.payload.cellIndex,
          state.currentSide
        ),
        currentSide: changeSide(state.currentSide)
      };
    default:
      return state;
  }
}

  useEffect(() => {
    if (!uid) return; // do not proceed if uid is not available
    let unsubscribeUser, unsubscribeSubUser;
  
    if (uid) {
        const userDetailsRef = doc(db, "users", uid);
        unsubscribeUser = onSnapshot(userDetailsRef, (doc) => {
            if (doc.exists()) {
                setUserDetails(doc.data());
                // Only set the main user's name if there's no subUserUID
                if (!subUserUID) {
                    setName(doc.data().Name);
                }
                setLoading(false);
            }
        });
  
        if (subUserUID) {
          const subUserDetailsRef = doc(db, "users", uid, "subusers", subUserUID);
          unsubscribeSubUser = onSnapshot(subUserDetailsRef, (subDoc) => {
            if (subDoc.exists()) {
              const subUserData = subDoc.data();
              setUserDetails(prevState => ({
                  ...prevState,
                  Name: subUserData.Name // Update name to the subuser's name
              }));
              setUserRole(subUserData.role); // Update the role
              setName(subUserData.Name); // Set the name to the sub-user's name
            }
          });
      }
    }
  
    return () => {
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeSubUser) unsubscribeSubUser();
    };
  }, [uid, subUserUID]);

  return (
    <div className={styles.background}>
      <Topbar />
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div className={styles.title}>
            <h2 >{`${greeting}, ${userDetails?.Name ?? "."}.`}</h2>
          </div>

          {loading ? ( // show blank white screen while loading
            <div style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}></div>
          ) : (
            state.board.map((row, rowIndex) => {
              return (
                <div
                  style={{
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  key={`row-${rowIndex}`}
                >
                  {row.map((side, cellIndex) => (
                   userRole !== 'nurse' || (userRole === 'nurse' && !(rowIndex === 1 && cellIndex === 2)) ? 

                    <Hex
                      key={`${rowIndex}-${cellIndex}`}
                      side={side}
                      onClick={                  () => {
                        if (rowIndex === 0 && cellIndex === 0) {
                          navigate('/monitoring');
                        } else if (rowIndex === 0 && cellIndex === 1) {
                          navigate('/patients');
                        } else if (rowIndex === 1 && cellIndex === 0) {
                          navigate('/ai');
                        } else if (rowIndex === 1 && cellIndex === 2) {
                          navigate('/claims');
                          
                        } else if (rowIndex === 1 && cellIndex === 1) {
                          navigate('/');
                        } else {
                          return;
                        }
                      }}
                    >
                      {rowIndex === 0 && cellIndex === 0 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faBedPulse}
                            style={{ height: "40px" }}
                          />
                          <h2>Data Monitoring</h2>
                          <p>Monitor all patient devices</p>
                        </div>
                      )}
                      {rowIndex === 0 && cellIndex === 1 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{ height: "40px" }}
                          />
                          <h2>Patients</h2>
                          <p>Manage your patients</p>
                        </div>
                      )}
                      {rowIndex === 1 && cellIndex === 0 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faBrain}
                            style={{ height: "40px" }}
                          />
                          <h2>AI</h2>
                          <p>Healthcare AI</p>
                        </div>
                      )}
                       {rowIndex === 1 && cellIndex === 1 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faTablet}
                            style={{ height: "40px" }}
                          />
                          <h2>Device Ordering</h2>
                          <p>Order RPM Devices</p>
                        </div>
                      )}
                      {rowIndex === 1 && cellIndex === 2 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faDollar}
                            style={{ height: "40px" }}
                          />
                          <h2>Billing</h2>
                          <p>All Billing related tools.</p>
                        </div>
                      )}
                      
                    </Hex>
                   : null

                  ))}
                </div>
              );
            })
          )}
        </div>
      </div>
      <Footer />
    </div>
    );
  };
  
  export default HomePage;
