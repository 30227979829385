import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import '../PatientFiles.modules.css';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteDoc, doc } from 'firebase/firestore';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import useUID from '../../General/useUID'

function PayerMatchingModal({ onClose }) {
    const [payerMatches, setPayerMatches] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [claimsPayers, setClaimsPayers] = useState([]);
    const [filteredPayers, setFilteredPayers] = useState([]);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [payerName, setPayerName] = useState("");
    const [showSearch, setShowSearch] = useState(false); // State to toggle search bar and payers table
    const [showPayerList, setShowPayerList] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [uid, subUserUID] = useUID();

    useEffect(() => {
        if (!uid) return;
        fetchClaimsPayers();
        fetchPayerMatches();
    }, [uid]);
    
     
    

    const fetchClaimsPayers = async () => {    
        setIsLoading(true);
        const claimsPayersCollection = collection(db, 'Payers');
        const claimsPayersSnapshot = await getDocs(claimsPayersCollection);
        const claimsPayersData = claimsPayersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClaimsPayers(claimsPayersData);
        setFilteredPayers(claimsPayersData);
        setIsLoading(false);
    };
    

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.trim() === "") {
            setFilteredPayers(claimsPayers);
            return;
        }
        const lowerCaseTerm = e.target.value.toLowerCase().trim();
        const filteredList = claimsPayers.filter(payer => {
            if (!payer.payerName) return false;
            return payer.payerName.toLowerCase().includes(lowerCaseTerm);
        });
        
        
        setFilteredPayers(filteredList);
    };
    
    
        
        

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check for both selected payer and payer name
        if (!selectedPayer) {
            alert("Please select a payer.");
            return;
        }
        
        if (!payerName || !payerName.trim()) {
            alert("Please provide a name for the matched payer.");
            return;
        }
    
        // Include the payer's document ID in the data to be saved
        await addDoc(collection(db, `users/${uid}/payerMatches`), {
            name: payerName.trim(),
            payer: {
                payerName: selectedPayer.payerName, 
                CPID: selectedPayer.CPID, // Assuming this is the structure of your payer object
            }
        });
        
        fetchPayerMatches();
        setSelectedPayer(null);
        setPayerName("");
        setShowSearch(false);
        setSearchTerm("");
        setShowPayerList(true);
    };
    
    
    
    
    const fetchPayerMatches = async () => {
        setIsLoading(true);
        const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
        const payerMatchesSnapshot = await getDocs(payerMatchesCollection);
        const payerMatchesData = payerMatchesSnapshot.docs.map(doc => ({
            ...doc.data(), 
            id: doc.id, 
            CPID: doc.data().payer.CPID, // Include CPID
        }));
        setPayerMatches(payerMatchesData);
        setIsLoading(false); 
    };



    const handleDelete = async (matchId) => {
        const matchRef = doc(db, `users/${uid}/payerMatches`, matchId);
    
        try {
            await deleteDoc(matchRef);
            fetchPayerMatches();  // Refresh payer matches after deletion
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='payerMatchContent'>
                <button className='matchCloseButton' onClick={onClose}>X</button>
                <h2>Payer Matching</h2>
                <button style={{ float: 'right', marginLeft: '4rem' }} onClick={() => {
                setShowSearch(!showSearch);
                if (showSearch) { // Reset when Cancel is clicked
                    setShowPayerList(true);
                }
            }}>
                {showSearch ? "Cancel" : "Add Match"}
            </button>
            <p>Add matches to upload patient's payers. In the PAYERS column of the XLSX, add the Match Name and the system will connect them to the matched payer. </p>

            {/* Only show table when showSearch is false */}
            {!showSearch && (
                <div className='payerMatchedTable'>
                <table className='payerMatchedTable'>
                    <thead>
                        <tr>
                            <th>Name in Spreadsheet</th>
                            <th>Name in specto</th>
                        </tr>
                    </thead>
                    {isLoading ? (
                <HexagonSpinner />
            ) : (
                    <tbody>
                        {payerMatches.map(match => (
                            <tr key={match.id}>
                                <td>{match.name}</td>
                                <td>{match.payer.payerName}</td>
                                <td>
                                    <FontAwesomeIcon 
                                        icon={faTrash} 
                                        onClick={() => handleDelete(match.id)} 
                                        style={{cursor: 'pointer', color: 'red'}}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                      )}

                </table>
                
                </div>
            )}

                            
                {showSearch && (
                    <div className='payerMatchSearch'>
                        {!selectedPayer && ( // Check if payer hasn't been selected
                            <input 
                                placeholder="Search for payers..." 
                                value={searchTerm} 
                                onChange={handleSearchChange} 
                            />
                        )}

                        {showPayerList ? (
                            <div className='payerSearchTable'>
                                {filteredPayers.map((payer, index) => (
                                    <li key={index} onClick={() => { 
                                        setSelectedPayer(payer); 
                                        setShowPayerList(false); 
                                    }}>
                                        {payer.payerName}
                                    </li>
                                ))}
                            </div>
                        ) : (
                            <button onClick={() => setShowPayerList(true)}>Change</button>
                        )}

                        {selectedPayer && (
                            <form onSubmit={handleSubmit} className='payerNameForm'>
                                <label>
                                    Name in your spreadsheet:
                                    <input 
                                        value={payerName} 
                                        onChange={(e) => setPayerName(e.target.value.toUpperCase())}
                                        placeholder={`Name for ${selectedPayer.payerName}`} 
                                    />
                                </label>
                                <button type="submit">Save Match</button>
                            </form>
                        )}
                    </div>
                )}

            </div>
        </div>
        </div>
    );
}

export default PayerMatchingModal;