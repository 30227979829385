import Topbar from "../General/Topbar";
import BatchStatus from "./BatchStatus";
import "./Claimstatus.css";
import ClaimStatusFileBrowser from "./ClaimStatusFileBrowser";

function Claimstatus() {
  return (
    <div>
     <Topbar/>
     <ClaimStatusFileBrowser />
     <BatchStatus />
    </div>
  );
}

export default Claimstatus;