import React, { useState, useEffect } from 'react';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from '../../General/useUID';
import ClaimStatusCategoryFlag from '../../ClaimStatus/ClaimStatusCategoryFlag';

const StatusSummary = () => {
  const [loading, setLoading] = useState(true);
  const [statusSummary, setStatusSummary] = useState({});
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    const fetchStatusSummary = async () => {
      setLoading(true);
      const functions = getFunctions();
      const aggregateClaimsStatusSummary = httpsCallable(functions, 'aggregateClaimsStatusSummary');

      try {
        const response = await aggregateClaimsStatusSummary({ uid });
        setStatusSummary(response.data);
      } catch (error) {
        console.error("Error fetching status summary:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatusSummary();
  }, [uid]);

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Aggregating your claim statuses.</h4>
      </div>
    );
  }

  return (
    <div className="status-summary-container">
      <h3 style={{ textAlign: 'center' }}>Claims Status Summary</h3>
      {Object.entries(statusSummary).map(([code, count]) => (
        <div key={code} className="status-summary-item">
          <ClaimStatusCategoryFlag statusCategoryCode={code} />
          <span>: {count}</span>
        </div>
      ))}
    </div>
  );
};

export default StatusSummary;
