import React, { useState } from "react";
import Switch from "react-switch";

const InputTypeChoice = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange,
  formElementId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    fieldItem.value ? fieldItem.value.split(", ").filter(Boolean) : []
  );

  const handleOptionChange = (option) => {
    if (fieldItem.multiple) {
      //Multiple choice:
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      // Single choice: Enforce only one option selected
      if (selectedOptions.includes(option)) {
        setSelectedOptions([]); // Deselect if the option was already selected
      } else {
        setSelectedOptions([option]);
      }
    }

    // Update input value
    const inputName = formElementId ?? fieldItem.name; // Use formElementId if provided, otherwise use fieldItem.name
    handleInputChange({
      target: {
        name: inputName,
        value: selectedOptions.join(", "),
      },
    });
  };

  return (
    <>
      {isVisible && (
        <div>
          <label>
            {fieldItem?.label}
            <span className="RequiredMark">
              {fieldItem?.required && " *"}
            </span>
            <span className="MultipleIndicator">{fieldItem.type}</span>
          </label>
          <div>
            {Array.isArray(fieldItem?.options) && fieldItem?.options.length > 0 ? (
              fieldItem?.options.map((option, index) => (
                <div key={index} className="ChoiceOption">
                  <Switch
                    disabled={preview}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleOptionChange(option)}
                    offColor="#ccc"
                    onColor="#00316f"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  <label className="pt-3 ml-3">{option}</label>
                </div>
              ))
            ) : (
              <div>No options available</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InputTypeChoice;