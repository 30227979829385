import React, { Component } from 'react';

const DEG_TO_RAD = Math.PI / 180;

// Vector2 class definition
class Vector2 {
    constructor(_x, _y) {
        this.x = _x;
        this.y = _y;
    }

    Length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    Add(_vec) {
        this.x += _vec.x;
        this.y += _vec.y;
    }

    Sub(_vec) {
        this.x -= _vec.x;
        this.y -= _vec.y;
    }

    Div(_f) {
        this.x /= _f;
        this.y /= _f;
    }

    Mul(_f) {
        this.x *= _f;
        this.y *= _f;
    }

    Normalize() {
        var sqrLen = this.x * this.x + this.y * this.y;
        if (sqrLen !== 0) {
            var factor = 1.0 / Math.sqrt(sqrLen);
            this.x *= factor;
            this.y *= factor;
        }
    }
}

class ConfettiPaper {
    constructor(_x) {  // Removed _y from constructor parameters
        this.pos = new Vector2(_x, Math.random() * 10);
        this.colors = [
            ["#df0050", "#3abada"],
            ["#00e860", "#206274"],
            ["#2beabc", "#05798a"],
            ["#ffd200", "#b06c00"],
        ];
        this.rotationSpeed = Math.random() * 600 + 800;
        this.angle = Math.random() * 360;
        this.rotation = Math.random() * 360;
        this.cosA = 1.0;
        this.size = 5.0;
        this.oscillationSpeed = Math.random() * 1.5 + 0.5;
        this.xSpeed = 40.0;
        this.ySpeed = Math.random() * 60 + 50.0;
        this.corners = new Array();
        this.time = Math.random();
        var ci = Math.round(Math.random() * (this.colors.length - 1));
        this.frontColor = this.colors[ci][0];
        this.backColor = this.colors[ci][1];
        for (var i = 0; i < 4; i++) {
            var dx = Math.cos(this.angle + DEG_TO_RAD * (i * 90 + 45));
            var dy = Math.sin(this.angle + DEG_TO_RAD * (i * 90 + 45));
            this.corners[i] = new Vector2(dx, dy);
        }
    }

    Update(_dt) {
        this.time += _dt;
        this.rotation += this.rotationSpeed * _dt;
        this.cosA = Math.cos(DEG_TO_RAD * this.rotation);
        this.pos.x += Math.cos(this.time * this.oscillationSpeed) * this.xSpeed * _dt;
        this.pos.y += this.ySpeed * _dt;
        if (this.pos.y > ConfettiPaper.bounds.y) {
            this.pos.x = Math.random() * ConfettiPaper.bounds.x;
            this.pos.y = 0;
        }
    }

    Draw(_g) {
        if (this.cosA > 0) {
            _g.fillStyle = this.frontColor;
        } else {
            _g.fillStyle = this.backColor;
        }
        _g.beginPath();
        _g.moveTo(this.pos.x + this.corners[0].x * this.size, this.pos.y + this.corners[0].y * this.size * this.cosA);
        for (var i = 1; i < 4; i++) {
            _g.lineTo(this.pos.x + this.corners[i].x * this.size, this.pos.y + this.corners[i].y * this.size * this.cosA);
        }
        _g.closePath();
        _g.fill();
    }
}

class Confetti extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.confettiPapers = [];
        this.frameRate = 30;
        this.dt = 1.0 / this.frameRate;

        // Bind methods in constructor
        this.setupCanvas = this.setupCanvas.bind(this);
        this.startConfetti = this.startConfetti.bind(this);
        this.updateConfetti = this.updateConfetti.bind(this);
        this.handleResize = this.handleResize.bind(this);

        // Initialize state
        this.state = {
            context: null
        };
    }

    componentDidMount() {
        this.setupCanvas();
        this.startConfetti();

        // Force state change for debugging
        this.setState({ debugUpdate: true });

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
        }
    }

    setupCanvas() {
        const canvas = this.canvasRef.current;
        if (!canvas) {
            console.error("Canvas element is not referenced.");
            return;
        }

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            console.error("Failed to get 2D context from canvas");
            return;
        } else {
            console.log("Context set successfully", ctx);
            this.setState({ context: ctx }); // Set context in state
        }

        ConfettiPaper.bounds = new Vector2(canvas.width, canvas.height);

        let confettiPaperCount = 75;
        for (let i = 0; i < confettiPaperCount; i++) {
            // Start confetti at different heights
            let initialY = Math.random() * canvas.height;
            this.confettiPapers[i] = new ConfettiPaper(Math.random() * canvas.width, initialY);
        }
    }

    startConfetti = () => {
        this.updateConfetti();
    }

    updateConfetti() {
        const canvas = this.canvasRef.current;
        if (!canvas) {
            console.error("Canvas element is not referenced.");
            return;
        }
    
        const context = canvas.getContext('2d');
        if (!context) {
            console.error("Failed to get 2D context from canvas");
            return;
        }
    
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
    
        for (let i = 0; i < this.confettiPapers.length; i++) {
            this.confettiPapers[i].Update(this.dt);
            this.confettiPapers[i].Draw(context);
    
            // Respawn confetti that has fallen off the screen
            if (this.confettiPapers[i].pos.y > canvas.height) {
                this.confettiPapers[i] = new ConfettiPaper(Math.random() * canvas.width, -10);
            }
        }
    
        this.animationFrameId = requestAnimationFrame(this.updateConfetti);
    }
    

    handleResize = () => {
        const canvas = this.canvasRef.current;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        ConfettiPaper.bounds = new Vector2(canvas.width, canvas.height);
    }

    render() {
        const canvasStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000, // Ensure this is high enough to overlay other elements
            pointerEvents: 'none' // Add this line
        };
    
        return <canvas id="myCanvas" ref={this.canvasRef} style={canvasStyle}></canvas>;
    }
}

export default Confetti;
