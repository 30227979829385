import React from 'react';
import styles from './quicksearch.module.css';

function QuicksearchData({ patientData, onClose }) {

  const eligibilityData = patientData?.eligibilityData || {}; 
  
return (
    <div className='results'>
      
    <div className='resultsContainer'>
    <button onClick={onClose} className="filesCloseButton">X</button>

    <div className={styles.patientDataContainer}>
      {eligibilityData && eligibilityData !== "Patient not found" && 
      
      <>

      {eligibilityData.insuranceName && <h2>{eligibilityData.insuranceName}</h2>}
      {eligibilityData.insuranceStatus && <h3>{eligibilityData.insuranceStatus}</h3>}
      {eligibilityData.submemberId && <p>{eligibilityData.submemberId}</p>}

        <div className={styles.patientGridContainer}>
        {eligibilityData.subfirstName && (
          <div>
            <h3>Subscriber</h3>
            {eligibilityData.subfirstName && <p>First Name: {eligibilityData.subfirstName}</p>}
            {eligibilityData.sublastName && <p>Last Name: {eligibilityData.sublastName}</p>}
            {eligibilityData.submiddleName && <p>Middle Name: {eligibilityData.submiddleName}</p>}
            {eligibilityData.submemberId && <p>Member ID: {eligibilityData.submemberId}</p>}
            {eligibilityData.subentityType && <p>Entity Type: {eligibilityData.subentityType}</p>}
            {eligibilityData.subgroupNumber && <p>Group Number: {eligibilityData.subgroupNumber}</p>}
            {eligibilityData.subentityIdentifier && <p>Entity Identifier: {eligibilityData.subentityIdentifier}</p>}
            {eligibilityData.subAddress1 && <h3>Address:</h3>}
            {eligibilityData.subAddress1 && <p>{eligibilityData.subAddress1}</p>}
            {eligibilityData.subCity && <p>{eligibilityData.subCity}</p>}
            {eligibilityData.subState && <p>{eligibilityData.subState}</p>}
            {eligibilityData.subZip && <p>{eligibilityData.subZip}</p>}
          </div>
        )}
          {eligibilityData.depfirstName && (
            <div>
              <h3>Dependent</h3>
              {eligibilityData.depfirstName && <p>First Name (Dependent): {eligibilityData.depfirstName}</p>}
              {eligibilityData.deplastName && <p>Last Name (Dependent): {eligibilityData.deplastName}</p>}
              {eligibilityData.depmiddleName && <p>Middle Name (Dependent): {eligibilityData.depmiddleName}</p>}
              {eligibilityData.depgender && <p>Gender (Dependent): {eligibilityData.depgender}</p>}
              {eligibilityData.depdob && <p>Date of Birth (Dependent): {eligibilityData.depdob}</p>}
              {eligibilityData.depmemberId && <p>Member Id (Dependent): {eligibilityData.depmemberId}</p>}
              {eligibilityData.deprelationToSubscriber && <p>Relation to Subscriber (Dependent): {eligibilityData.deprelationToSubscriber}</p>}
              {eligibilityData.depentityType && <p>Entity Type (Dependent): {eligibilityData.depentityType}</p>}
              {eligibilityData.depgroupNumber && <p>Group Number (Dependent): {eligibilityData.depgroupNumber}</p>}
              {eligibilityData.depentityIdentifier && <p>Entity Identifier (Dependent): {eligibilityData.depentityIdentifier}</p>}
              {eligibilityData.depAddress1 && <p>Member Address (Dependent): {eligibilityData.depAddress1}</p>}
              {eligibilityData.depCity && <p>Member City (Dependent): {eligibilityData.depCity}</p>}
              {eligibilityData.depState && <p>Member State (Dependent): {eligibilityData.depState}</p>}
              {eligibilityData.depZip && <p>Member Zip (Dependent): {eligibilityData.depZip}</p>}
            </div>
            )}
        
        {eligibilityData.planNumber && (
          <div>
            <h3>Plan Info</h3>
            {eligibilityData.planNumber && <p>Plan Number: {eligibilityData.planNumber}</p>}
            {eligibilityData.groupNumber && <p>Group Number: {eligibilityData.groupNumber}</p>}
            {eligibilityData.groupDescription && <p>Group Description: {eligibilityData.groupDescription}</p>}
            {eligibilityData.planDateInformation && <p>Plan Date Information: {eligibilityData.planDateInformation}</p>}
            {eligibilityData.planBegin && <p>Plan Begin: {eligibilityData.planBegin}</p>}
            {eligibilityData.planDetails && <p>Plan Details: {eligibilityData.planDetails}</p>}
            {eligibilityData.serviceTypeCodes && <p>Service Type Codes: {eligibilityData.serviceTypeCodes}</p>}
          </div>
            )}
        {eligibilityData.providerName && (
          <div>
            <h3>Benefits Info</h3>
            {eligibilityData.providerName && <p>Provider Name: {eligibilityData.providerName}</p>}
            {eligibilityData.coverageLevel && <p>Coverage Level: {eligibilityData.coverageLevel}</p>}
            {eligibilityData.insuranceType && <p>Insurance Type: {eligibilityData.insuranceType}</p>}
            {eligibilityData.relatedEntityName && <p>Related Entity Name: {eligibilityData.relatedEntityName}</p>}
            {eligibilityData.relatedEntityAddress1 && <h3>Related Entity Address:</h3>}
            {eligibilityData.relatedEntityAddress1 && <p>{eligibilityData.relatedEntityAddress1}</p>}
            {eligibilityData.relatedEntityCity && <p>{eligibilityData.relatedEntityCity}</p>}
            {eligibilityData.relatedEntityState && <p>{eligibilityData.relatedEntityState}</p>}
            {eligibilityData.relatedEntityPostalCode && <p>{eligibilityData.relatedEntityPostalCode}</p>}
          </div>
            )}
        {eligibilityData.errorMessage && (

          <div>
          <h3>Errors</h3>
            {eligibilityData.errorDesc && <p>Error Description: {eligibilityData.errorDesc}</p>}
            {eligibilityData.errorAction && <p>Error Action: {eligibilityData.errorAction}</p>}
            {eligibilityData.errorResolution && <p>Error Resolution: {eligibilityData.errorResolution}</p>}
            {eligibilityData.errorCode && <p>Error Code: {eligibilityData.errorCode}</p>}
            {eligibilityData.errorMessage && <p>Error Message: {eligibilityData.errorMessage}</p>}
          </div>
                      )}

        </div>
        
        </>
      }
    </div>
    </div>

    </div>

  );
}

export default QuicksearchData;
