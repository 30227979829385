import React, { useState, useEffect } from 'react';
import { auth } from "../../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import useUID from '../../General/useUID'

const Summary = () => {
  const [loading, setLoading] = useState(true);
  const [totalClaimsAmount, setTotalClaimsAmount] = useState(0);
  const [totalClaimsCount, setTotalClaimsCount] = useState(0);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const functions = getFunctions();
    const aggregateClaimsSummary = httpsCallable(functions, 'aggregateClaimsSummary');

    aggregateClaimsSummary({ uid })
      .then((result) => {
        const { totalAmount, claimsCount } = result.data;
        setTotalClaimsAmount(totalAmount);
        setTotalClaimsCount(claimsCount);
      })
      .catch((error) => {
        console.error("Error fetching claims summary:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uid]);

  // Function to format number with commas
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(number);
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Calculating your account summary.</h4>
      </div>
    );
}

  return (
    <div className="summary-container">
      <h3 style={{ textAlign: 'center' }}>Claims Summary</h3>
      
      <div className="summary-item">
        <p>Total Sent Claims Amount:</p>
        <h3>${formatNumber(totalClaimsAmount.toFixed(2))}</h3>
      </div>
  
      <div className="summary-item">
        <p>Total Claims Sent:</p>
        <h3>{totalClaimsCount}</h3>
      </div>
    </div>
  );
}

export default Summary;
