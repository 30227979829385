import React from "react";

function ValidateSubform(formData, formInputValues) {
  for (const input of formData) {
    const inputId = input.id;
    const inputValue = formInputValues[inputId]?.value;

    // Check for missing entry entirely:
    if (!formInputValues[inputId]) {
      if (input.fields[0].required) {
        return {
          isValid: false,
          message: `Please fill in the required field: ${input.fields[0].label}`,
        };
      }
    } else {
      // Check for missing or empty value if 'required' is true:
      if (
        input.fields[0].required &&
        (!inputValue || inputValue.trim() === "")
      ) {
        return {
          isValid: false,
          message: `Please fill in the required field: ${input.fields[0].label}`,
        };
      }
    }

    if (input.fields[0].validation && input.fields[0].validation !== "None") {
      const validationType = input.fields[0].validation;
      const valueToValidate = inputValue || "";

      // If value is null or undefined, treat it as an empty string
      switch (validationType) {
        case "Alphabetic":
          if (!/^[a-zA-Z]+$/.test(valueToValidate)) {
            return {
              isValid: false,
              message: `Invalid input for ${input.fields[0].label}. Only alphabetic characters are allowed.`,
            };
          }
          break;
        case "AlphaNumeric":
          if (!/^[a-zA-Z0-9]+$/.test(valueToValidate)) {
            return {
              isValid: false,
              message: `Invalid input for ${input.fields[0].label}. Only alphanumeric characters are allowed.`,
            };
          }
          break;
        case "Numeric":
          if (!/^[0-9]+$/.test(valueToValidate)) {
            return {
              isValid: false,
              message: `Invalid input for ${input.fields[0].label}. Only numeric characters are allowed.`,
            };
          }
          break;
        case "Email":
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueToValidate)) {
            return {
              isValid: false,
              message: `Invalid email address for ${input.fields[0].label}. Please enter a valid email address.`,
            };
          }
          break;
        default:
          break;
      }
    } else if (input.fields[0].type === "phone") {
      const valueToValidate = inputValue || "";
      // If the field is required and the input value is not empty, validate the phone number format
      if (input.fields[0].required && valueToValidate.trim() !== "") {
        if (
          !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
            valueToValidate
          )
        ) {
          return {
            isValid: false,
            message: `Invalid phone number for ${input.fields[0].label}. Please enter a valid phone number.`,
          };
        }
      }
    }
  }

  return { isValid: true };
}

export default ValidateSubform;