
import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../../../../firebase";
import '../../AddPatient/AddPatient.modules.css';
import SmallLogo from "../../../Auth/smalllogo";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  } from '@fortawesome/free-solid-svg-icons';


const functions = getFunctions();

function PersonalInformation() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });

  const [uid, setUid] = useState(null);
  const [requiredFields, setRequiredFields] = useState({ address: false, payer: false, addInsuranceCardCamera: false, requirePhone: false });

return (
    <>
{/* Personal Information */}
    <div className="centerHeader">
            <h3>Patient</h3>
            </div>

            <div className="input-group-row">
                <div className="input-field">
                <label htmlFor="firstName">First Name</label>
                <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                </div>

                <div className="input-field">
                <label htmlFor="lastName">Last Name</label>
                <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                </div>
            </div>



            {/* Date of Birth and Gender */}
            <div className="input-group">
                <label htmlFor="dob">Date of Birth</label>
                <input
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                />
                <div className="gender-buttons">
                <button
                    type="button"
                    className={`gender-button ${gender === "M" ? "selected" : ""}`}
                    onClick={() => setGender("M")}
                >
                    M
                </button>
                <button
                    type="button"
                    className={`gender-button ${gender === "F" ? "selected" : ""}`}
                    onClick={() => setGender("F")}
                >
                    F
                </button>
                </div>
            </div>
                    {/* Address */}
                    <div className="input-group-row">
                        {/* Address 1 and 2 */}
                        <div className="input-field">
                        <label htmlFor="address1">Address line 1</label>
                        <input
                            type="text"
                            name="address1"
                            value={address.address1}
                            onChange={(e) =>
                            setAddress({ ...address, address1: e.target.value })
                            }
                            required={requiredFields.address}
                        />
                        </div>

                        <div className="input-field">
                        <label htmlFor="address2">Address line 2</label>
                        <input
                            type="text"
                            name="address2"
                            value={address.address2}
                            onChange={(e) =>
                            setAddress({ ...address, address2: e.target.value })
                            }
                        />
                        </div>
                    </div>
                    <div className="input-group-row">
                        {/* City, State, and Zip */}
                        <div className="input-field">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            name="city"
                            value={address.city}
                            onChange={(e) => setAddress({ ...address, city: e.target.value })}
                            required={requiredFields.address}
                            />
                        </div>

                        <div className="input-field">
                        <label htmlFor="state">State</label>
                        <input
                            type="text"
                            name="state"
                            maxLength="2"
                            pattern="^[A-Z]{2}$"
                            value={address.state}
                            onChange={(e) => setAddress({ ...address, state: e.target.value })}
                            className="stateInput"
                            required={requiredFields.address}
                            />
                        </div>

                        <div className="input-field">
                        <label htmlFor="zip">Zip</label>
                        <input
                            type="text"
                            name="zip"
                            maxLength="5"
                            pattern="^\d{5}$"
                            value={address.zip}
                            onChange={(e) => setAddress({ ...address, zip: e.target.value })}
                            className="zipInput"
                            required={requiredFields.address}
                            />
                        </div>
                    </div>


            <div className="input-group-row">
            <div className="input-field">
                <label htmlFor="email">Email (optional)</label>
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                </div>
                <div className="input-field">
                <label htmlFor="phone">Phone</label>
                <InputMask
                    mask="(999) 999-9999"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="(000)-000-0000"
                    required={requiredFields.phone}
                >
                    {(inputProps) => <input {...inputProps} type="text" name="phone" />}
                </InputMask>
                </div>
                </div>
        </>
    )
}
export default PersonalInformation;