import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../../../../firebase";
import { collection, addDoc, updateDoc, doc, getDoc, arrayUnion, setDoc, getDocs, where, query } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import PayerSelection from '../../../Payers/PayerSelection';
import '../../AddPatient/AddPatient.modules.css';
import SmallLogo from "../../../Auth/smalllogo";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const functions = getFunctions();

function InsuranceInformation() {
  let { clinic } = useParams() || {};

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [payers] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [primaryDoctor, setPrimaryDoctor] = useState("");
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientNote, setPatientNote] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [firstThree, setFirstThree] = useState("");
  const [lastFour, setLastFour] = useState("");
  const [uid, setUid] = useState(null);
  const [token, setToken] = useState(null);
  const [requiredFields, setRequiredFields] = useState({ address: false, payer: false, addInsuranceCardCamera: false, requirePhone: false });
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [showVideoPreview, setShowVideoPreview] = useState(false);


const handlePayerSelect = (payer) => {
    setSelectedPayer(payer);
  };

  const handlePayerUnselect = () => {
    setSelectedPayer(null);
  };


  const checkCameraPermission = async () => {
    if (navigator.permissions) {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });
        if (permissionStatus.state === 'denied') {
          alert('Camera permission has been denied. Please allow camera access in browser settings.');
        }
        return permissionStatus.state;
      } catch (error) {
        console.error('Error checking camera permission:', error);
      }
    } else {
      console.warn('Permissions API is not supported on this browser.');
      return 'unknown';
    }
  };

  const onCameraClick = async () => {
    if (isMobile) {
        const videoElement = document.getElementById('videoPreview');
        try {
            const constraints = {
                video: {
                    facingMode: "environment"
                }
            };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);

            videoElement.onloadedmetadata = (e) => {
                videoElement.play();
            };

            videoElement.srcObject = stream;

            videoElement.addEventListener('error', (errorEvent) => {
              console.error('Video element error:', errorEvent);
            });

            stream.getVideoTracks()[0].addEventListener('ended', () => {
                console.warn('The video track ended unexpectedly!');
            });
            
            // Make the video element and capture button visible
            videoElement.style.display = 'block';
            document.getElementById('captureButton').style.display = 'block'; 

                // Change the display styles accordingly
            document.getElementById('startCameraButton').style.display = 'none';
            document.getElementById('videoPreview').style.display = 'block';
            document.getElementById('captureButton').style.display = 'block';

        } catch (error) {
            console.error("Error accessing camera:", error);
            alert("Couldn't access the camera. Please enable camera permissions in the browser settings.");
        }
    } else {
        document.getElementById("fileInput").click();
    }
};


const capturePhoto = () => {
  const videoElement = document.getElementById('videoPreview');
  const canvas = document.createElement('canvas');
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  const context = canvas.getContext('2d');
  context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  canvas.toBlob(blob => {
    // Convert blob to File
    const capturedFile = new File([blob], "InsuranceCardfromIntake.jpeg", { type: "image/jpeg" });

    // Set the file
    setFile(capturedFile);

    // Convert the blob to Data URL for preview
    const dataURL = URL.createObjectURL(blob);
    setPreviewImage(dataURL);
  }, 'image/jpeg');

  // Stop the video stream
  const tracks = videoElement.srcObject.getTracks();
  tracks.forEach(track => track.stop());

    // After capturing the image, hide the video preview and capture button
    document.getElementById('videoPreview').style.display = 'none';
    document.getElementById('captureButton').style.display = 'none';
};

  
  const handleFileChange = (file) => {
    if (file) {      
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

return (
    <>
    <div className="centerHeader">
        <h3>Insurance</h3>
        </div>
          {/* Member ID */}
          <div className="input-group-row">
            <div className="input-field">
                <label htmlFor="memberId">Member ID</label>
                <input
                    type="text"
                    name="memberId"
                    value={memberId}
                    onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                        setMemberId(sanitizedValue);
                    }}
                    required={requiredFields.payer}
                />
            </div>
        </div>


          {/* Payer Selection */}
          <div className="centerHeader">
          <h3>Select an insurance payer.</h3>
          </div>
          <div className="input-group-payerSelection">
            {!selectedPayer && (
              <PayerSelection
               mode={'claims'}
                onSelect={handlePayerSelect}
                required={requiredFields.payer}
                />
            )}
            {selectedPayer && (
                        <div className='payerSelectorContainer'>
                        <h3>Selected Payer:</h3>
                <p>{selectedPayer.name}</p>
                <button onClick={handlePayerUnselect}>Change</button>
              </div>
            )}
          </div>
          <div className="input-group">
              {isMobile ? (
                  <>
                      {previewImage ? (
                              <div className="preview-image-container">
                              {/* This will show the captured image */}
                              <img src={previewImage}  id="previewImage" alt="Preview" />
                              <button onClick={() => setPreviewImage(null)}>
                                  <FontAwesomeIcon icon={faCamera} />
                                  Retake Photo of Insurance ID Card
                              </button>
                              </div>
                      ) : (
                          <>
                              {/* Video element for live preview */}
                              <video id="videoPreview" width="500" height="600" autoplay muted playsInline style={{ display: 'none' }}></video>

                              <button id="startCameraButton" onClick={onCameraClick}>
                                  <FontAwesomeIcon icon={faCamera} />
                                  Take picture of Insurance ID Card
                              </button>

                              <button id="captureButton" onClick={capturePhoto} style={{ display: 'none' }}>
                                  Capture
                              </button>
                          </>
                      )}

                      <input 
                          type="file" 
                          id="fileInput"
                          style={{ display: 'none' }} 
                          accept="image/*" 
                          capture="camera" 
                          onChange={(e) => {
                              if (e.target.files && e.target.files.length > 0) {
                                  handleFileChange(e.target.files[0]);
                              }
                          }}
                      />
                  </>
              ) : (
                  <>
                      <input 
                          type="file" 
                          id="fileInput"
                          style={{ display: 'none' }} 
                          accept="image/*" 
                          capture="camera" 
                          onChange={(e) => {
                              if (e.target.files && e.target.files.length > 0) {
                                  handleFileChange(e.target.files[0]);
                              }
                          }}
                      />

                      <button 
                          onClick={onCameraClick} 
                          className="cameraButton"
                      >
                          <FontAwesomeIcon icon={faCamera} />
                          {file ? ' Change Photo' : ' Take/Select Photo of Insurance Card'}
                      </button>
                  </>
              )}
          </div>
          </>
    )
}

export default InsuranceInformation;