import React, { useState, useEffect } from 'react';
import SingleStatus from "../../ClaimStatus/SingleStatus";
import '../PatientFiles.modules.css';

function PatientFilesClaimStatus({ firstName, lastName, dob, gender, memberid, patientId, onClose, tradingPartnerName }) {

  function convertDobFormat(dob) {
    if (!dob) return '';
    const [month, day, year] = dob.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  return (
    <div className='actionContainer'>
      <div className="ClaimContainer">
        <button onClick={onClose} className="filesCloseButton">X</button>
        <SingleStatus
          initialFirstName={firstName}
          initialLastName={lastName}
          initialDob={convertDobFormat(dob)}
          initialGender={gender}
          initialmemberid={memberid}
          patientId={patientId}
          initialTradingPartnerName={tradingPartnerName}
        />
      </div>
    </div>

  );
};

export default PatientFilesClaimStatus;
