import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './Clinics.css'
import useUID from '../../General/useUID'
import {
    collection,
    getDoc,
    deleteDoc,
    doc, 
    query,
    where,
    onSnapshot,
    getDocs,
    addDoc, 
    serverTimestamp
  } from "firebase/firestore";
  import { auth, db } from "../../../firebase";
  import Modal from 'react-modal';
  import { Stepper, Step, StepLabel, Button } from '@mui/material';
  import FormEditor from '../FormEditor/FormEditor/FormEditor';


  function AddClinic() {
  const [link, setLink] = useState(null);
  const [clinicName, setClinicName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const functions = getFunctions();
  const [clinicNameURL, setClinicNameURL] = useState('');
  const [isClinicNameURLValid, setIsClinicNameURLValid] = useState(null);
  const [showAddClinicForm, setShowAddClinicForm] = useState(false);
  const [requireAddress, setRequireAddress] = useState(false);
  const [requireInsuranceInfo, setRequireInsuranceInfo] = useState(false);
  const [addInsuranceCardCamera, setAddInsuranceCardCamera] = useState(false);
  const [requirePhone, setRequirePhone] = useState(false);
  const [uid, subUserUID] = useUID();
  const [activeStep, setActiveStep] = useState(0);
  const [clinicType, setClinicType] = useState('public'); // Example state for step 1  
  const [editFormId, setEditFormId] = useState(null);
  const [enableAppointments, setEnableAppointments] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [timeZone, setTimeZone] = useState('EST');
  const [errorMessage, setErrorMessage] = useState('');
  const [workingHours, setWorkingHours] = useState({
    Monday: { open: true, start: '09:00', end: '17:00' },
    Tuesday: { open: true, start: '09:00', end: '17:00' },
    Wednesday: { open: true, start: '09:00', end: '17:00' },
    Thursday: { open: true, start: '09:00', end: '17:00' },
    Friday: { open: true, start: '09:00', end: '17:00' },
    Saturday: { open: false, start: '00:00', end: '00:00' },
    Sunday: { open: false, start: '00:00', end: '00:00' },
  });

  const steps = clinicType === 'public'
  ? ['Clinic Type', 'Clinic Name', 'Required Fields', 'Custom Form', 'Appointments', 'Save']
  : ['Clinic Type', 'Clinic Name'];

  const handleSave = async () => {
    setIsLoading(true);
    setShowAddClinicForm(false);
  
    // For private clinics, simplify data as per requirements
    const isPrivateClinic = clinicType === 'private';
    const subdomainLink = isPrivateClinic ? '' : generateSubdomainLink(clinicNameURL);
  
    try {
      // Adjust data saved based on clinic type
      const clinicData = {
        clinicName,
        ownerId: uid,
        created: serverTimestamp(),
        // Conditionally add fields based on clinic type
        ...(isPrivateClinic ? {} : {
          clinicNameURL,
          link: subdomainLink,
          requireAddress,
          requireInsuranceInfo,
          addInsuranceCardCamera,
          requirePhone
        }),
        ...(enableAppointments && {
            appointmentsEnabled: enableAppointments,
            workingHours,
            appointmentTypes, 
            timeZone
          })
      };
  
      // Save the clinic data to Firestore
      await saveClinicToFirestore(clinicData);
      setLink(subdomainLink);
  
      // Log activity
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({ uid, message: 'Clinic created.' });
  
      const logData = {
        timestamp: Date.now(),
        activity: 'Clinic created',
        activityType: 'clinicCreated',
        uid: uid
      };
  
      await addDoc(collection(db, "users", uid, "activityLogs"), logData);
  
      // Reset form fields after successful save and close the modal
      resetFormFields();
      setShowAddClinicForm(false);
    } catch (error) {
      console.error('Error saving clinic: ', error);
      setShowAddClinicForm(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const saveClinicToFirestore = async (clinicData) => {
    const clinicsRef = collection(db, "clinics");
    await addDoc(clinicsRef, clinicData);
  };

  const generateSubdomainLink = (clinicNameURL) => {
    const protocol = process.env.NODE_ENV === 'development' ? '' : 'https://';
    const baseUrl = process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'spectohealth.com';
    const subdomainLink = `${protocol}${baseUrl}/intake/${clinicNameURL}`;
    return subdomainLink;
  };
  
  
    const checkClinicNameURLUniqueness = async (url) => {
        const clinicsSnapshot = collection(db, "clinics");
        const q = query(clinicsSnapshot, where("clinicNameURL", "==", url));

        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
            setIsClinicNameURLValid(false);
        } else {
            setIsClinicNameURLValid(true);
        }
    };

    const handleNext = () => {
        let error = '';
    
        // Clinic name validation
        if (activeStep === 1) {
            if (!clinicName.trim()) {
                error = 'Please enter a clinic name.';
            }
            // Additional validation for public clinics' URL
            if (clinicType === 'public') {
                if (!clinicNameURL.trim()) {
                    error += ' Please enter a subdomain URL.';
                } else if (isClinicNameURLValid === false) {
                    error += ' This URL is already taken. Please choose another one.';
                }
            }
        }
    
        // Appointment validation, only if it's the appointment step and appointments are enabled
        if (activeStep === 4 && enableAppointments) {
            if (appointmentTypes.length === 0) {
                error += ' At least one appointment type is required.';
            } else if (!timeZone) {
                error += ' Please select a time zone for your appointments.';
            } else {
                let invalidTypes = appointmentTypes.filter(type => !type.name.trim() || !type.duration.trim());
                if (invalidTypes.length > 0) {
                    error += ' All appointment types must have both a name and a specified duration.';
                }
            }
        }
    
        if (error) {
            setErrorMessage(error); // Set the error message to display it
        } else {
            setErrorMessage(''); // Clear any previous errors
            if (clinicType === 'private' && activeStep === 1) {
                handleSave();
            } else {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    };
        
  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const resetFormFields = () => {
        setClinicName('');
        setClinicNameURL('');
        setIsClinicNameURLValid(null);
        setRequireAddress(false);
        setRequireInsuranceInfo(false);
        setAddInsuranceCardCamera(false);
        setRequirePhone(false);
        setActiveStep(0); 
        setClinicType('public')     
    };

    // Function to handle working hours change
    const handleWorkingHoursChange = (day, field, value) => {
        setWorkingHours((prevHours) => ({
        ...prevHours,
        [day]: {
            ...prevHours[day],
            [field]: value,
        },
        }));
    };

    // Helper function to handle adding a new appointment type
    const addAppointmentType = () => {
        setAppointmentTypes([...appointmentTypes, { name: '', duration: '' }]);
    };
    
    // Helper function to update an appointment type in the list
    const updateAppointmentType = (index, field, value) => {
        const updatedTypes = [...appointmentTypes];
        updatedTypes[index][field] = value;
        setAppointmentTypes(updatedTypes);
    };


    const getStepContent = (stepIndex) => {
        switch (stepIndex) {          
            case 0: // Clinic Type Selection
            return (
              <div className="step-content">
                <h2>Is this a public or private clinic?</h2>
                <p>Public clinics create a link where patients can fill out a custom intake form and make appointments. Private clinics are for organizing patients within Specto.</p>
                <div className="options">
                  <button onClick={() => setClinicType('public')} className={clinicType === 'public' ? 'selected' : ''}>Public</button>
                  <button onClick={() => setClinicType('private')} className={clinicType === 'private' ? 'selected' : ''}>Private</button>
                </div>
              </div>
            );
            case 1: // Clinic Name and Subdomain URL
            return (
                <div className="step-content">
                    <div className="input-field">
                        <label htmlFor="clinicName" className="form-label">Clinic Name:</label>
                        <input
                        id="clinicName"
                        type="text"
                        value={clinicName}
                        onChange={(e) => setClinicName(e.target.value)}
                        placeholder="Enter clinic name"
                        className="form-input"
                        />
                    </div>
                    {clinicType === 'public' && (
                        <div className="input-field">
                            <label htmlFor="clinicNameURL" className="form-label">Subdomain URL:</label>
                            <div className="url-container">
                                <span className="url-static-part">https://spectohealth.com/intake/</span>
                                <input
                                    id="clinicNameURL"
                                    type="text"
                                    value={clinicNameURL}
                                    onChange={(e) => {
                                        const url = e.target.value.trim().toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/--+/g, '-');
                                        setClinicNameURL(url);
                                        checkClinicNameURLUniqueness(url);
                                    }}
                                    placeholder="clinic-name"
                                    className="form-input"
                                />
                            </div>
                            {isClinicNameURLValid === false && <p className="error-text">This URL is already taken. Please choose another one.</p>}
                        </div>
                    )}
                </div>
            );

          case 2: // Required Fields
            return (
              <div className="step-content">
                <h2>Specify required fields for registration:</h2>
                <div className="checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      className='custom-checkbox'
                      checked={requireAddress}
                      onChange={(e) => setRequireAddress(e.target.checked)}
                    />
                    Require Address
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className='custom-checkbox'
                      checked={requirePhone}
                      onChange={(e) => setRequirePhone(e.target.checked)}
                    />
                    Require Phone
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className='custom-checkbox'
                      checked={requireInsuranceInfo}
                      onChange={(e) => setRequireInsuranceInfo(e.target.checked)}
                    />
                    Require Insurance Information
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className='custom-checkbox'
                      checked={addInsuranceCardCamera}
                      onChange={(e) => setAddInsuranceCardCamera(e.target.checked)}
                    />
                    Add Insurance Card Camera Feature
                  </label>
                </div>
              </div>
            );

        case 3:
            return (
                <div>
                <h2>Form</h2>
                    <FormEditor formId={editFormId} handleSave={handleSave} />
                </div>
            );

            // Adjust the `getStepContent` function to include the Appointments step
        case 4: // Appointments
        return (
            <div className="step-content">
            <h2>Appointments</h2>
            <label>
                <input
                type="checkbox"
                className='custom-checkbox'
                checked={enableAppointments}
                onChange={(e) => setEnableAppointments(e.target.checked)}
                /> Enable Appointments
            </label>
            {enableAppointments && (
                <div>
                     <label htmlFor="timeZone">Time Zone:</label>
                    <select
                        id="timeZone"
                        value={timeZone}
                        onChange={(e) => setTimeZone(e.target.value)}
                        className="form-control"
                    >
                        <option value="UTC">UTC (Coordinated Universal Time)</option>
                        <option value="EST">EST (Eastern Standard Time)</option>
                        <option value="CST">CST (Central Standard Time)</option>
                        <option value="MST">MST (Mountain Standard Time)</option>
                        <option value="PST">PST (Pacific Standard Time)</option>
                        {/* Add as many time zones as needed */}
                    </select>
                {Object.entries(workingHours).map(([day, { open, start, end }]) => (
                    <div key={day} className="day-hours">
                    <label>
                        <input
                        type="checkbox"
                        className='custom-checkbox'
                        checked={open}
                        onChange={(e) => handleWorkingHoursChange(day, 'open', e.target.checked)}
                        /> {day}
                    </label>
                    {open && (
                        <>
                        <input
                            type="time"
                            value={start}
                            onChange={(e) => handleWorkingHoursChange(day, 'start', e.target.value)}
                        />
                        to
                        <input
                            type="time"
                            value={end}
                            onChange={(e) => handleWorkingHoursChange(day, 'end', e.target.value)}
                        />
                        </>
                    )}
                    </div>
                ))}
                <h3>Appointment Types</h3>
                {appointmentTypes.map((type, index) => (
                    <div key={index}>
                    <input
                        type="text"
                        value={type.name}
                        onChange={(e) => updateAppointmentType(index, 'name', e.target.value)}
                        placeholder="Appointment Type"
                    />
                    <input
                        type="text"
                        value={type.duration}
                        onChange={(e) => {
                            // Parse the input value to an integer
                            const value = parseInt(e.target.value, 10);
                            
                            // Check if the value is a number and within the 1-600 range
                            if (!isNaN(value) && value >= 1 && value <= 600) {
                            // Update the appointment type duration if the validation passes
                            updateAppointmentType(index, 'duration', value.toString());
                            } else if (e.target.value === '') {
                            // Allow clearing the input
                            updateAppointmentType(index, 'duration', '');
                            }
                            // Optionally, handle invalid input cases here, such as showing an error message
                        }}
                        placeholder="Duration (minutes)"
                        />

                    </div>
                ))}
                <button onClick={addAppointmentType}>Add Appointment Type</button>
                </div>
            )}
            </div>
        );
        
          case 5: // Review & Save
            return (
              <div className="step-content review-save">
                <h2>Review Clinic Information</h2>
                <p><strong>Type:</strong> {clinicType}</p>
                <p><strong>Name:</strong> {clinicName}</p>
                <p><strong>Address Required:</strong> {requireAddress ? 'Yes' : 'No'}</p>
                <p><strong>Phone Required:</strong> {requirePhone ? 'Yes' : 'No'}</p>
                <p><strong>Insurance Info Required:</strong> {requireInsuranceInfo ? 'Yes' : 'No'}</p>
                <p><strong>Insurance Card Camera:</strong> {addInsuranceCardCamera ? 'Yes' : 'No'}</p>
              </div>
            );
          default:
            return 'Unknown Step';
        }
      };
      

    return (
        <div>
          <button onClick={() => setShowAddClinicForm(true)}>
            <FontAwesomeIcon icon={faPlus} />
            <FontAwesomeIcon icon={faHospital} style={{ marginRight: '1rem', marginLeft: '.5rem' }} />
            Add Clinic
          </button>
      
          <Modal
            isOpen={showAddClinicForm}
            onRequestClose={() => setShowAddClinicForm(false)}
            contentLabel="Add Clinic"
            className="clinicAddModal" 
            overlayClassName="modalOverlay"
          >
            <button onClick={() => setShowAddClinicForm(false)} className="filesCloseButton">X</button>
            <div className='stepperContainerClinics'>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div>{getStepContent(activeStep)}</div>
            <div>
                {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                </Button>
                {activeStep === steps.length - 1 || (clinicType === 'private' && activeStep === 1) ? (
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save Clinic
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        Next
                    </Button>
                )}
            </div>
          </Modal>
        </div>
      );
      
}

export default AddClinic;
