import React, { useState, useEffect } from 'react';
import { auth } from "../../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import styles from '../Claims.css';

const GeneralClaimVolume = () => {
  const [loading, setLoading] = useState(true);
  const [claimChartData, setClaimChartData] = useState([]);
  const [timeFrame, setTimeFrame] = useState('1m');

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) return;

    const fetchClaims = async () => {
      setLoading(true);
      const functions = getFunctions();
      const aggregateClaimsHistogram = httpsCallable(functions, 'aggregateClaimsHistogram');

      try {
        const response = await aggregateClaimsHistogram({ uid, timeFrame });
        setClaimChartData(response.data.map(item => ({
          ...item,
          date: formatDate(item.date),
          totalAmount: item.totalAmount
        })));
      } catch (error) {
        console.error("Error fetching histogram data:", error);
      }

      setLoading(false);
    };

    fetchClaims();
  }, [timeFrame]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{label}</p>
          <p>Total Amount: ${payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading your claim volume</h4>
      </div>
    );
  }

  return (
    <div className='SingleChart'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3 style={{ textAlign: 'center' }}>General Claim Volume in Dollars</h3>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
        <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          <option value="1w">1 Week</option>
          <option value="1m">1 Month</option>
          <option value="1y">1 Year</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={claimChartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tickFormatter={(value) => `$${value}`} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" dataKey="totalAmount" stroke="#206274" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Helper function to format date strings
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
};

export default GeneralClaimVolume;
