import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { useNavigate, useParams } from 'react-router-dom';
import './AUTH.css';
import { doc, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import logo from '../../specto_logos/Specto_logo_specto.png';
import { getFunctions, httpsCallableFromURL, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import SmallLogo from "./smalllogo";
import styles from "./AUTH.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import HexagonSpinner from "../General/Animations/Hexspinner";


const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const functions = getFunctions();
  const [isLoading, setIsLoading] = useState(false);

  let { token } = useParams() || {};

  const signUp = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    // Check if name is valid
    const nameSplit = name.trim().split(' ');
    if(nameSplit.length !== 2) {
      setError("Please enter your first and last name. One word each.");
      setIsLoading(false);  // Set loading to false
      return;
    }

    const firstName = capitalizeFirstLetter(nameSplit[0]);
    const lastName = capitalizeFirstLetter(nameSplit[1]);
    const fullName = `${firstName} ${lastName}`;


    // Check if password is valid
    const passwordRegex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(password)) {
       setError("Password must be at least 8 characters long, contain at least one uppercase letter, and at least one number");
       setIsLoading(false); // End loading
       return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false); // End loading
      return;
    }
    if (!agree) {
      setError("You must agree to the Terms of Service and Privacy Policy");
      setIsLoading(false); // End loading
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (token) {
        // If token exists, fetch the inviter's UID using the token from Firestore
        const tokenQuery = query(collection(db, 'UserInviteTokens'), where('token', '==', token));
        const tokenQuerySnapshot = await getDocs(tokenQuery);
        if (tokenQuerySnapshot.empty) {
          setError("Invalid invitation token.");
          setIsLoading(false); // End loading
          return;
        }

        const inviterUID = tokenQuerySnapshot.docs[0].data().uid;

        // This is a subuser signup based on the inviter's UID
        await setDoc(doc(db, "users", inviterUID, "subusers", user.uid), {
          firstName: firstName,
          lastName: lastName,          
          Email: email,
          parentUID: inviterUID,
          role: 'nurse' // or whatever role you intend this to be
        });
      
        // Set effectiveUID in session storage
        sessionStorage.setItem('effectiveUID', inviterUID);
      
        // Redirect subusers to /home
        navigate("/home");
      } else {
        
        // Regular signup
        await setDoc(doc(db, "users", user.uid), {
          Name: fullName,
          Email: email
        });

        sessionStorage.setItem('effectiveUID', user.uid);
  
        // Call the AccountStartDatabase function for regular users only
        const accountStartDatabase = httpsCallable(functions, 'AccountStartDatabase');
        try {
          await accountStartDatabase({ uid: user.uid });
        } catch (error) {
          console.error('Error populating Firestore data: ', error);
        }
  
        // Redirect regular users to /signupprovider
        navigate("/signupprovider");
      }
  
    } catch (error) {
      console.log(error);
      setError("Error creating account");
      setIsLoading(false); // End loading
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

  return (
    <>
    <Helmet>
      <title>Sign Up | Specto Health</title>
      <meta name="description" content="Create your account on Specto Health and start accessing our suite of medical tools. Join us today!" />
      <meta name="keywords" content="Sign Up, Create Account, Medical Tools, Medical Billing Software, Telehealth tools, Healthcare Billing, Clinic Management, Patient Management, AI in Healthcare, Specto Health" />
      <meta property="og:title" content="Sign Up | Specto Health" />
      <meta property="og:description" content="Create your account on Specto Health and start accessing our suite of medical tools. Join us today!" />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/spectohealth.appspot.com/o/Specto_logo_wide.png?alt=media&token=72c8f353-f0f9-463c-966e-4f7b644bea5d" />
      <meta property="og:url" content="https://spectohealth.com/signup" />
    </Helmet>

    <div className="background">
      <a href="/" className="logoWrapper">
        <img src={logo} alt="Logo" className="logo" />
      </a>

    <div className="AUTH-container">
    <div>
        <SmallLogo/>
        </div>
      <form onSubmit={signUp}>
        <h1 className="heading">Create Account</h1>
        <input
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles["input-field"]}
          />
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles["input-field"]}
          required
        />
        <input
          type="password"
          placeholder="(+8 characters, 1 uppercase, 1 number)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles["input-field"]}
          required
        />
        <input
          type="password"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={styles["input-field"]}
          required
        />
        <div>
        <input
          type="checkbox"
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
          className="custom-checkbox"
          required
        />
        <label htmlFor="agree">
          I have read and agree to the <Link to="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</Link> and <Link to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
        </label>

      </div>
        {error && <p className="error-message">{error}</p>}
        {isLoading ? (
              <HexagonSpinner /> // Render this when it's loading
            ) : (
              <>
              <button type="submit" className="signup-btn">Continue</button>
              <Link to="/signin" className="signin-btn">Sign In</Link>
              </>
        )}


      </form>
    </div>
    </div>
    </>
  );
};

export default SignUp;
