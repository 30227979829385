import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical, faUserCheck, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function PricingTable({ showPaymentForm }) {
  const plans = [
    {
      name: 'Freebie',
      description: 'Understand why we are the ultimate healthcare app.',
      buttonText: 'Get Started',
      price: 'Free',
      features: ['25 free messages', '1 provider', '500 Patients', '1 clinic']
    },
    {
      name: 'Professional',
      description: 'Take advantage of our powerful tools.',
      buttonText: 'Get Started',
      price: '$100/month',
      features: ['$0.35c per message', '5 Providers', '10,000 Patients', '5 clinics']
    },
    {
      name: 'Enterprise',
      description: 'Take your healthcare management to the next level.',
      buttonText: 'Get Started',
      price: '$1,000/month',
      features: ['$0.25c per message', 'Unlimited Providers and patients', 'Unlimited Clinics', 'Ask us about our API integrations']
    },
  ];

  return (
    <div className="pricing-table">
      <div className="plan-container">
        {plans.map((plan, index) => (
          <div className="plan-card" key={index}>
            <h2>{plan.name}</h2>
            <div className="plan-description">
              <p>{plan.description}</p>
            </div>            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <div className='price-container'>
              <span className="price">{plan.price.split('/')[0]}</span>
              {plan.price.split('/')[1] && (
                <span className="month">/{plan.price.split('/')[1]}</span>
              )}
            </div>
            <Link to="/signup">
              <button>{plan.buttonText}</button>
            </Link>
          </div>
        ))}
      </div> 
      <p style={{fontSize:'18px', textAlign: 'center'}}>
        * A message is an eligibility request, claim sent, or claim status checked.
      </p>  
    </div>
  );
}

export default PricingTable;
