import React, { useState } from 'react';

const FAQSection = () => {
    const [selectedId, setSelectedId] = useState(null);

    const handleClick = (id) => {
        setSelectedId(selectedId === id ? null : id);
    }

    const faqs = [
        { 
            id: 1, 
            question: 'What kind of services does Specto Health provide?', 
            answer: 'Specto Health provides a comprehensive suite of software tools for healthcare professionals. This includes tools for medical billing, patient management, insurance eligibility checks, remote patient monitoring (RPM), continuous glucose monitoring (CGM), and a personalized AI chat assistant for clinical documentation and decision support.' 
        },
        { 
            id: 2, 
            question: 'How does Specto Health protect my data?', 
            answer: 'We are committed to data security and HIPAA compliance.  We utilize role-based access control (RBAC). Regular security audits and updates are conducted to meet the latest healthcare data security standards.' 
        },
        { 
            id: 3, 
            question: 'How does AI help in my healthcare practice?', 
            answer: 'AI can streamline various workflows, including patient documentation, billing, and clinical decision support. For example, AI can assist in summarizing patient visits, providing ICD-10 code suggestions based on clinical notes, and even generating prescription recommendations. Our AI assistant is also trained to read and analyze large clinical documents for quick retrieval of important data.' 
        },
        { 
            id: 4, 
            question: 'Is it free to create an account?', 
            answer: 'Yes, you can sign up for a free account with Specto Health and access limited services, including 25 free claims requests. For additional features such as advanced billing options, RPM, or CGM monitoring, you can upgrade to a paid plan.' 
        },
     
        {
            id: 6,
            question: 'What devices does Specto Health support for RPM and CGM?',
            answer: 'We support a wide range of FDA-approved devices for both continuous glucose monitoring (CGM) and remote patient monitoring (RPM). This includes devices such as the Libre 3 CGM for real-time glucose tracking, and the Tenovi RPM blood pressure monitor for continuous cardiovascular monitoring. Our platform seamlessly integrates with these devices to provide real-time data, alerting, and trend analysis for healthcare providers.'
        },
        {
            id: 7,
            question: 'How do CGM and RPM devices integrate with my practice?',
            answer: 'Specto Health integrates data from CGM and RPM devices into your existing electronic medical records (EMR) system. We ensure real-time data flow from devices like Libre 3 and RPM blood pressure monitors into our secure platform, allowing you to review patient data, set automated alerts, and optimize patient care. This integration is HIPAA-compliant and supports various clinical workflows for telehealth and in-person visits.'
        },
        {
            id: 8,
            question: 'What kind of analytics does Specto Health offer?',
            answer: 'Our platform offers advanced analytics for patient health metrics, including blood glucose trends, blood pressure patterns, and overall health monitoring. We provide customizable dashboards that allow clinicians to track patient progress over time, compare historical data, and set thresholds for alerts. This enables more proactive patient care and improves clinical outcomes by identifying at-risk patients earlier.'
        },
        {
            id: 9,
            question: 'Can I integrate Specto Health with my existing EMR or EHR?',
            answer: 'Yes, Specto Health can integrate with most electronic medical records (EMR) and electronic health records (EHR) systems through APIs, and other standardized protocols. This ensures seamless data exchange between your practice’s existing systems and Specto Health’s platform, reducing manual data entry and streamlining workflows.'
        },
        {
            id: 10,
            question: 'How does Specto Health handle telehealth services?',
            answer: 'Specto Health offers a fully integrated telehealth solution that allows healthcare providers to conduct virtual visits, manage remote patient monitoring (RPM), and review continuous glucose monitoring (CGM) data, all in one platform. Our telehealth feature includes video calls, secure messaging, and the ability to upload and view real-time health data, making it easier to manage patients remotely.'
        },
    ];

    return (
        <div className="FAQ-container">
            <h1>FAQ</h1>
            {faqs.map(({ id, question, answer }) => (
                <div key={id}>
                    <h2 onClick={() => handleClick(id)}>{selectedId === id ? "-" : "+"}{"       "}{question}</h2>
                    {selectedId === id && <p>{answer}</p>}
                </div>
            ))}
        </div>
    );
}

export default FAQSection;
