import React from 'react';
import { Link } from 'react-router-dom';
import './Website.css';
import logo from './assetts/Specto_logo_specto.png'; 

const Header = () => {
  return (
    <header>
      <div className='logoContainer'>
        <Link to="/">
          <img src={logo} alt="Logo" className="logoHome" />
        </Link>
      </div>
      <ul className="nav-links">
      <li><Link to="/techspecs" style={{ color: 'black' }}>Tech Specs</Link></li>
      {/* <li><Link to="/pricing" style={{ color: 'black' }}>Pricing</Link></li> */}
      <li><Link to="/privacy" style={{ color: 'black' }}>Privacy</Link></li>
        <li><Link to="/signup" className="signup-btn">Sign Up</Link></li>
        <li><Link to="/signin" className="login-btn">Sign In</Link></li>
      </ul>
    </header>
  );
};

export default Header;
