import React, { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from '../../../firebase';
import { collection, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import HexSpinner from '../../General/Animations/Hexspinner';
import DOMPurify from 'dompurify';
import PdfGenerator from './PdfGenerator';
import Signature from './Signature';
import useUID from '../../General/useUID'
import Confetti from '../../General/Animations/Confetti'

const functions = getFunctions();

function DoctorsNote({ patientId, note = null, body, manualInputMode }) {
  const [response, reRponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [user, setUser] = useState({});
  const [formattedResponse, setFormattedResponse] = useState(null);
  const [isPatientDataLoaded, setIsPatientDataLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPanel, setCurrentPanel] = useState(manualInputMode ? 2 : 1);
  const [signatureData, setSignatureData] = useState(null);
  const [noteBody, setNoteBody] = useState(body || '');
  const [Error, setError] = useState('');
  const [Success, setSuccess] = useState('');
  const [savedNoteId, setSavedNoteId] = useState(null);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (currentPanel === 4) handleSave();
  }, [currentPanel]);

  useEffect(() => {
    setNoteBody(body || '');
  }, [body]);

  useEffect(() => {
    if (!uid) return;
    const fetchUserData = async () => {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setUser(userDoc.data());
      }
    };

    fetchUserData();
  }, [uid]);

  useEffect(() => {
    if (!uid) return;
    const loadPatientData = async () => {
      const patientDocRef = doc(db, 'patients', uid, 'patientData', patientId);
      const patientDoc = await getDoc(patientDocRef);
      if (patientDoc.exists()) {
        const data = patientDoc.data();
        setPatientData(data);
        setIsPatientDataLoaded(true); // Set loaded flag
      } else {
        console.log('Patient data does not exist for patientId:', patientId);
      }
    };

    loadPatientData();
  }, [patientId, uid]);

  useEffect(() => {
    // This effect will run when isPatientDataLoaded changes to true
    if (isPatientDataLoaded && body && body.trim() !== '') {
      handleSubmit({ preventDefault: () => { } });
    }
  }, [isPatientDataLoaded, body]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (noteBody.trim() === '') {
      setError('Note body is required');
      return;
    }

    setIsLoading(true);

    try {
      const data = {
        question: `${noteBody}`,
        uid: uid,
      };

      const doctorsNoteCallable = httpsCallable(functions, 'DoctorsNote');
      const result = await doctorsNoteCallable(data);

      let tempFormattedResponse = result.data.text
        .replace(/\[Patient's Name\]/g, `${patientData.patient.firstName} ${patientData.patient.lastName}`)
        .replace(/\[Date of Visit\]/g, new Date().toLocaleDateString())
        .replace(/\[Doctor's Name\]/g, `${user.FirstName} ${user.LastName}`)
        .replace(/\[Medical Clinic Name\]/g, user.OrganizationName)
        .replace(/\[Contact Information\]/g, user.Phone)
        .replace(/\[Doctor's Credentials\]/g, '')
        .replace(/\[He\/She\]/g, patientData.patient.gender === 'M' ? 'He' : 'She')
        .replace(/\[he\/she\]/g, patientData.patient.gender === 'M' ? 'he' : 'she')
        .replace(/\[him\/her\]/g, patientData.patient.gender === 'M' ? 'him' : 'her')
        .replace(/\[his\/her\]/g, patientData.patient.gender === 'M' ? 'his' : 'her');

      setFormattedResponse(tempFormattedResponse);
      reRponse(DOMPurify.sanitize(tempFormattedResponse));
    } catch (error) {
      console.error('Error:', error);
    }

    setError(null);
    setCurrentPanel(currentPanel + 1);
    setIsLoading(false);
  };

  const back = () => {
    if (currentPanel >= 1) setCurrentPanel(currentPanel - 1);
    setError('');
  };

  const next = async () => {
    if (currentPanel === 1 && noteBody.trim() === '') {
      setError('Note body is required');
      return;
    }

    if (currentPanel === 2 && response.trim() === '') {
      setError('Note body is required');
      return;
    }

    // // If manualInputMode is enabled and the current panel is 2, save the note directly
    // if (manualInputMode && currentPanel === 2) {
    //   await handleSave(); // Save the note
    //   return; // Exit the function to avoid moving to the next panel
    // }

    if (currentPanel <= 4) setCurrentPanel(currentPanel + 1);
  };

  const fetchAuthorName = async () => {
    try {
      const userDocRef = doc(db, "users", uid, ...(subUserUID ? ['subusers', subUserUID] : []));
      const userDocSnap = await getDoc(userDocRef);
      return userDocSnap.exists() ? userDocSnap.data().firstName : 'Unknown Author';
    } catch (error) {
      console.error("Error fetching author's name: ", error);
      return 'Unknown Author';
    }
  };


  const handleSave = async () => {
    setIsUploading(true);

    try {
      const patientName = `${patientData.patient.firstName} ${patientData.patient.lastName} ${(manualInputMode) ? '' : '(AI)'}`
      const authorName = await fetchAuthorName(); // Fetch the author's name
      const notesRef = collection(db, "patients", uid, "patientData", patientId, "notes");
      const noteData = {
        title: patientName,
        text: response,
        timestamp: new Date(),
        signatureId: signatureData.id, 
        authorName: authorName, // Include the author's name here
        isAIGenerated: !manualInputMode
      };

      if (savedNoteId) {
        // Update existing note
        const docRef = doc(notesRef, savedNoteId);
        await updateDoc(docRef, noteData);
      } else {
        // Add new note
        await addDoc(notesRef, noteData);
      }

      // setSuccess('Note Saved Successfully!');
      // setDisabledSave(true);

      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Error saving note:', error);
      setIsUploading(false);
      setError(error.message);
    }

    setIsUploading(false);
  };


  const handleDownload = async () => {
    const pdfGenerator = new PdfGenerator();
    await pdfGenerator.downloadPdf(response, patientData, signatureData, uid, subUserUID);
  };

  const createAnotherNote = () => {
    setNoteBody('');
    reRponse('');
    setCurrentPanel(manualInputMode ? 2 : 1);
    setError('');
    setSuccess('');
  };

  const panels = {
    1: (
      <form onSubmit={handleSubmit}>
        <label htmlFor="body" className="m-b">
          Let's start with a note summary:
        </label>
        {Error && <div className="error-message">{Error}</div>}
        <div>
          <textarea
            id="body"
            disabled={isLoading}
            value={noteBody}
            onChange={(e) => setNoteBody(e.target.value)}
            className="textarea"
            placeholder="Summarize your note, and leave the rest to us..."
          />
        </div>

        {isLoading ? (
          <HexSpinner />
        ) : (
          <button className="submit-button">Generate Note</button>
        )}
      </form>
    ),
    2: (
      <div id="response" className="response">
        {Error && <div className="error-message">{Error}</div>}

        <textarea
          className="response-edit"
          value={response}
          onChange={(e) => reRponse(e.target.value)}
          placeholder="Add a new note"
        />
        {Success && <div className="success-message">{Success}</div>}

        {!manualInputMode && (
          <div>
            <label htmlFor="body">
              SpectoAI may produce inaccurate information. Please carefully read the letter before saving.
            </label>
          </div>
        )}
        <div className="button-group">
          {!manualInputMode && (
            <button className="back-button rm-220" onClick={() => back()}>
              Back
            </button>
          )}
          <button className={manualInputMode ? 'submit-button' : 'next-button'} onClick={() => next()}>
            {manualInputMode ? 'Add Note' : 'Next'}
          </button>
        </div>
      </div>
    ),
    3: (
      <div>
        <Signature
          onBack={() => back()}
          onNext={() => next()}
          onApplySignature={(signatureData) => setSignatureData(signatureData)}
        />
      </div>
    ),
    4: (
      <div>
        <Confetti />
        <div className="congratulations-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
          >
            <circle cx="50" cy="50" r="45" stroke="#206274" strokeWidth="8" fill="none" />
            <path
              d="M30 50L45 65L75 35"
              stroke="#206274"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
          <h2>Congratulations!</h2>
          <p>Your note is complete & saved.</p>
          <p>You can now download,</p>
          <p>or create another one.</p>
        </div>

        {Success && <div className="success-message">Note successfully saved.</div>}
        <button className="back-button rm-220" onClick={() => back()}>
          Back
        </button>
        {/* <button onClick={handleSave} className="next-button">
          Save
        </button> */}
        <button onClick={handleDownload} className="next-button">
          Download PDF
        </button>
        <button onClick={createAnotherNote} className="next-button">
          + Create Another
        </button>
      </div>
    ),
  };

  return <div className="doctors-note">{panels[currentPanel]}</div>;
}

export default DoctorsNote;
