import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, getDocs, where } from 'firebase/firestore';
import './SearchBar.css';

const SearchBar = ({ category = '', searchTerm = '' }) => {
  const [searchTermState, setSearchTermState] = useState(searchTerm);
  const [articles, setArticles] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (searchTermState !== '') {
      handleSearch();
    } else {
      setArticles([]);
    }
  }, [searchTermState]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleSearch = async () => {
    if (searchTermState === '') {
      setArticles([]);
      return;
    }

    let articleQuery = collection(db, 'articles');

    if (category) {
      articleQuery = query(articleQuery, where('category', '==', category));
    }

    const snapshot = await getDocs(articleQuery);
    const fetchedArticles = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const filteredArticles = fetchedArticles.filter((article) => {
      const titleMatch = article.title && article.title.toLowerCase().includes(searchTermState.toLowerCase());
      const keywordsMatch = article.keywords && article.keywords.some((keyword) => keyword.toLowerCase().includes(searchTermState.toLowerCase()));
      return titleMatch || keywordsMatch;
    });

    setArticles(filteredArticles);
  };

  const handleSearchNavigation = () => {
    if (searchTermState.trim() !== '') {
      const queryParams = new URLSearchParams({ q: searchTermState });
      navigate(`/search?${queryParams.toString()}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchNavigation();
    }
  };

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div ref={searchBarRef} className="search-bar-container">
      <div className="search-bar" onClick={handleClick}>
        <input
          type="text"
          placeholder="Search Useful Articles..."
          value={searchTermState}
          onChange={(e) => setSearchTermState(e.target.value)}
          onKeyPress={handleKeyPress}
          className={`search-input ${showOptions && articles.length > 0 ? 'searching' : ''}`}
        />
        <button onClick={handleSearchNavigation} className={`search-button ${showOptions && articles.length > 0 ? 'searching' : ''}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
      </div>
      {showOptions && (
        <div className={`articles-list ${showOptions && articles.length > 0  ? 'searching' : ''}`}>
          {articles.map((article) => (
            <a href={`/article/${article.id}`} key={article.id}>
              <div className="article-item">{article.title}</div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
