import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PatientList from "./PatientList";
import "./PatientFiles.modules.css";
import Topbar from "../General/Topbar";
import PatientView from "./PatientView";
import FolderControls from "./FolderControls";

function PatientFiles() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("patientData");
  const [dataUpdated, setDataUpdated] = useState(false); // State to track data updates
  const { patientIdfromURL } = useParams();
  const scrollingTableRef = useRef(null);

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
  };

  // Function to toggle dataUpdated state
  const handleDataUpdated = () => {
    setDataUpdated(prevState => !prevState);
  };

  return (
    <div className="background">
      <Topbar />
      <div className="content">
        <div className="patientWindow">
          <PatientView 
            patientSelected={selectedPatient} 
            onDataUpdated={handleDataUpdated} // Pass handleDataUpdated to PatientView
          />
        </div>
        <div className="folderControlsContainer">
          <FolderControls
            onFolderChange={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>
        <div className="patientViewBar" ref={scrollingTableRef}>
          <PatientList 
            onSelectPatient={handleSelectPatient} 
            selectedFolder={selectedFolder} 
            selectedPatient={selectedPatient}
            patientIdfromURL={patientIdfromURL}
            scrollingTableRef={scrollingTableRef} 
            dataUpdated={dataUpdated} // Pass dataUpdated to PatientList
            
          />
        </div>
      </div>
    </div>
  );
}

export default PatientFiles;
