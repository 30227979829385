import React, { useState, useEffect } from 'react';
import styles from './quicksearch.module.css';
import { auth } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PayerSelection from '../Payers/PayerSelection';
import QuicksearchData from './QuicksearchData';
import HexSpinner from '../General/Animations/Hexspinner';
import { getFirestore, collection, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
const db = getFirestore();
import ProviderSelection from '../Providers/ProviderSelection';
import ServiceTypeDropdown from './ServiceTypeDropdown';

function QuickSearch({ onSuccess, firstName: initialFirstName, middleName: initialMiddleName, lastName: initialLastName, dob: initialDob, gender: initialGender, memberid: initialmemberid, patientId, initialTradingPartnerName, initialProcedureCode }) {
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [middleName, setMiddleName] = useState(initialMiddleName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [dob, setDob] = useState(initialDob || '');
  const [gender, setGender] = useState(initialGender || '');
  const [patientData, setPatientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [payers, setPayers] = useState([]);
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [payerError, setPayerError] = useState("");
  const [memberId, setmemberId] = useState(initialmemberid || '');
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [providerNPI, setProviderNPI] = useState('');
  const [providerfirstName, setProviderFirstName] = useState('');
  const [providerlastName, setProviderLastName] = useState('');
  const [medicareNumber, setMedicareNumber] = useState('');
  const [medicaidNumber, setMedicaidNumber] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [procedureCode, setProcedureCode] = useState(initialProcedureCode || '');
  const [selectedServiceType, setSelectedServiceType] = useState('');

  // Utility function to format today's date in YYYY-MM-DD format
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  };
  const [dos, setDos] = useState(formatDate(new Date()));

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isBCBS = selectedPayers.some(payer => payer.name && payer.name.includes("Blue Cross Blue Shield"));

    if (isBCBS && !memberId) {
      setErrorMessage("For Blue Cross Blue Shield payers, Member Id is mandatory.");
      return;  // This should stop the function from proceeding further.
    }

    if (!firstName || !lastName || !dob || !gender || !dos || selectedPayers.length === 0) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    if (!hasRemainingRequests) {
      setErrorMessage("You have reached the limit of 25 API requests for the free trial.");
      return;
    }


    setIsLoading(true);
    try {
      const formattedDob = dob ? new Date(dob).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';
      const formattedDos = dos ? new Date(dos).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';

      const currentUser = auth.currentUser;
      const uid = currentUser ? currentUser.uid : null;

      const functions = getFunctions();
      const EligibilitySingleAPI = httpsCallable(functions, 'EligibilitySingleAPI');

      const result = await EligibilitySingleAPI({
        firstName,
        lastName,
        ...(middleName && { middleName }),
        dob: formattedDob,
        dos: formattedDos,
        gender,
        ...(memberId && { memberId }),
        payers: selectedPayers,
        uid,
        organizationName: providerOrganizationName,
        providerfirstName: providerfirstName,
        providerlastName: providerlastName,
        npi: providerNPI,
        ...(medicareNumber && { medicareNumber }),
        ...(medicaidNumber && { medicaidNumber }),
        ...(patientId && { patientId }),
        ...(procedureCode && { procedureCode }),
        ...(selectedServiceType && { selectedServiceType }),


      }).catch((error) => {
        console.error("Error calling function: ", error);
      });

      if (result) {
        if (result.data && result.data.success) {
          // Handle success scenario
          if (result.data.eligibilityData) {
            setPatientData(result.data);
            if (onSuccess) {
              onSuccess(result.data.eligibilityData);
            }
          } else {
            setPatientNotFound(true);
          }
        } else {
          // Handle error scenario
          let errorMessage = 'An error occurred while processing your request.';
          if (result.data && result.data.errors && result.data.errors.length > 0) {
            // Construct a message from the first error in the array
            errorMessage = `${result.data.errors[0].description}. ${result.data.errors[0].possibleResolutions}`;
          }
          setErrorMessage(errorMessage);
        }
      } else {
        setErrorMessage('No response from the server. Please try again later.');
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error adding document: ", error);
      setErrorMessage('An unexpected error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  const handlePayerSelect = (selectedPayers) => {
    setSelectedPayers(selectedPayers);

    const isBCBS = selectedPayers.some(payer => payer.name && payer.name.includes("Blue Cross Blue Shield"));

    if (isBCBS && !memberId) {
      setErrorMessage("For Blue Cross Blue Shield payers, Member Id is mandatory.");
    } else {
      setErrorMessage("");
    }
  };



  useEffect(() => {
    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const uid = auth.currentUser.uid;
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      const AccountTier = userDocSnap.data().AccountTier || '';

      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();

          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });

        if (AccountTier === 'Freebie' && total >= 25) {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });

      return () => unsubscribe();
    };

    if (auth.currentUser) {
      fetchAccountTierAndCheckRemainingRequests();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    if (firstName && lastName && dob && gender && dos && selectedPayers.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, lastName, dob, gender, dos, selectedPayers]);

  // Function to clear the error message
  const clearErrorMessage = () => {
    setErrorMessage('');
  };



  // Handler function for when service type changes
  const handleServiceTypeChange = (newServiceType) => {
    setSelectedServiceType(newServiceType);
  };


  return (
    <div className={styles.eligibilitySearchContainer}>
      <div className={styles.quickSearchForm}>
        <div className="centerHeader">
          <h3>Eligibility Search</h3>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="firstname">First Name:</label>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="firstname">Middle Name:</label>
            <input type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
          </div>
          <div className="input-field">
            <label htmlFor="firstname">Last Name:</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
          </div>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="memberID">Gender:</label>
            <select id="gender" className={styles.genderSelect} value={gender} onChange={(e) => setGender(e.target.value)} required >
              <option value="">Select gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="input-field">
            <label htmlFor="memberID">Member Id (optional):</label>
            <input
              className={styles.memberid}
              type="text"
              value={memberId}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                setmemberId(sanitizedValue);
              }}
            />
          </div>

        </div>
        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="dob">Date of Birth:</label>
            <input type="date" id="dob" placeholder="Date of Birth" value={dob} onChange={(e) => setDob(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="dos">Date of Service:</label>
            <input
              type="date"
              id="dos"
              placeholder="Date of Service"
              value={dos}
              onChange={(e) => setDos(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="firstname">Procedure Code (optional)</label>
            <input type="text" value={procedureCode} onChange={(e) => setProcedureCode(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="serviceType">Service Type (optional)</label>
            <ServiceTypeDropdown onServiceTypeChange={handleServiceTypeChange} />
          </div>
        </div>

        <div className='content-center mb-3 mt-3'>
          <PayerSelection payers={payers} TradingPartnerName={initialTradingPartnerName} onSelect={handlePayerSelect} mode={'eligibility'} />
        </div>
        
        <ProviderSelection
          onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
            setProviderOrganizationName(organizationName);
            setProviderNPI(npi);
            setProviderFirstName(firstName);
            setProviderLastName(lastName);
            setMedicareNumber(medicareNumber);
            setMedicaidNumber(medicaidNumber)
          }}
        />

        {
          errorMessage && (
            <div className={styles.payerErrorMessage}>
              {errorMessage}
            </div>
          )
        }

        <div className="content-center mb-3 mt-3">
          {isLoading ? (
            <HexSpinner />
          ) : (
            <button type="submit" onClick={handleSubmit} disabled={!isFormValid}>
              Search
            </button>

          )}
        </div>
      </div>
      {
        payerError && (
          <div className={styles.payerErrorMessage}>{payerError}</div>
        )
      }

      {
        patientNotFound && <div className={styles.patientNotFoundMessage}>Insurance not found</div>
      }
      <div className={styles.patientDataPopupContainer} style={{ display: patientData ? 'block' : 'none' }}>
        {patientData && (
          <div className={styles.patientDataPopupContainer}>
            <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
          </div>
        )}

        {
          errorMessage && (
            <div className={styles.errorMessage}>
              {errorMessage}
              <button onClick={clearErrorMessage}>X</button>
            </div>
          )
        }

      </div>
    </div>
  );
}

export default QuickSearch;

