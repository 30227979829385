import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import useUID from '../General/useUID'

const Dashboard = () => {
  const [deviceCount, setDeviceCount] = useState(0);
  const [uid, subUserUID, error, isLoading] = useUID();

  useEffect(() => {
    if (!uid) return;

    const fetchDevices = async () => {
      if (!uid) {
        console.log('No user signed in');
        return;
      }

      const patientRef = collection(db, 'patients', uid, 'patientData');
      const patientSnapshot = await getDocs(patientRef);
      let deviceCounter = 0;
      await Promise.all(patientSnapshot.docs.map(async (patientDoc) => {
        const terraRef = collection(patientDoc.ref, 'Terra');
        const terraSnapshot = await getDocs(terraRef);
        for (const terraDoc of terraSnapshot.docs) {
          const resourceName = terraDoc.data().resource || null;
          if (resourceName === 'FREESTYLELIBRE') {
            deviceCounter++;
          }
        }
      }));

      setDeviceCount(deviceCounter);
    };

    fetchDevices();
  }, [uid]);

  return (
    <div className="dashboardMonitoring">
      <h1>Data Monitoring</h1>
      <p>Number of devices: {deviceCount}</p>
    </div>
  );
};

export default Dashboard;
