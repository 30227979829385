import { getFirestore, collection, getDocs, getDoc, doc, query, where, updateDoc, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

const teachinguploadFile = async (file, currentUser) => {
  const db = getFirestore();
  const userRef = doc(db, 'users', currentUser.uid);


  // Get the user document to retrieve the accountTier
  const userDoc = await getDoc(userRef);
  const userData = userDoc.data();
  const timestamp = Date.now();
  const uniqueName = `${timestamp}.pdf`;

  const storage = getStorage();  // get a reference to the default bucket
  const storageRef = ref(storage, `/teachingAI/${currentUser.uid}/${uniqueName}`);
  
  // upload the file
  const uploadTask = uploadBytesResumable(storageRef, file); // pass the file to uploadBytesResumable
  
  // wait for the upload to complete
  await uploadTask;
  
  // get the download URL
  const downloadURL = await getDownloadURL(storageRef);
  
  const documentsRef = collection(db, 'teachingDocuments');

  // Create a new document in Firestore
  const docRef = await addDoc(documentsRef, {
    uid: currentUser.uid,
    originalFilename: file.name,
    downloadURL,
    status: 'processing',
    deleteFlag: 1,
  });

  // Call teachAI function
  const functions = getFunctions();
  const teachAI = httpsCallableFromURL(functions, process.env.REACT_APP_TEACHINGAI_URL);
  await teachAI({ docId: docRef.id });

  return {
    success: true,
    message: 'File uploaded successfully.',
  };
};

export default teachinguploadFile;
