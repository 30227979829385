import React, { useState } from 'react';
import QuickSearch from './Quicksearch';
import QuicksearchData from './QuicksearchData';
import styles from './quicksearchpopup.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function QuickSearchPopup() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isPatientDataOpen, setIsPatientDataOpen] = useState(false);
  const [patientData, setPatientData] = useState(null);

  const toggleSearchPopup = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  // const handleSearchSuccess = (data) => {
  //   setPatientData(data);
  //   setIsSearchOpen(false);
  //   setIsPatientDataOpen(true);
  // };

  const closePatientDataPopup = () => {
    setIsPatientDataOpen(false);
    setPatientData(null);
  };

  return (
    <>
     <button
            id="quicksearchButton"
            onClick={toggleSearchPopup}
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundImage: 'linear-gradient(135deg, #1e5f9c, #206274)',
              color: '#fff',
              fontWeight: 'bold',
              cursor: 'pointer',
              boxShadow: '0px 1px 5px 0px #888888',
              minWidth: '200px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '10px' }} />
            Quick Search
          </button>


      {isSearchOpen && (
        <div className={styles.popupContainer}>
          <div className={styles.popupContent}>
            <button onClick={toggleSearchPopup}>
              Batch Eligibility
            </button>
          
            <QuickSearch />
          </div>
        </div>
      )}
      
    </>
  );
}

export default QuickSearchPopup;
