import React, { useState, useEffect } from "react";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import useUID from "../../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEdit,
  faPenFancy,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";

import Modal from "react-modal";
import { Button } from "react-bootstrap";
import FormEditor from "../FormEditor/FormEditor";
import FormDisplay from "./FormDisplay";

function FormSelection({
  onFormInputChange = () => {},
  selectedFormId = null,
  setSelectedFormId = null,
}) {
  // Use local states
  const [layouts, setLayouts] = useState([]);
  const [uid] = useUID();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [showFormEditor, setShowFormEditor] = useState(null);
  const [editFormId, setEditFormId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [selectedFormData, setSelectedFormData] = useState(null);

  const handleFormInputChange = (values) => {
    if (typeof onFormInputChange === "function") {
      console.log("test", values)
      onFormInputChange(values);
    }
  };

  const fetchLayouts = async () => {
    try {
      const formsCollectionRef = collection(db, `users/${uid}/forms`);
      const formsQuerySnapshot = await getDocs(formsCollectionRef);

      const allLayouts = [];

      formsQuerySnapshot.forEach((formDoc) => {
        const formData = formDoc.data();
        if (!formData.deleted) {
          allLayouts.push({
            id: formDoc.id,
            ...formData,
          });
        }
      });

      setLayouts(allLayouts);
    } catch (error) {
      console.error("Error fetching form data:", error);
      setLayouts([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!uid) return;
    fetchLayouts();
  }, [uid]);

  useEffect(() => {
    if (selectedFormId) {
      const selectedForm = layouts.find(
        (layout) => layout.id === selectedFormId
      );
      if (selectedForm) {
        setSelectedFormData(selectedForm.formElements);
      } else {
        setSelectedFormData(null);
      }
    } else {
      setSelectedFormData(null);
    }
  }, [selectedFormId, layouts]);

  const onClose = () => {
    setShowFormEditor(null);
  };

  const handleSave = async () => {
    await fetchLayouts();
    const updatedFormData = layouts.find(
      (layout) => layout.id === selectedFormId
    );

    console.log("updatedFormData", updatedFormData);
    if (updatedFormData) {
      handleSelectForm(updatedFormData);
    }

    setShowFormEditor(null);
    if (setSelectedFormId) {
      setSelectedFormId(updatedFormData?.id || null);
    }
  };

  const handleAddNewProvider = () => {
    setShowFormEditor(true);
    setEditFormId(null);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setProviderToDelete(null);
  };

  const handleEdit = (formId) => {
    setShowFormEditor(true);
    setEditFormId(formId);
    if (setSelectedFormId) {
      setSelectedFormId(formId);
    }
  };

  const handleSelectForm = (formData) => {
    if (
      formData === null ||
      (selectedFormId !== null && selectedFormId === formData?.id)
    ) {
      setSelectedFormId(null);
      setSelectedFormData(null);
    } else {
      if (setSelectedFormId) {
        setSelectedFormId(formData.id);
      }
      setSelectedFormData(formData.formElements);
    }
  };

  const confirmDelete = async (formId) => {
    try {
      const formDocRef = doc(db, `users/${uid}/forms`, formId);
      await deleteDoc(formDocRef); // Use 'deleteDoc' for hard delete
      setShowConfirmModal(false);
      setProviderToDelete(null);
      fetchLayouts(); // Refresh the layouts after delete
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  return (
    <>
      {showFormEditor && (
        <div className="actionContainer">
          <div className="popupContainer">
            <div className="popupContent">
              <button className="filesCloseButton" onClick={onClose}>
                X
              </button>
              <div className="container content-center w-100">
                <FormEditor formId={editFormId} handleSave={handleSave} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid-container w-100">
        {isLoading ? (
          <div className="grey-card h-250px w-100 content-center py-4">
            <HexagonSpinner />
            <h4 className="headerForm">Your providers are loading.</h4>
          </div>
        ) : (
          layouts.map((layout) => (
            <div
              key={layout.id}
              className={
                (layout.id === selectedFormId
                  ? "blue-card card-selected"
                  : "white-card") +
                " white-card card w-100 h-250px content-center text-center py-4"
              }
              onClick={() => handleSelectForm(layout)}
            >
              <FontAwesomeIcon className="mb-3 mx-0" icon={faPenFancy} />
              <h4 className="headerForm">{layout.title || "Custom Form test"}</h4>
              <h5>Last Updated:</h5>
              <h5>
                {layout.updatedAt
                  ? new Date(
                      layout.updatedAt.seconds * 1000
                    ).toLocaleDateString()
                  : "-"}
              </h5>
              <div className="button-group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(layout.id);
                  }}
                  className="secondary"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmModal(true);
                    setProviderToDelete(layout);
                  }}
                  className="secondary"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))
        )}
        <div
          className="white-card w-100 h-250px content-center py-4"
          onClick={handleAddNewProvider}
        >
          <FontAwesomeIcon icon={faPlus} style={{ height: "32px" }} />
          <h4 className="mt-3">Add a Layout</h4>
        </div>
      </div>

      {selectedFormData && (
        <FormDisplay
          formData={selectedFormData}
          onFormInputChange={handleFormInputChange}
        />
      )}

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className="confirmModal content-center text-center"
        overlayClassName="confirmModalOverlay"
      >
        <h2>Delete Form?</h2>
        <p>Are you sure you want to delete this form?</p>
        <div className="confirmButtons display-flex">
          <Button variant="delete" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            className="danger"
            onClick={() => confirmDelete(providerToDelete.id)}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(FormSelection);
