import React, { useState, useEffect } from 'react';
import QuickSearch from "../../Eligibility/Quicksearch";
import '../PatientFiles.modules.css';

function PatientFilesEligibility({ firstName, lastName, dob, gender, onClose, patientId, memberid, tradingPartnerName }) {

  function convertDobFormat(dob) {
    if (!dob) return '';
    const [month, day, year] = dob.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  return (
    <div className='actionContainer'>
      <div className="ClaimContainer">
        <button onClick={onClose} className="filesCloseButton">X</button>
        <div className='quickSearchHelper'>
        <QuickSearch
          firstName={firstName}
          lastName={lastName}
          dob={convertDobFormat(dob)}
          gender={gender}
          memberid={memberid}
          patientId={patientId}
          initialTradingPartnerName={tradingPartnerName}
        />

      </div>
      </div>
    </div>
  );
};

export default PatientFilesEligibility;
