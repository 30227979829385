import React, { useState } from 'react';
import HexSpinner from '../General/Animations/Hexspinner';

const PatientList = ({ patientsData, isLoading }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'descending' }); // Default to sorting by timestamp, descending
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState('Active'); // Possible values: 'Active', 'Inactive', 'Deleted'

  console.log(patientsData)

  const sortedPatients = React.useMemo(() => {
    return [...patientsData].sort((a, b) => {
      const aTimestamp = a.timestamp || 0;
      const bTimestamp = b.timestamp || 0;
      return aTimestamp < bTimestamp ? (sortConfig.direction === 'ascending' ? -1 : 1) :
             aTimestamp > bTimestamp ? (sortConfig.direction === 'ascending' ? 1 : -1) : 0;
    });
  }, [patientsData, sortConfig]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  
  
  const filteredPatients = sortedPatients.filter(patient => {
    const { firstName, lastName, isActive = false, isDeleted = false } = patient;
  
    const matchesName = firstName.toLowerCase().includes(searchTerm) || 
                        lastName.toLowerCase().includes(searchTerm);
  
    switch (filter) {
      case 'Active':
        // Show patients who are active and not deleted
        const isActiveFiltered = matchesName && isActive && !isDeleted;
        return isActiveFiltered;
      case 'Inactive':
        // Show patients who are inactive and not deleted
        const isInactiveFiltered = matchesName && !isActive && !isDeleted;
        return isInactiveFiltered;
      case 'Deleted':
        // Show patients who are marked as deleted regardless of their activity status
        const isDeletedFiltered = matchesName && isDeleted;
        return isDeletedFiltered;
      default:
        return false;
    }
  });
  
  

  const sortData = (key) => {
    setSortConfig(currentConfig => ({
      key,
      direction: currentConfig.key === key && currentConfig.direction === 'ascending' ? 'descending' : 'ascending'
    }));
  };

  return (
    <div className="monitoringPatientsList">
      {isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <HexSpinner />
          <h4 style={{ marginTop: "10rem" }}>Retrieving and analyzing your latest data.</h4>
        </div>
      ) : (
        <>
          <div>
            <button onClick={() => setFilter('Active')} style={{ color: filter === 'Active' ? 'Green' : '' }}>Active</button>
            <button onClick={() => setFilter('Inactive')} style={{ color: filter === 'Inactive' ? 'Green' : '' }}>Inactive</button>
            <button onClick={() => setFilter('Deleted')} style={{ color: filter === 'Deleted' ? 'Green' : '' }}>Deleted</button>
          </div>
          <input
            type="text"
            placeholder="Search by name"
            onChange={handleSearchChange}
          />
          <table>
            <thead>
              <tr>
                <th onClick={() => sortData('firstName')}>Name</th>
                <th onClick={() => sortData('dob')}>Date of Birth</th>
                <th onClick={() => sortData('gender')}>Gender</th>
                <th onClick={() => sortData('insurance')}>Insurance</th>
                <th onClick={() => sortData('isActive')}>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredPatients.map((patient) => (
                <tr
                  key={patient.id}
                  onClick={() => window.location.href = `/monitoring/${patient.id}`}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{patient.firstName} {patient.middleName} {patient.lastName}</td>
                  <td>{patient.dob}</td>
                  <td>{patient.gender}</td>
                  <td>{patient.insurance}{' | '}{patient.memberId}</td>
                  <td style={{ backgroundColor: patient.isActive ? 'lightgreen' : (patient.deleted ? 'grey' : 'lightcoral') }}>
                    {patient.isActive ? 'Active' : (patient.deleted ? 'Deleted' : patient.statusDetails)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default PatientList;
