import { useState, useEffect, useRef } from "react";
import { storage, auth, db } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import PayerSelection from '../Payers/PayerSelection';
import * as XLSX from 'xlsx';
import React from 'react';
import QuickSearchPopup from './QuickSearchPopup';
import CollapsibleSection from "./CollapsibleSection";
import './Eligibility.css';
import { doc,  collection, query, orderBy, onSnapshot, getDoc } from 'firebase/firestore';
import ProviderSelection from '../Providers/ProviderSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

function BatchEligibility() {
  const [progresspercent, setProgresspercent] = useState(0);
  const [files, setFiles] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [payers, setPayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [payerError, setPayerError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [isQuickSearchOpen, setIsQuickSearchOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(1);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [batchName, setBatchName] = useState('');
  const [batchNameError, setBatchNameError] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [fileErrors, setFileErrors] = useState([]);
  const [errorTable, setErrorTable] = useState([]);
  const fileInputRef = useRef(null);
  const [AccountTier, setAccountTier] = useState('');
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [providerNPI, setProviderNPI] = useState('');
  const [medicareNumber, setMedicareNumber] = useState('');
  const [medicaidNumber, setMedicaidNumber] = useState('');

// Add the new state variable for totalApiRequests
const [totalApiRequests, setTotalApiRequests] = useState(0);

useEffect(() => {
  const fetchAccountTierAndCheckRemainingRequests = async () => {

    const currentUser = auth.currentUser;
    const uid = currentUser ? currentUser.uid : null;

    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    const AccountTier = userDocSnap.data().AccountTier || '';
    setAccountTier(AccountTier);

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();

        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }
      });

      setTotalApiRequests(total); // Set the state variable for totalApiRequests

      if (total >= 25 && AccountTier === 'Freebie') {
        setHasRemainingRequests(false);
      } else {
        setHasRemainingRequests(true);
      }
    });

    return () => unsubscribe();
  };

  if (auth.currentUser) {
    fetchAccountTierAndCheckRemainingRequests();
  }
}, [auth.currentUser]);

const handleSubmit = async (e) => {
  e.preventDefault();

  const totalRequests = rowCount * payers.length;
  const isFreeTrial = AccountTier === 'Freebie';
  const reachedFreeTrialLimit = (totalApiRequests + totalRequests) > 25 && isFreeTrial;

  if (reachedFreeTrialLimit) {
    setErrorMessage(<span>You can only make up to 25 API requests in the free trial. You have made {totalApiRequests} requests, and you are trying to make {totalRequests} more. Please <a href="https://spectohealth.com/signupstripe" target="_blank" rel="noopener noreferrer">upgrade your account</a> to continue.</span>);
    return;
  }

  // Rest of the handleSubmit code for the batch eligibility API call
  if (!fileUploaded || !uploadedFile) { // Check if uploadedFile is null
    setErrorMessage("Please upload a file before submitting.");
    return;
  }

  const userId = currentUser ? currentUser.uid : '';
  const functions = getFunctions();
  const filePath = `BatchEligibility/${userId}/Upload/${batchName}_${uploadedFile.name}`;
  const batcheligibility = httpsCallableFromURL(functions, process.env.REACT_APP_BATCHELIGIBILITY_URL);
  batcheligibility({ filePath, selectedPayers: payers, npi: providerNPI, organizationName: providerOrganizationName, medicareNumber: medicareNumber, ...( medicareNumber && { medicareNumber}), ...(medicaidNumber && { medicaidNumber}) })
    .then((result) => {
      if (result.data.errors && result.data.errorMessage) {
        setErrorMessage(result.data.errorMessage);
      } else {
        // Handle success case
        setConfirmationMessage('Your submission was successful');
      }
    })
    .catch((error) => {
      console.log('Error calling batchEligibility:', error);
    });
  setShowSubmitButton(false);
};


  
  

  const handleBatchNameChange = (e) => {
    const newName = e.target.value;
    if (newName.includes("_")) {
      setBatchNameError("Batch name cannot contain underscore character");
    } else {
      setBatchName(newName);
      setBatchNameError("");
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
  }, []);

  const readExcelData = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const rowCount = data.length;
        resolve({ data, rowCount });
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

const validateFile = async (file) => {
  if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    setErrorMessage("Please upload a valid .xlsx file.");
    return false;
  }

  const { data } = await readExcelData(file);

  if (data.length > 1000) {
    errors.push("The uploaded file exceeds the maximum row limit of 1000.");
}

  const parsedData = data.map((row, rowIndex) => {
    const parsedRow = {};
    const errors = [];

    // List of mandatory fields
    const mandatoryFields = ["firstName", "lastName", "dob", "gender", "dos"];

    // Step 1: Check each mandatory field for emptiness
    mandatoryFields.forEach((key) => {
      const value = row[key];
      if (!value || value.toString().trim() === '') {
        errors.push({
          row: rowIndex + 1,
          message: `${key} field is empty.`,
        });
        return;  // Exit if error found for mandatory field
      }
      parsedRow[key] = value;
    });

    // Step 2: Do not proceed with any further validation if any mandatory field is empty
    if (errors.length) return { data: parsedRow, errors };

    // Step 3: Continue with format-specific validation
    Object.keys(row).forEach((key) => {
      const value = row[key];
      switch (key) {
        case "firstName":
        case "lastName":
          if (typeof row[key] !== "string" || !/^[A-Za-z- ]+$/.test(row[key])) {
            errors.push({
              row: rowIndex + 1,
              message: `${key} should only contain letters.`,
            });
          }
          break;
        case "dob":
        case "dos":
          const date = new Date(value);
          if (isNaN(date)) {
            errors.push({
              row: rowIndex + 1,
              message: `Invalid date format for ${key}. Expected format: MM/DD/YYYY`,
            });
          }
          break;
        case "gender":
          if (!/^[MF]$/.test(value)) {
            errors.push({
              row: rowIndex + 1,
              message: `Invalid value for ${key}. Expected value: M or F`,
            });
          }
          break;
        default:
          parsedRow[key] = value;
          break;
      }
    });

    return { data: parsedRow, errors };
  });

  const valid = parsedData.every((row) => row.errors.length === 0);

  if (!valid) {
    const errorMessages = parsedData.flatMap((row) => row.errors);
    setErrorTable(errorMessages);
    return false;
  }

  setErrorMessage("");
  return true;
};


  //if the file is dropped into the box
  async function handleDrop(event) {
  event.preventDefault();
  event.currentTarget.classList.remove("dragover");

  if (!batchName) {
    setBatchNameError("Please name the batch first before uploading.");
    return;
  } else {
    setBatchNameError("");
  }

  const fileList = event.dataTransfer.files;
  setFiles([fileList[0]]);

  const file = fileList[0];
  if (!file) return;

  const valid = await validateFile(file);
  if (!valid) return;

  // Set uploadedFile state variable here
  setUploadedFile(file);

  const userId = currentUser ? currentUser.uid : "";
  const storageRef = ref(
    storage,
    `BatchEligibility/${userId}/Upload/${batchName}_${file.name}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  readExcelData(file).then(({ data, rowCount }) => {
    setRowCount(rowCount);
  });

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setProgresspercent(progress);
    },
    (error) => {
      console.error(error);
      setErrorMessage(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref)
        .then((downloadURL) => {
          setImgUrl(downloadURL);
          setFileUploaded(true);
          setActiveSection(2); // Move to the next section after a successful upload
        })
        .catch((error) => {
          console.log("Error getting + URL:", error);
        });
    }
  );
}

  //if file is selected via the file browser
  async function handleFileInputChange(e) {
    const file = e.target.files[0];
    if (!file) return;
    setFiles([file]);
  
    const valid = await validateFile(file);
    if (!valid) return;
  
    const userId = currentUser ? currentUser.uid : "";
    const storageRef = ref(
      storage,
      `BatchEligibility/${userId}/Upload/${batchName}_${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    readExcelData(file).then(({ data, rowCount }) => {
      setRowCount(rowCount);
    });
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        console.error(error);
        setErrorMessage(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setImgUrl(downloadURL);
            setFileUploaded(true);
            setActiveSection(2); // Move to the next section after a successful upload
          })
          .catch((error) => {
            console.log("Error getting download URL:", error);
          });
      }
    );
  }
  

  const handleFileInputClick = (e) => {
    if (!batchName) {
      setBatchNameError("Please name the batch first before uploading.");
      e.preventDefault(); // Prevent the file input from opening
    } else if (fileErrors.length > 0) {
      setErrorMessage("Please fix the file errors before uploading.");
      e.preventDefault(); // Prevent the file input from opening
    } else {
      setBatchNameError("");
      fileInputRef.current.click(); // Open the file input dialog
    }
  };


  const handleSection2Submit = async (e) => {
    if (!fileUploaded) {
      setErrorMessage("Please upload a file before selecting payers.");
      return;
    }
  
    if (payers.length === 0) {
      setPayerError("Please select a payer before submitting.");
      return;
    }
  
    // Assuming that NPI and organization name are mandatory for provider validation
    if (!providerNPI || !providerOrganizationName) {
      setErrorMessage("Please select a valid provider before proceeding.");
      return;
    }
  
    const { data, rowCount } = await readExcelData(files[0]);
    setRowCount(rowCount);
  
    const parsedData = data.map((row, rowIndex) => {
      const parsedRow = {};
      const errors = [];
      
      if (errors.length > 0) {
        console.log(`Row ${rowIndex + 1}: ${errors.join(", ")}`);
      }
      return { data: parsedRow, errors };
    });
  
    const valid = parsedData.every((row) => row.errors.length === 0);
    setFileErrors(parsedData.filter((row) => row.errors.length > 0).map((row) => row.errors));
  
    if (!valid) {
      const errorMessages = fileErrors.map((error, index) => {
        return `Row ${index + 1}: ${error.join(", ")}`;
      });
      setErrorMessage(`Errors found in the file: ${errorMessages.join(", ")}`);
      return;
    }
  
    setPayerError("");
    setErrorMessage("");
    setActiveSection(3);
    setUploadedFile(files[0]);
  };
  
  
  
  const handlePayerSelection = (selectedPayers) => {
    // Check if any of the selected payers is a BCBS payer
    const isBCBS = selectedPayers.some(payer => payer.name.includes("Blue Cross Blue Shield")); // Assuming payer has a 'name' property. Adjust as needed.

    if (isBCBS) {
        setErrorMessage("Blue Cross Blue Shield payers must have a member ID.");
    } else {
        setErrorMessage(""); // Clear error message if no BCBS payer is selected
    }
    
    setPayers(selectedPayers);
};


  function handleDownload() {
    const message = document.getElementById('message');
    message.style.display = 'block';
    
    // Call your download function here
    downloadXLSX();
  }

  // This function downloads the XLSX file template from Firebase storage
  const downloadXLSX = async () => {
    // Define the path of your template
    const templatePath = 'xlsxtemplates/eligibilitytemplate.xlsx';
  
    // Get a reference to the storage service
    const storageRef = ref(storage, templatePath);
  
    // Get the download URL
    const url = await getDownloadURL(storageRef);
  
    // Use that URL to initiate a download
    let link = document.createElement("a");
    link.href = url;
    link.download = "template.xlsx"; // Set the filename
    document.body.appendChild(link);
    link.click();
  };

const openQuickSearch = () => {
  setIsQuickSearchOpen(true);
};

const closeQuickSearch = () => {
  setIsQuickSearchOpen(false);
};

function handleDragOver(event) {
  if (fileErrors.length > 0) {
    setErrorMessage("Please fix the file errors before uploading.");
    return; // Return early to prevent drag-over effect
  }
  event.preventDefault();
  event.currentTarget.classList.add('dragover');
}

function handleDragLeave(event) {
  event.preventDefault();
  event.currentTarget.classList.remove('dragover');
}

const handleNewBatch = () => {
  window.location.reload();
};

function handleDownload() {
  const message = document.getElementById('message');
  message.style.display = 'block';
  
  // Call your download function here
  downloadXLSX();
}

useEffect(() => {
  if (errorTable.length > 0) {
    fileInputRef.current.value = null; // This will reset the file input
  }
}, [errorTable]); // This effect runs whenever errorTable changes

  
return (
  <div className="eligibility-inner-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
    <div style={{ marginRight: '10px' }}>
      <QuickSearchPopup />
    </div>
    {errorMessage && <div className="error-message">{errorMessage}</div>}
    {payerError && <div className="error-message">{payerError}</div>}
    <div className="checkout">
      <CollapsibleSection
        title="New Batch"
        number={1}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      >
        <div className="checkoutformContainer">
          <div style={{ display: 'fixed', alignItems: 'center' }}>
            <input
              type="text"
              id="batchName"
              placeholder="Batch Name"
              onChange={handleBatchNameChange}
              style={{ color: 'gray', marginRight: '10px' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <button id="templateButton" onClick={handleDownload}>
              Download XLSX Template
            </button>
          </div>
          <div id="message" style={{ display: 'none' }}>
            Please fill out all fields. Do not modify headers. Address 2 and Policy Number are optional.
          </div>
          </div>
          {batchNameError && <div className="error-message">{batchNameError}</div>}
          {errorTable.length > 0 && (
            <div className="error-table-container">
              <h3>Errors found in the file:</h3>
              <table>
                <thead>
                  <tr>
                    <th>Row</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>
                  {errorTable.map((error, index) => (
                    <tr key={index}>
                      <td>{error.row+1}</td>
                      <td>{error.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {imgUrl && (
            <div className="outerbar">
              <div className="innerbar" style={{ width: `${progresspercent}%` }}>
                {progresspercent}%
              </div>
            </div>
          )}
          {!imgUrl && (
            <div>
              <input
                type="file"
                accept=".xlsx"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
              <div
                className="dropbox"
                onClick={handleFileInputClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                title="Upload files"

              >
                <div className='dropBoxContent'>
                    <FontAwesomeIcon icon={faCloudArrowUp}
                        style={{ height: "3rem", marginBottom: ".5rem" }}/>                
                </div>
                <p>Click to browse or drop files in here.</p>
            </div>
            </div>
          )}
        </div>
      </CollapsibleSection>
      <CollapsibleSection
        title="Choose Payers and Provider"
        number={2}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      >
        <div className="payerSelection">
          <PayerSelection onSelect={handlePayerSelection} payers={payers} mode={'eligibility'} />
        </div>
        <h3>Provider</h3>
          <ProviderSelection
          onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
            setProviderOrganizationName(organizationName);
              setProviderNPI(npi);
              setMedicareNumber(medicareNumber)
              setMedicaidNumber(medicaidNumber)
            }}
          />
      </CollapsibleSection>
      {activeSection === 2 && (
        <button type="button" onClick={handleSection2Submit}>
          Continue
        </button>
      )}

      <CollapsibleSection
          title="Review and Submit"
          number={3}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        >
          <div className="reviewAndSubmit">
            <p>Number of patients in the file: {rowCount}</p> {/* Display the patient count */}
            <p>Number of payers selected: {payers.length}</p>
            <p>Number of messages: {rowCount * payers.length}</p> {/* Calculate and display the number of requests */}
            <div className="button-container">
              {showSubmitButton && (
                <button type="button" onClick={handleSubmit}>
                  Submit
                </button>
              )}
              {!showSubmitButton && <div>File sent for processing. Please check the output folder for your file.</div>}

              {/* add some spacing between the buttons */}
              <div className="button-spacing" />

              <button onClick={handleNewBatch}>New Batch or Cancel</button>
            </div>
          </div>
        </CollapsibleSection>
        

      </div>
    </div>
);


}
export default BatchEligibility;

