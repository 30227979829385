import React, { useEffect, useState, useRef } from 'react';
import { collection, doc, query, onSnapshot, addDoc, orderBy, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import useUID from './useUID';
import { db } from "../../firebase";
import Topbar from './Topbar';
import styles from './MainStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function Messages() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [uid, subUserUID] = useUID();
  const [Name, setName] = useState('');
  const messagesEndRef = useRef(null); 
  const [activeSenderId, setActiveSenderId] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    // Determine and set the active sender ID based on whether a subUserUID is present
    setActiveSenderId(subUserUID || uid);
  }, [uid, subUserUID]);

  // This useEffect is for fetching the user's or subuser's name
  useEffect(() => {
    const fetchUserName = async () => {
      let userDocRef;
      if (subUserUID) {
        userDocRef = doc(db, "users", uid, "subusers", subUserUID);
      } else {
        userDocRef = doc(db, "users", uid);
      }

      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setName(`${userData.firstName} ${userData.lastName}`); // Assuming the user has firstName and lastName fields
        }
      }, (error) => {
        console.error("Error fetching user details:", error);
      });

      return unsubscribe;
    };

    if (uid) fetchUserName();
  }, [uid, subUserUID]);

  // This useEffect is for subscribing to the messages collection
  useEffect(() => {
    if (!uid) return;

    const q = query(collection(db, `users/${uid}/messages`), orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(), // Assuming you're using Firebase Timestamps
      }));
      setMessages(fetchedMessages);
      setInitialLoadComplete(true);
    });

    return () => unsubscribe();
  }, [uid]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !Name) return; // Also check if Name is set
  
    try {
      await addDoc(collection(db, `users/${uid}/messages`), {
        message: newMessage,
        timestamp: serverTimestamp(),
        senderId: subUserUID || uid,
        senderName: Name, // Corrected to match state variable case
      });
      setNewMessage('');
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };
  
  const handleKeyDown = (e) => {
    // Check if Enter key is pressed without the Shift key
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default action (new line)
      handleSendMessage(e); // Call the send message function
    }
  };
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Dependency array includes messages to trigger the effect on update

  const renderMessage = (message) => (
    <div key={message.id} className={`message ${message.senderId === (subUserUID || uid) ? 'sent' : 'received'}`}>
      {message.senderName && <div className="senderName">{message.senderName}</div>}
      <div className="messageText">{message.message}</div>
      <div className="timestamp">{message.timestamp?.toLocaleString()}</div>
    </div>
  );

  return (
    <div>
      <Topbar />
      <div className='MessageContainer'>
        <div className="messagesList">
        {initialLoadComplete && messages.length === 0 ? (
            <div className="noMessages">Welcome! This is a practice chat system for internal staff. It is HIPAA compliant and is shared accross your organization.</div>
          ) : (
            messages.map(renderMessage)
          )}

          <div ref={messagesEndRef} /> {/* This div is the scroll target */}
        </div>
        <form onSubmit={handleSendMessage} className="inputArea">
        <textarea
            style={{ paddingRight: '1rem'}}
            className='textarea'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here..."
            onKeyDown={handleKeyDown} // Add the onKeyDown event listener here
            />
        <button className="sendButton">
            <FontAwesomeIcon icon={faArrowUp} />
        </button>        
    </form>
      </div>
    </div>
  );
}

export default Messages;
