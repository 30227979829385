import { useState, useEffect, useRef } from "react";
import { storage, auth, db } from '../../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, list, listAll } from "firebase/storage";
import { getFunctions, httpsCallableFromURL, httpsCallable } from 'firebase/functions';
import PayerSelection from '../Payers/PayerSelection';
import * as XLSX from 'xlsx';
import React from 'react';
import CollapsibleSection from "./CollapsibleSection";
import './Claims.css';
import { doc,  collection, query, orderBy, onSnapshot, getDoc, getDocs } from 'firebase/firestore';
import styles from './Claims.css';
import ServiceLineTable from './ServiceLineTable';
import HexSpinner from '../General/Animations/Hexspinner';
import Confetti from '../General/Animations/Confetti';
import ProviderSelection from '../Providers/ProviderSelection';
import PayerMatchingModal from '../PatientFiles/AddPatient/PayerMatching'
import { ProviderProvider } from '../../context/ProviderContext'
import useUID from '../General/useUID'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

function BatchClaims({ onClose }) {
  const [progresspercent, setProgresspercent] = useState(0);
  const [files, setFiles] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedPayers, setSelectedPayers] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [payerError, setPayerError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [isSingleClaimOpen, setIsSingleClaimOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(1);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [batchName, setBatchName] = useState('');
  const [batchNameError, setBatchNameError] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [fileErrors, setFileErrors] = useState([]);
  const [errorTable, setErrorTable] = useState([]);
  const fileInputRef = useRef(null);
  const [AccountTier, setAccountTier] = useState('');
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [payers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCharges, setTotalCharges] = useState(0);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [selectedRenderingProviders, setSelectedRenderingProviders] = useState([]);
  const [selectedBillingProviders, setSelectedBillingProviders] = useState([]);
  const [selectedReferingProviders, setSelectedReferingProviders] = useState([]);
  const [DatesOfService, setDatesOfService] = useState("");
  const [filePath, setFilePath] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [fileName, setFileName] = useState("");
  const [failedPatients, setFailedPatients] = useState([]);
  const [isPayerModalOpen, setIsPayerModalOpen] = useState(false);
  const [showPayerSelection, setShowPayerSelection] = useState(true);
  const [errors2, setErrors2] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [hasPayerInExcel, setHasPayerInExcel] = useState(false);
  const [totalApiRequests, setTotalApiRequests] = useState(0);
  const [uid, subUserUID] = useUID();


  const myRef = useRef(null);


    useEffect(() => {
      const fetchAccountTierAndCheckRemainingRequests = async () => {

        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);

        const AccountTier = userDocSnap.data().AccountTier || '';
        setAccountTier(AccountTier);

        const requestsRef = collection(db, "users", uid, "API");
        const q = query(requestsRef, orderBy("timestamp", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let total = 0;
          querySnapshot.forEach((doc) => {
            const requestData = doc.data();

            if (requestData.APIRequests) {
              const apiRequestsCount = parseInt(requestData.APIRequests, 10);
              total += apiRequestsCount;
            }
          });

          setTotalApiRequests(total); // Set the state variable for totalApiRequests

          if (total >= 25 && AccountTier === 'Freebie') {
            setHasRemainingRequests(false);
          } else {
            setHasRemainingRequests(true);
          }
        });

        return () => unsubscribe();
      };

      if (auth.currentUser) {
        fetchAccountTierAndCheckRemainingRequests();
      }
    }, [auth.currentUser]);

    const handleSubmit = async (e) => {
      e.preventDefault();

          // Check if the number of claims exceeds 50
        if (rowCount > 150) {
          const totalSeconds = rowCount * 1.8;
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = Math.round(totalSeconds % 60);

          const message = `It is estimated to take ${minutes} minutes and ${seconds} seconds to process your claims. You will get an email when complete. Thank you.`;
          setErrorMessage(message);
      }
      
      setIsLoading(true);
      setShowSubmitButton(false);
      const uid = auth.currentUser.uid;

      const claimData = {
        selectedPayers: selectedPayers,
        uid,
        selectedBillingCodes,
        selectedServiceProviders,
        selectedRenderingProviders,
        selectedBillingProviders,
        selectedReferingProviders,
        DatesOfService,
        totalCharges
      };



      const totalRequests = rowCount;
      const isFreeTrial = AccountTier === 'Freebie';
      const reachedFreeTrialLimit = (totalApiRequests + totalRequests) > 25 && isFreeTrial;

      if (reachedFreeTrialLimit) {
        setErrorMessage(<span>You can only make up to 25 API requests in the free trial. You have made {totalApiRequests} requests, and you are trying to make {totalRequests} more. Please <a href="https://spectohealth.com/payment" target="_blank" rel="noopener noreferrer">upgrade your account</a> to continue.</span>);
        return;
      }

      // Rest of the handleSubmit code for the batch Claims API call
      if (!fileUploaded || !uploadedFile) { // Check if uploadedFile is null
        setErrorMessage("Please upload a file before submitting.");
        return;
      }

      const userId = currentUser ? currentUser.uid : '';
      const functions = getFunctions();
      const filePath = `BatchClaims/${userId}/Upload/${fileName}`;  
      const dateTimeString = getCurrentDateTime();
      setBatchName(dateTimeString);


      const batchclaims = httpsCallableFromURL(functions, process.env.REACT_APP_BATCHCLAIMS_URL);
          batchclaims({ filePath, claimData })
          .then((result) => {
    
            if (result.data.errors && result.data.errorMessage) {
                setErrorMessage(result.data.errorMessage);
                setFailedPatients(result.data.errors);
    
                failedPatients.forEach(failedPatient => {
                    console.log('Error:', failedPatient.error);
                });
            }
    
            if (result.data.successCount && result.data.successCount > 0) {
                // Set success messages and count only if there were successful claims
                setSuccessMessages(result.data.successMessages);
                setSuccessCount(result.data.successCount);
            }
    
            setShowSuccessMessage(true);
            setIsLoading(false);
            setShowSubmitButton(true);
        })
        .catch((error) => {
            console.log('Error calling batchClaims:', error);
        });
      
    };


  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
  }, []);

  const readExcelData = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const rowCount = data.length;
        resolve({ data, rowCount });
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  const fetchMatchedPayers = async () => {
    const uid = auth.currentUser.uid;
    const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
    const payerMatchesSnapshot = await getDocs(payerMatchesCollection);
    // Convert payer names to uppercase for case-insensitive comparison
    return payerMatchesSnapshot.docs.map(doc => doc.data().name.toUpperCase());
  };
  
  const validateFile = async (file) => {
    const errorMessages = [];
  
    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      errorMessages.push("Please upload a valid .xlsx file.");
    }
  
    const matchedPayers = await fetchMatchedPayers();
    const subHeaders = [
        "firstName", "middleName", "lastName", "dateOfBirth", "gender","address1", "address2", "city", "state", "zip", "PAYER", "memberId", "dateOfService", 
    ];

    const { data } = await readExcelData(file);

    if (data.length > 2000) {
      errorMessages.push("The uploaded file exceeds the maximum row limit of 2000.");
  }
  
    const errors = [];
    const parsedData = data.map((row, rowIndex) => {
        const parsedRow = {};
        const rowErrors = [];

        Object.keys(row).forEach((key) => {
            if (subHeaders.includes(key)) {
          if (key === "firstName") {
            if (typeof row[key] !== "string" || !/^[A-Za-z- ]+$/.test(row[key])) {
              errors.push({
                  row: rowIndex + 1,
                  message: "Sub first name should only contain letters, hyphens, and spaces.",
              });
          }          
            parsedRow[key] = row[key];
          } else if (key === "lastName") {
            if (typeof row[key] !== "string" || !/^[A-Za-z- ]+$/.test(row[key])) {
              errors.push({
                  row: rowIndex + 1,
                  message: "Sub last name should only contain letters, hyphens, and spaces.",
              });
          }          
            parsedRow[key] = row[key];
          } else if (key === "dateOfBirth") {
            const date = new Date(row[key]);
            if (isNaN(date)) {
              errors.push({
                row: rowIndex + 1,
                message: "Invalid date format for subdob. Expected format: MM/DD/YYYY",
              });
            }
            parsedRow[key] = date;
          } else if (key === "dateOfService") {
            const date = new Date(row[key]);
            if (isNaN(date)) {
              errors.push({
                row: rowIndex + 1,
                message: "Invalid date format for subdos. Expected format: MM/DD/YYYY",
              });
            }
            parsedRow[key] = date;
          } else if (key === "gender") {
            if (row[key] !== 'M' && row[key] !== 'F') {
              errors.push({
                  row: rowIndex + 1,
                  message: "Invalid gender. Expected 'M' or 'F'.",
              });
          }            
          parsedRow[key] = row[key];
        } else if (key === "memberId") {
          parsedRow[key] = String(row[key]).replace(/[^a-zA-Z0-9]/g, '');
          } else if (key === "address1") {
            parsedRow[key] = row[key];
          } else if (key === "address2") {
            parsedRow[key] = row[key];
          } else if (key === "city") {
            parsedRow[key] = row[key];
          } else if (key === "state") {
            parsedRow[key] = row[key];
          } else if (key === "zip") {
            // Check if in development mode
            if (process.env.NODE_ENV === "development") {
                if (!/^\d{9}$/.test(row[key])) {
                    errors.push({
                        row: rowIndex + 1,
                        message: "Zip code should be 9 digits long in development mode.",
                    });
                }
            } else { // Assume production if not development
                if (!/^\d{5}$/.test(row[key])) {
                    errors.push({
                        row: rowIndex + 1,
                        message: "Zip code should be 5 digits long.",
                    });
                }
            }
            parsedRow[key] = row[key];
        }
        
        if (key === "PAYER") {
          // Convert the PAYER value from the file to uppercase
          const payerValue = row["PAYER"].toUpperCase();
          // Check if the uppercase PAYER matches the payers in the database
          if (payerValue && !matchedPayers.includes(payerValue)) {
              rowErrors.push({
                  row: rowIndex + 1,
                  message: `Invalid payer '${row["PAYER"]}'. Please use a matched payer. Review step 2 above for more information.`
              });
          }
          parsedRow[key] = row[key];
      }
        }
      });
  
    const requiredFields = subHeaders.filter(header => !["PAYER", "address2", "middleName"].includes(header));
    const hasEmptyValues = requiredFields.some(header => !row[header]);
    const hasFilledValues = requiredFields.some(header => !!row[header]);
    
    if (hasEmptyValues && hasFilledValues) {
        rowErrors.push({
            row: rowIndex + 1,
            message: "Inconsistency in data: If one cell in a row is filled, all cells in that row must also be filled (except optional fields).",
        });
    }

    if (rowErrors.length > 0) {
        errors.push(...rowErrors);
        return false;
    }

    return parsedRow;
});

if (errors.length > 0) {
    setErrorTable(errors);
    return false;
}
    
   // Check if there are rows with PAYER data and rows without PAYER data
   const hasInvalidPayerMatch = parsedData.some(row => row && row.PAYER && !matchedPayers.includes(row.PAYER.toUpperCase()));

    if (hasInvalidPayerMatch) {
        setErrorMessage("One or more rows have an invalid PAYER entry that does not match the available payers.");
        return false;
    }
   
   // Check if there are rows with PAYER data and rows without PAYER data
   const hasAnyRowWithPayerData = parsedData.some(row => row && row.PAYER && row.PAYER.trim() !== "");
   const hasAnyRowWithoutPayerData = parsedData.some(row => row && (!row.PAYER || row.PAYER.trim() === ""));
   setHasPayerInExcel(hasAnyRowWithPayerData);

   // If there's inconsistency in PAYER data (some filled and some empty), display an error
   if (hasAnyRowWithPayerData && hasAnyRowWithoutPayerData) {
       setErrorMessage("Inconsistency in PAYER data: All rows should either have PAYER data or none should have it.");
       return false;
   }
   
   // Logic related to payer selection based on the presence of PAYER data
   if (!hasAnyRowWithPayerData) {
       setShowPayerSelection(true);
   } else {
       setShowPayerSelection(false);  // Assuming you don't want to show payer selection if there are PAYER cells filled
   }
   
   if (errorMessages.length > 0) {
    setErrorMessage(errorMessages.join(' ')); // Joining all error messages
    return false;
  }

  if (errors.length > 0) {
    setErrorTable(errors);
    return false;
  }

  return true; // If no error triggered, validation is successful
};

  //file upload logic if dropped in
  async function handleDrop(event) {
    event.preventDefault();
    event.currentTarget.classList.remove("dragover");
  
  
    const fileList = event.dataTransfer.files;
    setFiles([fileList[0]]);
  
    const file = fileList[0];
    if (!file) return;
  
    const valid = await validateFile(file);
    if (!valid) return;
  
    setUploadedFile(file);


    const userId = currentUser ? currentUser.uid : "";
    const randomNum = Math.floor(Math.random() * 1000000);
    const generatedFileName = `${getCurrentDateTime()}.${randomNum}`;
    setFileName(generatedFileName); // Save the generated file name to the state
    const storageRef = ref(storage, `BatchClaims/${userId}/Upload/${generatedFileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
  
    readExcelData(file).then(({ data, rowCount }) => {
      setRowCount(rowCount);
    });
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        console.error(error);
        setErrorMessage(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setImgUrl(downloadURL);
            setFileUploaded(true);
            setActiveSection(2); // Move to the next section after a successful upload
          })
          .catch((error) => {
            console.log("Error getting + URL:", error);
          });
      }
    );
  }
  

  async function folderExists(storage, path) {
    try {
      const folderRef = ref(storage, path);
      await list(folderRef, { maxResults: 1 });
      return true;
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        return false;
      }
      throw error;
    }
  }
  
  async function createFolder(storage, path) {
    const folderRef = ref(storage, `${path}/dummy.txt`);
    await uploadBytesResumable(folderRef, new Blob(["dummy"], { type: "text/plain" }));
  }


  //logic if selected via browser
  async function handleFileInputChange(e) {
    const file = e.target.files[0];
    if (!file) return;
    setFiles([file]);
  
    const valid = await validateFile(file);
    if (!valid) return;
    
    setUploadedFile(file);
  
    const userId = currentUser ? currentUser.uid : "";
    const randomNum = Math.floor(Math.random() * 1000000);
    const generatedFileName = `${getCurrentDateTime()}.${randomNum}`;
    setFileName(generatedFileName); // Save the generated file name to the state
    const storageRef = ref(storage, `BatchClaims/${userId}/Upload/${generatedFileName}`);

      
    const folderPath = `BatchClaims/${userId}/Upload`;
  
    const folderExistsInStorage = await folderExists(storage, folderPath);
    if (!folderExistsInStorage) {
      await createFolder(storage, folderPath);
    }
  
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    readExcelData(file).then(({ data, rowCount }) => {
      setRowCount(rowCount);
    });
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        console.error(error);
        setErrorMessage(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setImgUrl(downloadURL);
            setFileUploaded(true);
  
            setActiveSection(2); // Move to the next section after a successful upload
          })
          .catch((error) => {
            console.log("Error getting download URL:", error);
          });
      }
    );
  }


  const handleFileInputClick = (e) => {
    if (fileErrors.length > 0) {
      setErrorMessage("Please fix the file errors before uploading.");
      e.preventDefault(); // Prevent the file input from opening
    } else {
      fileInputRef.current.click(); // Open the file input dialog
    }
  };


// Helper function to convert the string to ArrayBuffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function handleDragOver(event) {
  if (fileErrors.length > 0) {
    setErrorMessage("Please fix the file errors before uploading.");
    return; // Return early to prevent drag-over effect
  }
  event.preventDefault();
  event.currentTarget.classList.add('dragover');
}

function handleDragLeave(event) {
  event.preventDefault();
  event.currentTarget.classList.remove('dragover');
}

const handleNewBatch = () => {
  window.location.reload();
};

const handleBillingProviderSelection = (providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
  const providerData = {
      providerId, 
      organizationName, 
      firstName, 
      lastName, 
      npi, 
      ein, 
      address1, 
      city, 
      state, 
      zip, 
      posCode, 
      cliaNumber, 
      medicareNumber
  };

  console.log('Selected Billing Provider:', providerData);
  setSelectedBillingProviders(providerData);
};


const handleUnifiedProviderSelection = (providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
  const providerData = {
      providerId, 
      organizationName, 
      firstName, 
      lastName, 
      npi, 
      ein, 
      address1, 
      city, 
      state, 
      zip, 
      posCode, 
      cliaNumber, 
      medicareNumber
  };

  setSelectedServiceProviders(providerData);
  setSelectedRenderingProviders(providerData);
  setSelectedReferingProviders(providerData);
};


  
  const handleBillingCodesSelect = (selectedCodes) => {
    setSelectedBillingCodes(selectedCodes);
  };
  
  const handleTotalChargesChange = (charges) => {
    setTotalCharges(charges);
  };
  
  const handlePayerSelect = (payer) => {
    setSelectedPayers(payer);
  };
  
  const handlePayerUnselect = () => {
    setSelectedPayers(null);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    });
    const time = now.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
    const formattedDate = date.replace(/\//g, '.');
    const formattedTime = time.replace(/:/g, '.');
    return `${formattedDate}.${formattedTime}`;
  };
  
  
  function handleDownload() {
    const message = document.getElementById('message');
    message.style.display = 'block';
    
    // Call your download function here
    downloadXLSX();
  }

  // This function downloads the XLSX file template from Firebase storage
  const downloadXLSX = async () => {
    // Define the path of your template
    const templatePath = 'xlsxtemplates/claimstemplate.xlsx';
  
    // Get a reference to the storage service
    const storageRef = ref(storage, templatePath);
  
    // Get the download URL
    const url = await getDownloadURL(storageRef);
  
    // Use that URL to initiate a download
    let link = document.createElement("a");
    link.href = url;
    link.download = "template.xlsx"; // Set the filename
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (activeSection === 3) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeSection]);
  
  useEffect(() => {
    if (errorTable.length > 0) {
      fileInputRef.current.value = null; // This will reset the file input
    }
  }, [errorTable]); // This effect runs whenever errorTable changes
  
  
  const validateSection2 = () => {
    let sectionErrors = {};
  
    // Only validate payer if it's not filled in the Excel
    if (!hasPayerInExcel && !selectedPayers) {
      sectionErrors.payer = "Payer is required";
    }
  
    if (selectedBillingCodes.length === 0) sectionErrors.billingCode = "Billing code is required";
  
    setErrors2(sectionErrors);
    return Object.keys(sectionErrors).length === 0;
  }
  

  const canProceedToNextSection = () => {
    const isProviderSelected = (provider) => {
      const isSelected = provider && Object.keys(provider).length > 0;
      return isSelected;
    };
  
    const areAllProvidersSelected = isProviderSelected(selectedBillingProviders)
      && isProviderSelected(selectedServiceProviders)
      && isProviderSelected(selectedRenderingProviders)
      && isProviderSelected(selectedReferingProviders);
  
    return areAllProvidersSelected;
  };

  
return (
  <div className="Claims-inner-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
    <button className="filesCloseButton" onClick={onClose}>
            X
      </button>    
    {isPayerModalOpen ? <PayerMatchingModal onClose={() => setIsPayerModalOpen(false)} /> : null}
    {payerError && <div className="error-message">{payerError}</div>}

    <div className="checkout">
    <div className="centerHeader">
          <h3>Batch Claims Submission</h3>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <CollapsibleSection
          title="New Batch"
          number={1}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        >
          <div className="stepsContainer">
            <button id="templateButton" onClick={handleDownload}>
              Download XLSX Template
            </button>

            <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>

            <button             
                title="Match payers in your spreadsheet with those in specto."
                id="templateButton" 
                onClick={() => setIsPayerModalOpen(true)}>
                  Payer Matching for Template
                </button>

            <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>

            <div>
              <input
                type="file"
                accept=".xlsx"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
                  <div
                    className="dropbox"
                    onClick={handleFileInputClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    title="Upload files"
                    style={{ 
                      display: "flex", 
                      flexDirection: "column", 
                      alignItems: "center", 
                      justifyContent: "center",
                      padding: "1rem"
                  }}
              >
                  <div className='dropBoxContent'>
                    <FontAwesomeIcon icon={faCloudArrowUp}
                        style={{ height: "3rem", marginBottom: ".5rem" }}/>                
                </div>
                <p>Click to browse or drop files in here.</p>
            </div>
            </div>
          </div>

          {imgUrl && (
            <div className="outerbar">
              <div className="innerbar" style={{ width: `${progresspercent}%` }}>
                {progresspercent}%
              </div>
            </div>
          )}

          {errorTable.length > 0 && (
            <div className="error-table-container">
              <h3>Errors found in the file:</h3>
              <table>
                <thead>
                  <tr>
                    <th>Row</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>
                  {errorTable.map((error, index) => (
                    <tr key={index}>
                      <td>{error.row + 1}</td>
                      <td>{error.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div id="message" style={{ display: 'none' }}>
            Please fill out all fields. Do not modify headers. Please read the instructions.  
          </div>
        </CollapsibleSection>

      <CollapsibleSection
      title="Payer"
      number={2}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
    >
    {showPayerSelection && (

      <div className={styles.payerSelection}>
        {!selectedPayers && (
          <PayerSelection
            payers={payers}
            onSelect={handlePayerSelect}
            mode={'claims'}
            required
          />
        )}
        {selectedPayers && (
          <div>
            <h3>Selected Payer:</h3>
            <p>{selectedPayers.name}</p>
            <button onClick={handlePayerUnselect}>Change</button>
          </div>
        )}
            </div>
          )}

            <ServiceLineTable
              onBillingCodesSelect={handleBillingCodesSelect}
              onTotalChargesChange={handleTotalChargesChange}
              required
            />
            
            <div className='errors2Section'>
                {errors2.payer && <div className="error-text">{errors2.payer}</div>}
                {errors2.billingCode && <div className="error-text">{errors2.billingCode}</div>}
            </div>


            <button
                  type="button"
                  onClick={() => {
                    if (validateSection2()) {
                      setActiveSection((prevSection) => prevSection + 1);
                    }
                  }}
                >
                  Next
                </button>           
              </CollapsibleSection>
              <CollapsibleSection
              ref={myRef}
              title="Provider"
              number={3}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              style={{ paddingLeft: 0 }}
          >
            <ProviderProvider uid={uid}>
            <h3>a. Select a Billing Provider</h3>
              <h4>The important one. Add medicare number, medicaid number, and CLIA here.</h4>
              <ProviderSelection
                  type="billing"
                  onProviderSelect={handleBillingProviderSelection}
              />
              <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
              <h3>b. Select a Service Provider</h3>
              <h4>Servicing and rendering provider.</h4>          
              <ProviderSelection
              type="unified"
              onProviderSelect={handleUnifiedProviderSelection}
                  />
              <div className={styles.nextButtonContainer}>
                  <button
                      type="button"
                      disabled={!canProceedToNextSection()}
                      onClick={() => {
                          setActiveSection(activeSection + 1);
                      }}
                  >
                      Next
                  </button>
                  {!canProceedToNextSection() && <span className="error-text">Please select all providers</span>}

              </div>
              </ProviderProvider>
          </CollapsibleSection>


      <CollapsibleSection
          title="Review and Submit"
          number={4}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        >
          <div className="reviewAndSubmit">
            <p style={{ borderBottom: '1px solid black' }}>Number of claims: {rowCount}</p> {/* Calculate and display the number of requests */}            
            <div className={styles.reviewItem}><span className={styles.lightText}>Billing Provider:</span> <strong>{selectedBillingProviders.firstName}{' '}{selectedBillingProviders.lastName}{' | NPI:'}{selectedBillingProviders.npi}</strong></div>
            <div className={styles.reviewItem}><span className={styles.lightText}>Service Provider:</span> <strong>{selectedServiceProviders.firstName}{' '}{selectedServiceProviders.lastName}{' | NPI:'}{selectedServiceProviders.npi}</strong></div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Diagnosis Codes:</span> 
              <strong>
                {
                  selectedBillingCodes.map(code => code.diagnosisCode.join(', ')).join('; ')
                }
              </strong>
            </div>
            <div className={styles.reviewSection}>
                <h4>Service Lines</h4>
                <table className={styles.serviceLinesTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Diagnosis Code</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedBillingCodes.map((code, index) => (
                      <tr key={index}>
                        <td>{code.code}</td>
                        <td>{code.name}</td>
                        <td>{code.diagnosisCode.join(', ')}</td>
                        <td>${code.billAmount.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            <div className="button-container">
            <div className={styles.searchButtonContainer}>
              {isLoading ? (
                <HexSpinner />
              ) : (
              <div>
                {showSuccessMessage && successCount > 0 && 
                    <div>
                        <p>The batch of claims have been successfully submitted. Successful submissions: {successCount}. 
                        </p>
                        <Confetti />

                    </div>
                }
            
                {errorMessage && 
                    <div>
                        <h3>Failed Patients</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedPatients.map(patient => 
                                    <tr key={`${patient.requestData.subscriber.firstName}-${patient.requestData.subscriber.lastName}`}>
                                        <td>{patient.requestData.subscriber.firstName}</td>
                                        <td>{patient.requestData.subscriber.lastName}</td>
                                        <td>{patient.error}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            
                {!isLoading && !showSuccessMessage && 
                    <button type="button" onClick={handleSubmit}>
                        Submit Claims
                    </button>
                }
            </div>
              )}
            </div>
          </div>
          {!isLoading && <button onClick={handleNewBatch}>New Batch or Cancel</button>}

          </div>
        </CollapsibleSection>
        

      </div>
    </div>
);


}
export default BatchClaims;

