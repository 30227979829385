import React, { useEffect, useState, useMemo } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { auth, db } from "../../firebase";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import './Monitoring.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import useUID from '../General/useUID';
import HexSpinner from '../General/Animations/Hexspinner';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getFunctions, httpsCallable } from 'firebase/functions';

const GlucoseChart = () => {
    const [originalData, setOriginalData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [patientInfo, setPatientInfo] = useState({});
    const [patientNotFound, setPatientNotFound] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [noDataInRange, setNoDataInRange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRange, setSelectedRange] = useState('1day');
    const [deviceNotConnected, setDeviceNotConnected] = useState(false);
    const [uid, subUserUID, error, isLoading] = useUID();
    const { patientId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!uid || !patientId || !selectedRange) return;

        setLoading(true);
        const functions = getFunctions();
        const fetchPatientGlucoseData = httpsCallable(functions, 'getGlucoseData');

        fetchPatientGlucoseData({ uid, patientId, range: selectedRange })
            .then((result) => {
                const { deviceNotConnected, glucoseData } = result.data;

                if (deviceNotConnected) {
                    setDeviceNotConnected(true);
                } else if (!glucoseData || glucoseData.length === 0) {
                    setChartData([]);
                    setNoDataInRange(true);
                } else {
                    const formattedData = glucoseData.map(sample => ({
                        time: sample.timestamp,
                        glucoseLevel: sample.blood_glucose_mg_per_dL,
                    })).sort((a, b) => moment(a.time).valueOf() - moment(b.time).valueOf());

                    setChartData(formattedData);
                    setOriginalData(formattedData);
                    setNoDataInRange(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching glucose data:", error);
                setChartData([]);
                setNoDataInRange(true);
            })
            .finally(() => setLoading(false));
    }, [patientId, uid, selectedRange]);

    useEffect(() => {
        if (!uid || !patientId) return;

        setLoading(true);

        const patientDocRef = doc(db, 'patients', uid, 'patientData', patientId);

        getDoc(patientDocRef)
            .then(docSnap => {
                if (docSnap.exists()) {
                    const patientData = docSnap.data().patient;
                    const dob = patientData.dob;
                    const birthDate = new Date(dob);
                    let age = new Date().getFullYear() - birthDate.getFullYear();
                    const m = new Date().getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && new Date().getDate() < birthDate.getDate())) {
                        age--;
                    }

                    setPatientInfo({
                        ...patientData,
                        age,
                    });
                } else {
                    setPatientNotFound(true);
                }
            })
            .catch((error) => {
                console.error("Error getting document:", error);
                setPatientNotFound(true);
            })
            .finally(() => setLoading(false));
    }, [uid, patientId]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === "startTime") {
            setStartTime(value);
        } else if (name === "endTime") {
            setEndTime(value);
        }
    };

    const filterDataBySelectedTimes = () => {
        const startMoment = startTime ? moment(startTime) : null;
        const endMoment = endTime ? moment(endTime) : null;

        const filteredData = originalData.filter((sample) => {
            const sampleMoment = moment(sample.time);
            return (!startMoment || sampleMoment.isSameOrAfter(startMoment)) &&
                   (!endMoment || sampleMoment.isSameOrBefore(endMoment));
        });

        setChartData(filteredData);
        setNoDataInRange(filteredData.length === 0);
    };

    useEffect(() => {
        filterDataBySelectedTimes();
    }, [startTime, endTime, originalData]);

    const chart = useMemo(() => {
        if (chartData.length === 0) {
            return <p>No data available for the selected time range.</p>;
        }

        const formatTimeLabel = (timestamp) => {
            return moment(timestamp).format("MM/DD/YYYY");
        };

        const CustomTooltip = ({ active, payload }) => {
            if (active && payload && payload.length) {
                return (
                    <div className="custom-tooltip">
                        <p className="label">{`Time: ${moment(payload[0].payload.time).format("MM/DD/YYYY HH:mm")}`}</p>
                        <p className="intro">{`Glucose Level (mg/dL): ${payload[0].value}`}</p>
                    </div>
                );
            }

            return null;
        };

        return (
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="time" tickFormatter={formatTimeLabel} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line type="monotone" dataKey="glucoseLevel" name="Glucose Level (mg/dL)" stroke="#ff7300" />
                </LineChart>
            </ResponsiveContainer>
        );
    }, [chartData]);

    const handleViewPatient = (patientId) => {
        navigate(`/patients/${patientId}`);
    };

    return (
        <div className="chart-container" id="chart-container">
            <Link to="/monitoring">
                <button>Back to Dashboard</button>
            </Link>

            {loading ? (
                <>
                    <div className="data-range-selection">
                        <button onClick={() => setSelectedRange('1day')}>1 Day</button>
                        <button onClick={() => setSelectedRange('7days')}>7 Days</button>
                        <button onClick={() => setSelectedRange('30days')}>30 Days</button>
                        <button onClick={() => setSelectedRange('1year')}>1 Year</button>
                        <button onClick={() => setSelectedRange('all')}>All Time/Historical</button>
                    </div>
                    <HexSpinner />
                </>
            ) : patientNotFound ? (
                <p>Patient not found</p>
            ) : deviceNotConnected ? (
                <p>The patient does not have a device connected to their file.</p>
            ) : (
                <>
                    <h2>{`${patientInfo.firstName} ${patientInfo.middleName ? patientInfo.middleName + ' ' : ''}${patientInfo.lastName}`}</h2>
                    <p>Date of Birth: {patientInfo.dob} | {patientInfo.age} years</p>
                    <p>Gender: {patientInfo.gender}</p>
                    <span onClick={() => handleViewPatient(patientId)} style={{ color: '#3abada', cursor: 'pointer' }}>
                        View Patient's File {'>'}
                    </span>
                    <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>

                    <div className="date-filter">
                        <label>Start Time:</label>
                        <input
                            type="datetime-local"
                            name="startTime"
                            value={startTime}
                            onChange={handleDateChange}
                        />
                        <label>End Time:</label>
                        <input
                            type="datetime-local"
                            name="endTime"
                            value={endTime}
                            onChange={handleDateChange}
                        />
                    </div>
                    <div className="data-range-selection">
                        <button onClick={() => setSelectedRange('1day')}>1 Day</button>
                        <button onClick={() => setSelectedRange('7days')}>7 Days</button>
                        <button onClick={() => setSelectedRange('30days')}>30 Days</button>
                        <button onClick={() => setSelectedRange('1year')}>1 Year</button>
                        <button onClick={() => setSelectedRange('all')}>All Time</button>
                    </div>

                    <h2>Glucose line chart</h2>
                    {noDataInRange ? (
                        <p>No data available for the selected time range.</p>
                    ) : (
                        chart
                    )}
                </>
            )}
        </div>
    );
};

export default GlucoseChart;
