import React from "react";
import ChoiceInputSection from "./ChoiceInputSection";
import ValidationSelect from "./ValidationSelect";
import GeneralSwitch from "./GeneralSwitch";

const InputSection = ({ item, fieldDef, fieldIndex, handleChange }) => {
  const title = fieldDef?.properties?.title;
  let itemVar = item.fields?.[fieldIndex];
  return (
    <>
      {itemVar?.isVisible && (
        <>
          {"title" in fieldDef?.properties && (
            <>
              <h4 className="mt-1">{title}:</h4>
              <hr />
            </>
          )}
          {"label" in fieldDef?.properties && (
            <>
              <label className="mt-3">{title} Label:</label>
              <input
                type="text"
                placeholder={title}
                value={itemVar?.label || ""}
                onChange={(e) => handleChange(e, fieldIndex, "label")}
              />
            </>
          )}
          {"placeholder" in fieldDef?.properties && (
            <>
              <label className="mt-3">{title} Placeholder:</label>
              <input
                type="text"
                placeholder="Placeholder Value"
                value={itemVar?.placeholder || ""}
                onChange={(e) => handleChange(e, fieldIndex, "placeholder")}
              />
            </>
          )}

          {"required" in fieldDef?.properties && (
            <GeneralSwitch
              label="Is Required:"
              itemFields={item.fields}
              fieldIndex={fieldIndex}
              handleChange={handleChange}
              property="required"
            />
          )}

          {item?.type == "Spacer" && (
            <>
              <input
                type="range"
                value={itemVar?.default}
                min={itemVar?.min}
                max={itemVar?.max}
                step="1"
                onChange={(e) => handleChange(e, fieldIndex, "default")}
              />
            </>
          )}

          {item.type === "ChoiceInput" && (
            <ChoiceInputSection
              item={item}
              fieldDef={fieldDef}
              fieldIndex={fieldIndex}
              handleChange={handleChange}
            />
          )}

          {"showValidation" in fieldDef?.properties && (
            <ValidationSelect
              selectedValidation={itemVar?.validation || "None"}
              onValidationChange={(e) =>
                handleChange(e, fieldIndex, "validation")
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default InputSection;
