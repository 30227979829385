import { auth, db } from "../../../firebase";
import { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import AuthDetails from "../../Auth/AuthDetails";
import Footer from "../Home/Footer";
import Sidebar from "../Topbar";
import styles from "../MainStyles.css";
import useUID from "../useUID";

function Logs() {
  const [requests, setRequests] = useState([]);
  const [logs, setLogs] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid) return; // do not proceed if uid is not available

    // Fetching API requests
    const requestsRef = collection(db, "users", uid, "API");
    const requestQuery = query(requestsRef, orderBy("timestamp", "desc"));
    const unsubscribeRequests = onSnapshot(requestQuery, (querySnapshot) => {
      let requestsList = [];
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();

        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }

        requestsList.push({
          timestamp: requestData.timestamp,
          apiRequests: requestData.APIRequests
        });
      });

      setRequests(requestsList);
      setTotalRequests(total);
    },[uid]);

    // Fetching logs
    const logsRef = collection(db, "users", uid, "logs");
    const logsQuery = query(logsRef, orderBy("timestamp", "desc"));
    const unsubscribeLogs = onSnapshot(logsQuery, (querySnapshot) => {
      let logsList = [];
      querySnapshot.forEach((doc) => {
        const logData = doc.data();
        logsList.push({
          timestamp: logData.timestamp,
          message: logData.message
        });
      });

      setLogs(logsList);
    });

    return () => {
      unsubscribeRequests();
      unsubscribeLogs();
    };
  }, [uid]);

  return (
    <div className="background">
      <Sidebar />

      <div className="contentContainer">
        <div className="dashboardContainer">
          <div className="apiRequestsSection">
            <h3>API Requests Made</h3>
            <div className="requestsContainer">
              {requests.map((request, index) => (
                <div key={index} className="requestContainer">
                  <p className={styles.timestamp}>
                    Timestamp: {request.timestamp.toDate().toString()}
                  </p>
                  <p className={styles.apiRequests}>
                    API requests: {JSON.stringify(request.apiRequests)}
                  </p>
                </div>
              ))}
            </div>
            <div className="totalRequestsContainer">
              <p>Total Requests: {totalRequests}</p>
            </div>
          </div>

          <div className="logsSection">
            <h3>User Logs</h3>
            <div className="logsContainer">
              {logs.map((log, index) => (
                <div key={index} className="logContainer">
                  <p className={styles.timestamp}>
                    Timestamp: {log.timestamp.toDate().toString()}
                  </p>
                  <p className={styles.message}>
                    Message: {log.message}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logs;