import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where, limit, orderBy, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID';
import { storage, auth, db } from '../../firebase';
import QuicksearchData from '../Eligibility/QuicksearchData'

function InstantEligibility({ patientId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [uid, subUserUID, isLoading] = useUID();
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [patientData, setPatientData] = useState(null);
  // Set DOS automatically to today's date
  const [dos, setDos] = useState(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    return formattedDate;
  });
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [npi, setNPI] = useState('');

  useEffect(() => {
    // Fetch user's data including providerOrganizationName and npi
    const fetchUserData = async () => {
      const uid = auth.currentUser.uid;
      const userDocRef = doc(db, "users", uid);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setProviderOrganizationName(userData.OrganizationName);
          setNPI(userData.NPI);
        } else {
          console.log("No such user document!");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    if (auth.currentUser) {
      fetchUserData();
    }
  }, [auth.currentUser]);

  function formatDate(dateStr) {
    if (dateStr && dateStr.length === 8) {
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);
    
      // Reformat to MM/DD/YYYY
      return `${month}/${day}/${year}`;
    }
    return dateStr; // Return the original string if it's not in YYYYMMDD format
  }
  
  
  

  const handleRunEligibility = async () => {
    console.log('Running eligibility check...');
    setLoading(true);
    setError(null);
  
    try {
      console.log('Fetching patient data...');
      const patientDocRef = doc(db, `patients/${uid}/patientData/${patientId}`);
      const patientDocSnap = await getDoc(patientDocRef);
  
      if (patientDocSnap.exists()) {
        const patientData = patientDocSnap.data();
        setRequestData(patientData);
  
        const { firstName, lastName, dob, gender } = patientData.patient;
        const { name: tradingPartnerName, memberId } = patientData.payers;
        
        const convertedtradingPartnerServiceId = await getConvertedTradingID(tradingPartnerName);
  
        const formattedDob = formatDate(dob);

        const [year, month, day] = dos.split('-');
        const formattedDos = `${month}/${day}/${year}`;

        const payersArray = [{ tradingPartnerId: convertedtradingPartnerServiceId }];
  
        if (!hasRemainingRequests) {
          setError("You have reached the limit of 25 API requests for the free trial.");
          console.log('API request limit reached');
          setLoading(false);
          return;
        }
  
        const functions = getFunctions();
        const EligibilitySingleAPI = httpsCallable(functions, 'EligibilitySingleAPI');
        
        const result = await EligibilitySingleAPI({
        firstName,
        lastName,
        dob: formattedDob,
        dos: formattedDos,
        gender,
        memberId,
        payers: payersArray,
        uid,
        organizationName: providerOrganizationName,
        npi,
        patientId
        });

  
        if (result && result.data && result.data.success) {
          if (result.data.eligibilityData) {
            setPatientData(result.data);
          } else {
            setPatientNotFound(true);
            console.log('Patient not found in eligibility data');
          }
        } else {
          setPatientNotFound(true);
          console.log('No success response from EligibilitySingleAPI');
        }
      } else {
        setError('No patient data available.');
        console.log('No patient data available for:', patientId);
      }
    } catch (error) {
      console.error("Error in handleRunEligibility: ", error);
      setError(error.message || 'An error occurred.');
    } finally {
      setLoading(false);
      console.log('Eligibility check finished');
    }
  };
  

  async function getConvertedTradingID(tradingPartnerName) {
    try {
      // Create a query against the Payers collection using the tradingPartnerName
      const payersRef = collection(db, 'Payers');
      const q = query(payersRef, where('payerName', '==', tradingPartnerName));

      const snapshot = await getDocs(q);
      
      if (!snapshot.empty) {
          const realtimePayerID = snapshot.docs[0].data().RealtimePayerID;
          
          if (realtimePayerID) {
              return realtimePayerID;
          } else {
              throw new Error('This payer does not support single click claim status at this time.');
          }
      } else {
          console.log('No matching payer found for:', tradingPartnerName);
          return null;
      }
  } catch (error) {
      console.error('Error fetching Realtime Payer ID:', error);
      throw error;
  }
}

  
useEffect(() => {
  const fetchAccountTierAndCheckRemainingRequests = async () => {
    const uid = auth.currentUser.uid;
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    const AccountTier = userDocSnap.data().AccountTier || '';

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();

        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }
      });

      if (AccountTier === 'Freebie' && total >= 25) {
        setHasRemainingRequests(false);
      } else {
        setHasRemainingRequests(true);
      }
    });

    return () => unsubscribe();
  };

  if (auth.currentUser) {
    fetchAccountTierAndCheckRemainingRequests();
  }
}, [auth.currentUser]);

  
return (
  <>
  <button 
    onClick={handleRunEligibility}
    className="btn btn-outline-primary mb-2" 
    data-toggle="tooltip" 
    data-placement="top" 
    title="Run Eligibility on this claim"
    disabled={loading}
  >
     <div className="icon-container">
    <FontAwesomeIcon icon={faBoltLightning} size="lg" style={{ height: '30px' }} />
    </div>

    {loading && "Checking..."}
    {
      patientNotFound && <div className='patientNotFoundMessage'>Insurance not found</div>
    }
<div className="text-container">
    Instant Eligibility!
</div>
  </button>
  <div className='patientDataPopupContainer' style={{ display: patientData ? 'block' : 'none' }}>
    {patientData && (
      <div className='patientDataPopupContainer'>
        <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
      </div>
    )}
    </div>
  </>
);
}

export default InstantEligibility;


