import React, { Component, createRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import HexSpinner from '../General/Animations/Hexspinner'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function PaymentFormWrapper({ plan, planDetails, price }) {
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Add useState hook for error
  const [isProcessing, setIsProcessing] = useState(false);


  const handleForm = async (e, stripe, card) => {
    e.preventDefault();
    setIsProcessing(true);
    if (!stripe) return; // handle error
    const { token, error } = await stripe.createToken(card);
    if (error) {
      setError(error.message);
      setIsProcessing(false);
    } else {
      let startSubscription;
      if (plan === 'Professional') {
        startSubscription = httpsCallable(getFunctions(), 'startSubscriptionProfessional');
      } else if (plan === 'Enterprise') {
        startSubscription = httpsCallable(getFunctions(), 'startSubscriptionEnterprise');
      }
  
      const res = await startSubscription({ source: token.id });
      if (res.data.success) {
        navigate('/home');
      } else {
        setError(res.data.message);
        setIsProcessing(false);
      }
    }
  }
  
  

  return (
    <PaymentFormComponent
      handleForm={handleForm}
      plan={plan}
      planDetails={planDetails}
      price={price}
      error={error}
      isProcessing={isProcessing}
    />
  );
  }

class PaymentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.cardForm = createRef();
    this.state = {
      stripe: null,
      card: null
    };
  }

  async componentDidMount() {
    const stripe = await stripePromise;
    const elements = stripe.elements();
    const card = elements.create('card');
    card.mount(this.cardForm.current);
    this.setState({ stripe, card });
  }

  render() {
    const { error } = this.props;
    const { handleForm, plan, planDetails, price } = this.props;
  
    return (
      <div className="popupCard">
        <form onSubmit={(e) => handleForm(e, this.state.stripe, this.state.card)}>
          <h1 className="heading">Enter Your Payment Details</h1>
          <h2 className="subheading">Plan: {plan}</h2>
          <h3 className="price">Price: {price}</h3>
          {planDetails && (
            <ul className="plan-details-list">
              {planDetails.map((detail, index) => (
                <li key={index} className="payment-form__detail-item">
                  {detail}
                </li>
              ))}
            </ul>
          )}
          <div ref={this.cardForm} className="input-field"></div>
          {error && <p className="error-message">{error}</p>}
          {this.props.isProcessing ? (
            <div className="spinner-wrapper">
              <HexSpinner />
            </div>
          ) : (
            <button type="submit" className="signup-btn">
              Submit Payment
            </button>
          )}
  
          <div className="powered-by-stripe">
            <img
              src="https://stripe.com/img/about/logos/logos/blue.png"
              alt="Stripe logo"
              className="stripe-logo"
            />
            <p>Powered by Stripe</p>
          </div>
        </form>
      </div>
    );
  }
}
export default PaymentFormWrapper;