import React, { useState } from 'react';
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';

const medicalConditions = [
  'Diabetes',
  'Hypertension',
  'Heart Disease',
  'Cancer',
  'Stroke',
  'Asthma',
  'Other',
];

const familyMembers = [
  'Father',
  'Mother',
  'Siblings',
  'Children',
  'Grandparents',
  'Uncles/Aunts',
  'Cousins',
  'Other'
];

function FamilyHistory({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(familyMembers[0].toLowerCase());
  const [historyDetails, setHistoryDetails] = useState({});
  const [otherCondition, setOtherCondition] = useState('');
  const [isOtherConditionChecked, setIsOtherConditionChecked] = useState(false);

  const handleConditionChange = (condition, isChecked) => {
    if (condition === 'Other') {
      setIsOtherConditionChecked(isChecked); // Control the rendering of the "Other" input field directly
      if (isChecked) {
        // When checking 'Other', add it without removing other conditions
        setHistoryDetails(prevDetails => ({
          ...prevDetails,
          [selectedFamilyMember]: [...(prevDetails[selectedFamilyMember] || []), 'Other']
        }));
      } else {
        // When unchecking 'Other', remove it and 'Other:' related entries without removing other conditions
        setHistoryDetails(prevDetails => ({
          ...prevDetails,
          [selectedFamilyMember]: (prevDetails[selectedFamilyMember] || []).filter(c => c !== 'Other' && !c.startsWith('Other:'))
        }));
        // Clear the 'Other' input field since we're unchecking it
        setOtherCondition('');
      }
    } else {
      // Handle other conditions as before
      setHistoryDetails(prevDetails => {
        const currentConditions = prevDetails[selectedFamilyMember] || [];
        if (isChecked) {
          // Add the condition to the list
          return { ...prevDetails, [selectedFamilyMember]: [...currentConditions, condition] };
        } else {
          // Remove the condition from the list
          return { ...prevDetails, [selectedFamilyMember]: currentConditions.filter(c => c !== condition) };
        }
      });
    }
  };
  

  const handleOtherConditionChange = (e) => {
    const newValue = e.target.value;
    setOtherCondition(newValue); // Set the value for the 'Other' input field immediately
  
    // Defer updating the `historyDetails` until after the `otherCondition` state has been set
    if (newValue.trim() !== '') {
      setHistoryDetails(prevDetails => {
        const updatedConditions = (prevDetails[selectedFamilyMember] || []).filter(cond => !cond.startsWith('Other'));
        return {
          ...prevDetails,
          [selectedFamilyMember]: [...updatedConditions, `Other: ${newValue}`],
        };
      });
    } else {
      // If the input is empty, we want to remove the 'Other' entry
      setHistoryDetails(prevDetails => {
        const updatedConditions = (prevDetails[selectedFamilyMember] || []).filter(cond => !cond.startsWith('Other'));
        return updatedConditions.length > 0
          ? { ...prevDetails, [selectedFamilyMember]: updatedConditions }
          : Object.fromEntries(Object.entries(prevDetails).filter(([key]) => key !== selectedFamilyMember));
      });
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.keys(historyDetails).length === 0) {
      alert("Please select a family member and fill in their medical conditions.");
      return;
    }

    const medicalHistoryRef = collection(doc(db, 'patients', uid, 'patientData', patientId), 'medicalHistory');

    try {
      await addDoc(medicalHistoryRef, {
        type: 'FamilyHistory', 
        timestamp: serverTimestamp(),
        ...historyDetails
      });
      console.log('Family History added:', historyDetails);

      setHistoryDetails({});
      setOtherCondition('');
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className={styles.familyHistoryForm}>
      <form onSubmit={handleSubmit}>
        <div className='input-group-row'>
          <div className='input-field'>
            <label htmlFor="familyMember">Select Family Member:</label>
            <select
              id="familyMember"
              value={selectedFamilyMember}
              onChange={(e) => setSelectedFamilyMember(e.target.value)}
            >
              {familyMembers.map(member => (
                <option key={member} value={member.toLowerCase()}>
                  {member}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='input-group-row'>
          <div className='input-field'>
            <label>Medical Conditions:</label>
            <div className="checkbox-group">
              {medicalConditions.map(condition => (
                <label key={condition}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    value={condition}
                    checked={
                      historyDetails[selectedFamilyMember]?.includes(condition) ||
                      (condition === 'Other' && historyDetails[selectedFamilyMember]?.some(cond => cond.startsWith('Other:')))
                      || false
                    }
                    onChange={(e) => handleConditionChange(condition, e.target.checked)}
                  />
                  {condition}
                </label>
              ))}
            </div>
          </div>
        </div>

        {isOtherConditionChecked && (
          <div className='input-group-row'>
            <div className='input-field'>
              <label htmlFor="otherCondition">Specify Other Condition:</label>
              <input
                type="text"
                id="otherCondition"
                value={otherCondition}
                onChange={handleOtherConditionChange}
                placeholder="Specify the condition"
              />
            </div>
          </div>
        )}

        <button type="submit" className={styles.submitButton}>
          Submit Family History
        </button>
      </form>
    </div>
  );
}

export default FamilyHistory;
