import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../../firebase";
import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import Topbar from "../Topbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardUpdateForm from "./CardUpdateForm";
import "./settings.modules.css";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { updateDoc, deleteField } from 'firebase/firestore';
import { collection, query, orderBy, where } from "firebase/firestore";
import moment from "moment";
import useUID from "../useUID";

const functions = getFunctions();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function Settings() {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [user, setUser] = useState(null);
  const [showCardUpdateForm, setShowCardUpdateForm] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [totalRequests, setTotalRequests] = useState(0);
  const [last24HoursRequests, setLast24HoursRequests] = useState(0);
  const [billingInfo, setBillingInfo] = useState({
    totalRequests: 0,
    totalSpend: 0,
    last24HoursRequests: 0,
    last24HoursCharge: 0,
  });

  useEffect(() => {
    if (!uid) return; // do not proceed if uid is not available
    const userRef = doc(db, "users", uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUser(doc.data());
      }
    });
    return () => unsubscribe();
  }, [uid]);

  useEffect(() => {
    if (!uid) return; // do not proceed if uid is not available

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let requestsList = [];
      let total = 0;
      let last24HoursTotal = 0;

      querySnapshot.forEach((doc) => {
        const requestData = doc.data();
        const apiRequests = requestData.APIRequests;

        if (apiRequests) {
          const apiRequestsCount = parseInt(apiRequests, 10);
          total += apiRequestsCount;

          const timestamp = requestData.timestamp.toDate();
          const now = new Date();
          const differenceInHours = (now - timestamp) / 1000 / 60 / 60;

          if (differenceInHours <= 24) {
            last24HoursTotal += apiRequestsCount;
          }
        }
      });

      setTotalRequests(total);
      setLast24HoursRequests(last24HoursTotal);
    });

    return () => unsubscribe();
  }, [uid]);


  const handlePasswordReset = () => {
    sendPasswordResetEmail(getAuth(), user.Email)
      .then(() => {
        alert("Email sent to reset password.");
      })
      .catch((error) => {
        console.error("Error sending password reset email:", error);
        alert("Error sending email to reset password.");
      });
  };

  const updateCard = async (paymentMethodId) => {
    try {
      const functions = getFunctions();
      const updateCardFunction = httpsCallable(functions, "updateCard");
      await updateCardFunction({ paymentMethodId: paymentMethodId });
    } catch (error) {
      console.error("Error updating card on server:", error);
      throw error; // Propagate the error to the caller
    }
  };

  const handleSubscriptionCancellation = async () => {
    try {

      // Delete the subscriptionId from the user document
      const userRef = doc(db, "users", uid);
      await updateDoc(userRef, { subscriptionId: deleteField() });

      // Add logs
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({ uid: uid, message: 'Account Deleted' });

      // Delete Firebase auth account
      await auth.currentUser.delete()
        .then(() => {
          console.log("Firebase Auth User deleted");
        })
        .catch((error) => {
          console.error("Error deleting Firebase Auth User:", error);
        });

      setShowCancelConfirmation(false);
      alert("Your subscription has been canceled. Your account has been deleted.");

    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("Error canceling subscription.");
    }
  };



  const renderCancelConfirmation = () => {
    return (
      <div className="popup">
        <div className="popup-content">
          <h3>Are you sure you want to cancel your subscription?</h3>
          <div className="flex-container">
            <button className="danger" onClick={handleSubscriptionCancellation}>Yes, cancel subscription.</button>
            <button className="primary"  onClick={() => setShowCancelConfirmation(false)}>No, Keep my account.</button>
          </div>
        </div>
      </div>
    );
  };


  if (!user) {
    return <p></p>;
  }


  const handleDowngradeToFreebie = async () => {
    try {
  
      // Downgrade to freebie tier and cancel Stripe subscription
      const downgradeFunction = httpsCallable(functions, "DowngradetoFreebie");
      const response = await downgradeFunction({ uid: uid });
  
      if (!response.data.success) {
        throw new Error(response.data.error);
      }
  
      // Update local state to reflect the change (assuming you're storing the user's tier in state)
      setUser({ ...user, AccountTier: 'freebie' });
  
      alert(response.data.message);
      
    } catch (error) {
      console.error("Error downgrading to freebie tier:", error);
      alert("Error downgrading to freebie tier.");
    }
  };
  
  
  return (
    <div className='background'>
        <Topbar />
        <div className="contentContainer">
            <div className="content">
                <div className="leftColumn">
                    <div className="bigName">
                        {user.Name}
                    </div>
                    {/* Practice Information */}
                    <section className="section">
                        <h2>Practice Information</h2>
                        <div>
                            <span>Organization Name: </span>
                            <span>{user.OrganizationName}</span>
                        </div>
                        <div>
                            <span>EIN: </span>
                            <span>{user.EIN}</span>
                        </div>
                        <div>
                            <span>NPI: </span>
                            <span>{user.NPI}</span>
                        </div>
                    </section>
                    {/* Provider Information */}
                    <section className="section">
                        <h2>Provider Information</h2>
                        <div>
                            <span>First Name: </span>
                            <span>{user.FirstName}</span>
                        </div>
                        <div>
                            <span>Last Name: </span>
                            <span>{user.LastName}</span>
                        </div>
                    </section>
                    {/* Profile */}
                    <section className="section">
                        <h2>Profile</h2>
                        <div>
                            <span>Name: </span>
                            <span>{user.Name}</span>
                        </div>
                        <div>
                            <span>Email: </span>
                            <span>{user.Email}</span>
                        </div>
                        <div>
                            <button className="primary" onClick={handlePasswordReset}>Reset Password</button>
                        </div>
                    </section>
                </div>
                <div className="rightColumn">


                    {/* Billing */}
                    <section className="section">
                        <h2>Billing</h2>
                        <div>
                            <span>Account Tier: </span>
                            <span>{user.AccountTier}</span>
                        </div>
                        <div>
                            <span>Total API requests: </span>
                            <span>{totalRequests}</span>
                        </div>
                        <div>
                            <span>Total spend: </span>
                            <span>${totalRequests ? (totalRequests * 0.35).toFixed(2) : "0.00"}</span>
                        </div>
                        <div>
                            <span>API requests in the last 24 hours: </span>
                            <span>{last24HoursRequests}</span>
                        </div>
                        <div>
                            <span>Charges for the last 24 hours: </span>
                            <span>${last24HoursRequests ? (last24HoursRequests * 0.35).toFixed(2) : "0.00"}</span>
                        </div>
                    </section>
                    {/* Subscription */}
                    <section className="section">
                        <h2>Subscription</h2>
                        <div>
                            <button className="primary" onClick={() => setShowCardUpdateForm(!showCardUpdateForm)}>
                                Change Payment Method
                            </button>
                            {showCardUpdateForm && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <h3>Update Card Details</h3>
                                        <Elements stripe={stripePromise}>
                                            <CardUpdateForm handleSubmit={updateCard} />
                                        </Elements>
                                        <button className="primary" onClick={() => setShowCardUpdateForm(false)}>Close</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <button className="delete" onClick={() => setShowCancelConfirmation(true)}>Cancel Subscription</button> */}
                        {showCancelConfirmation && renderCancelConfirmation()}
                        <button onClick={handleDowngradeToFreebie}>Downgrade to Freebie</button>
                        <button onClick={() => setShowCancelConfirmation(true)}>Delete Account</button>
                        {showCancelConfirmation && renderCancelConfirmation()}
                    </section>
                    {
                        user.AccountTier === 'Enterprise' &&
                        <button onClick={() => window.location.href='/api'}>API</button>
                      }
                    <button onClick={() => window.location.href='/users'}>Users</button>

                </div>

            </div>
        </div>
    </div>
);

}

export default Settings;
