import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Make sure this path correctly points to your Firebase configuration
import { getFunctions, httpsCallable } from 'firebase/functions';
import { addDoc, collection } from "firebase/firestore";

const AddBPDeviceComponent = ({ uid, patientId }) => {
  const [showInput, setShowInput] = useState(false);
  const [gatewayId, setGatewayId] = useState('');
  const [existingGatewayId, setExistingGatewayId] = useState('');

  useEffect(() => {
    const fetchPatientData = async () => {
      const patientRef = doc(db, "patients", uid, "patientData", patientId);
      const docSnap = await getDoc(patientRef);

      if (docSnap.exists() && docSnap.data().gatewayId) {
        // If a gatewayId exists for this patient, store it to state
        setExistingGatewayId(docSnap.data().gatewayId);
      } else {
        // Reset existingGatewayId if the current patient does not have one
        setExistingGatewayId('');
      }
    };

    fetchPatientData();
  }, [uid, patientId]); // Dependency array to re-run when uid or patientId changes

  const handleAddDeviceClick = () => {
    setShowInput(true);
  };

  const handleSave = async () => {
    if (!gatewayId) {
      alert('Please enter a Gateway ID');
      return;
    }
  
    try {
      const functions = getFunctions();
      const tenoviDeviceActivation = httpsCallable(functions, 'TenoviDeviceActivation');
      
      // Call the cloud function with uid, patientId, and gatewayId
      const result = await tenoviDeviceActivation({ uid, patientId, gatewayId });
  
      if (result.data.success) {
    
        alert('Blood Pressure Device added and activated successfully!');
        setExistingGatewayId(gatewayId); // Optionally update UI based on saved data
        setShowInput(false);
        setGatewayId('');
      } else {
        alert('Device activation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error activating device:', error);
      alert('Failed to activate device. Please try again.');
    }
  };
  

  return (
    <div>
      {existingGatewayId ? (
        <p>Existing Gateway ID: {existingGatewayId}</p>
      ) : (
        <>
          {!showInput && (
            <button onClick={handleAddDeviceClick}>Add Blood Pressure Device</button>
          )}
          {showInput && (
            <div>
              <input
                type="text"
                value={gatewayId}
                onChange={(e) => setGatewayId(e.target.value)}
                placeholder="Enter Gateway ID"
              />
              <button onClick={handleSave}>Save</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddBPDeviceComponent;
