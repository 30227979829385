import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../../firebase';
import { doc, getDoc } from "firebase/firestore";
import './Claims.css';
import HexSpinner from '../General/Animations/Hexspinner';
import SingleClaim from "./SingleClaim";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faNotesMedical, faFileInvoiceDollar, faArrowUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import ClaimsViewClaimStatus from './ClaimsViewClaimStatus'
import ClaimsViewEligibility from './../PatientFiles/Billing/ClaimsViewEligibility'
import ClaimsStatusCards from './ClaimStatusView'
import useUID from '../General/useUID';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ClaimsReportsView from './ClaimsReportsView';
import downloadClaimsData from './DownloadClaimsData';

const ClaimsPopup = ({ claimIds, onClose }) => {
  const [showSingleClaimPopup, setShowSingleClaimPopup] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [largeFileSizeMessage, setLargeFileSizeMessage] = useState(null);
  const [patientsData, setPatientsData] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedClaimIndex, setSelectedClaimIndex] = useState(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [updatePatients, setUpdatePatients] = useState(false);
  const [uid, subUserUID] = useUID();
  const [showRunBatchModal, setShowRunBatchModal] = useState(false);
  const [batchClaimsStatusCost, setBatchClaimsStatusCost] = useState(0);
  const [resubmitClaimControlNumber, setResubmitClaimControlNumber] = useState(null);

  const scrollingTableRef = useRef(null);
  const navigate = useNavigate();

  // Function to handle the selection of a claim
  const handleClaimSelection = (index) => {
    const selected = patientsData[index];
    setSelectedClaim(selected);
    setSelectedClaimIndex(index); // Update the selected claim index
  };

  useEffect(() => {
    if (!uid) return;

    const fetchClaimsData = async () => {
      const updatedClaims = await Promise.all(claimIds.map(async claimId => {
        const claimDocumentPath = `patients/${uid}/claims/${claimId}`;

        try {
          const claimRef = doc(db, claimDocumentPath);
          const claimSnapshot = await getDoc(claimRef);

          if (!claimSnapshot.exists()) {
            console.log(`No data found for claimId: ${claimId}`);
            return { claimId };
          }

          const claimData = claimSnapshot.data();
          const patientId = claimData.patientId;
          const patientClaimId = claimData.claimId;
          const requestDataPath = `patients/${uid}/patientData/${patientId}/claims/${patientClaimId}`;
          const requestDataRef = doc(db, requestDataPath);
          const requestDataSnapshot = await getDoc(requestDataRef);

          if (requestDataSnapshot.exists()) {
            const requestData = requestDataSnapshot.data();
            // Merge requestData into claimData
            return { claimId, ...claimData, ...requestData };
          } else {
            return { claimId, ...claimData };
          }
        } catch (error) {
          return { claimId };
        }
      }));

      setPatientsData(updatedClaims);

      if (updatedClaims.length > 0) {
        setSelectedClaim(updatedClaims[0]);
        setSelectedClaimIndex(0);
      } else {
        // Handle the scenario when there are no claims
        setSelectedClaim(null);
        setSelectedClaimIndex(null);
      }
    };

    if (claimIds && claimIds.length > 0) {
      fetchClaimsData();
    } else {
      // Handle the scenario when claimIds is empty or not provided
      setPatientsData([]);
      setSelectedClaim(null);
      setSelectedClaimIndex(null);
    }
  }, [uid]);

  useEffect(() => {
    if (!updatePatients) return;
    const updatePatientsWithStatusesAndReports = async () => {
      const updatedPatients = await Promise.all(patientsData.map(async patient => {
        const claimStatuses = await fetchClaimStatuses(patient);
        const reports = await fetchReportsForClaim(patient);
        return { ...patient, claimStatuses, reports };
      }));

      setPatientsData(updatedPatients);
      setUpdatePatients(false);

      // Automatically select the first claim if there are multiple claims
      if (updatedPatients.length > 1) {
        setSelectedClaim(updatedPatients[0]);
        setSelectedClaimIndex(0); // Set the index to 0 for the first claim
      } else if (updatedPatients.length === 1) {
        // If there's only one claim, select it
        setSelectedClaim(updatedPatients[0]);
        setSelectedClaimIndex(null); // No need to highlight as there's only one claim
      } else {
        // If there are no claims
        setSelectedClaim(null);
        setSelectedClaimIndex(null);
      }
    };
    if (patientsData.length > 0) {
      updatePatientsWithStatusesAndReports();
    }
  }, [updatePatients, patientsData]);

  useEffect(() => {
    if (!scrollingTableRef.current) {
      return;
    }
    const checkScrollTop = () => {
      const tableScrollPosition = scrollingTableRef.current.scrollTop;
      if (!showScrollToTop && tableScrollPosition > 400) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && tableScrollPosition <= 400) {
        setShowScrollToTop(false);
      }
    };
    scrollingTableRef.current.addEventListener('scroll', checkScrollTop);
    return () => {
      if (scrollingTableRef.current) {
        scrollingTableRef.current.removeEventListener('scroll', checkScrollTop);
      }
    };
  }, [showScrollToTop]);


  // Calculate total charges
  const totalCharges = patientsData.reduce((sum, patient) => {
    const chargeAmount = patient.requestData?.claimInformation?.claimChargeAmount || 0;
    return sum + parseFloat(chargeAmount);
  }, 0);

  // Number of claims
  const numberOfClaims = patientsData.length;

  // Unique payers
  const payers = new Set(
    patientsData.map(patient => patient.requestData?.receiver?.organizationName).filter(name => name)
  );

  // Unique organization names
  const organizationName = new Set(
    patientsData.map(patient => patient.requestData?.claimInformation?.serviceFacilityLocation?.organizationName).filter(name => name)
  );


  // Example of getting receiverName from the first patient (if exists)
  const receiverName = patientsData.length > 0 ? patientsData[0].requestData?.receiver?.organizationName || 'Default Receiver Name' : null;

  // Function to format the paid date
  const formatPaidDate = (dateStr) => {
    if (!dateStr || dateStr.length !== 8) return "-";

    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);

    return `${month}/${day}/${year}`;
  };

  // Function to convert DOB format
  function convertDobFormat(dob) {
    if (!dob || dob.length !== 8) return '';
    const year = dob.slice(0, 4);
    const month = dob.slice(4, 6);
    const day = dob.slice(6, 8);
    return `${year}-${month}-${day}`;
  }

  // Modify handleResubmitClick function
  const handleResubmitClick = async (e, patient) => {
    setResubmitClaimControlNumber(patient.controlNumber);
    setShowSingleClaimPopup(true);
    setSelectedPatient(patient.requestData);
  };

  // Function to send data to an external API
  const handleSendToAPI = async () => {
    setShowRunBatchModal(false);

    if (numberOfClaims > 400) {
      const estimatedTime = numberOfClaims * 0.3;
      const message = `Due to the large file size, we recommend you check back later. It is estimated to take ${estimatedTime} seconds.`;
      setLargeFileSizeMessage(message);
    }

    setLoading(true);
    try {
      const functions = getFunctions();
      const sendPatientData = httpsCallableFromURL(functions, process.env.REACT_APP_CLAIMSBATCHCLAIMSSTATUS_URL);
      const response = await sendPatientData({ uid: uid, patientsData });
      console.log('response', response.data)
      if (response && response.data.message) {
        // Assuming response.data.allClaims contains updates or new data for patients
        const apiData = response.data.allClaims;

      } else {
        // Handle the scenario where no message is returned
      }
    } catch (error) {
      console.error("Error sending data to the API:", error);
      setApiError("Error sending data to the API. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Check if any claim has status IDs and calculate total number of statuses and total paid
  const anyClaimHasStatusIds = patientsData.some(patient =>
    patient.claimStatusIds && patient.claimStatusIds.length > 0
  );

  let numberOfStatuses = 0;
  let totalPaid = 0;

  if (anyClaimHasStatusIds) {
    patientsData.forEach(patient => {
      numberOfStatuses += patient.claimStatuses?.length || 0;
      totalPaid += patient.claimStatuses?.reduce((sum, status) => sum + (parseFloat(status.mainStatus?.amountPaid) || 0), 0) || 0;
    });
  }

  const fetchClaimStatuses = async (patient) => {
    let statuses = [];


    // Check if claimStatusIds exists and is an array
    if (patient?.claimStatusIds && Array.isArray(patient.claimStatusIds)) {
      for (const statusId of patient.claimStatusIds) {
        const statusRef = doc(db, `patients/${auth.currentUser.uid}/patientData/${patient.patientId}/claimStatus/${statusId}`);
        const statusSnapshot = await getDoc(statusRef);
        if (statusSnapshot.exists()) {
          statuses.push(statusSnapshot.data());
        }
      }
    }

    return statuses;
  };

  const fetchReportsForClaim = async (patient) => {
    let latestReport = null;
    if (patient?.reportIds && Array.isArray(patient.reportIds)) {
      for (const reportId of patient.reportIds) {
        const reportRef = doc(db, `patients/${auth.currentUser.uid}/patientData/${patient.patientId}/claims/${patient.claimId}/reports/${reportId}`);
        const reportSnapshot = await getDoc(reportRef);
        if (reportSnapshot.exists()) {
          const reportData = reportSnapshot.data();
          if (!latestReport || reportData.timestamp.toDate() > latestReport.timestamp.toDate()) {
            latestReport = reportData;
          }
        }
      }
    }

    return latestReport;
  };

  // Helper function to interpret claim status with expanded code coverage
  const interpretClaimStatus = (report) => {
    if (!report) return ''; // Return empty string if no report is available

    const adjustmentCodes = report.adjustments?.map(adj => adj.adjustmentReasonCode) || [];

    // Define sets of codes for each category
    const deniedCodes = ['2', '3', '5']; // Example denied status codes
    const rejectedCodes = ['6', '7', '8']; // Example rejected status codes
    const paidCodes = ['1']; // Example paid status codes
    const partialPaymentCodes = ['9', '10']; // Example partial payment codes
    const acknowledgedCodes = ['11', '12', '13']; // Example acknowledged status codes
    const informationalCodes = ['14', '15', '16']; // Long, descriptive reason codes

    // Check for specific codes in adjustments and claim status
    if (deniedCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => deniedCodes.includes(code))) {
      return 'Denied';
    }
    if (rejectedCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => rejectedCodes.includes(code))) {
      return 'Rejected';
    }
    if (paidCodes.includes(report.claimStatusCode)) {
      return adjustmentCodes.length === 0 ? 'Paid' : 'Partial Payment';
    }
    if (partialPaymentCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => partialPaymentCodes.includes(code))) {
      return 'Partial Payment';
    }
    if (informationalCodes.includes(report.claimStatusCode)) {
      return 'Informational';
    }
    if (acknowledgedCodes.includes(report.claimStatusCode)) {
      return 'Acknowledged';
    }

    // Default to 'Unknown' for any codes not covered
    return 'Unknown';
  };

  // const handleRunEligibility = (event, claimId) => {
  //   event.stopPropagation(); // Prevent click event from bubbling up to parent
  //   // Logic to run eligibility check for a specific claim
  //   console.log('Running eligibility check for claim:', claimId);
  // };

  // Function to display report details
  const displayReportDetails = (report) => {
    if (!report) return <p></p>;

    return (
      <div>
        <p>Status: {interpretClaimStatus(report)}</p>
        <p>Total Charge Amount: ${report.lineItemChargeAmount}</p>
        <p>Total Provider Payment Amount: ${report.lineItemProviderPaymentAmount}</p>
        {report.adjustments && report.adjustments.map((adj, index) => (
          <div key={index}>
            <p>Adjustment Reason Code: {adj.adjustmentReasonCode}</p>
            <p>Adjustment Amount: ${adj.adjustmentAmount}</p>
          </div>
        ))}
      </div>
    );
  };

  const scrollToTop = () => {
    if (scrollingTableRef.current) {
      scrollingTableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const fetchUserAccountTier = async (uid) => {
    const docRef = doc(db, `users/${uid}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().AccountTier;
    } else {
      return 'Freebie';
    }
  };

  // Function to fetch the account tier and calculate the cost
  const fetchTierAndCalculateCost = async () => {
    console.log(uid)
    const accountTier = await fetchUserAccountTier(uid);
    let tierCharge;
    switch (accountTier) {
      case 'Professional':
        tierCharge = 0.35;
        break;
      case 'Enterprise':
        tierCharge = 0.25;
        break;
      case 'Freebie':
      default:
        tierCharge = 0;
        break;
    }

    const cost = numberOfClaims * tierCharge;
    setBatchClaimsStatusCost(cost);
    setShowRunBatchModal(true);
  };

  const showBatchMenu = selectedClaim?.batchId;

  // Modified onClose function
  const modifiedOnClose = () => {
    // Reset the URL
    navigate("/claims");

    // If there is any existing onClose logic, include it here
    if (typeof onClose === "function") {
      onClose();
    }
  };

  // Function to handle navigation to patient view
  const handleViewPatient = (patientId) => {
    navigate(`/patients/${patientId}`);
  };

  const handleDownload = () => {
    // Assuming `patientsData` is the state or prop holding your patient data
    console.log(patientsData)
    downloadClaimsData(patientsData);
  };


  return (
    <div className='batch-popup-container'>
      <div className='batch-popup'>

        {showSingleClaimPopup ? (
          <>
            <button onClick={() => setShowSingleClaimPopup(false)} className="filesCloseButton">X</button>
            <div className='popupContent'>
              <div className='singleClaimPopup'>

                <SingleClaim
                  initialFirstName={selectedPatient?.subscriber?.firstName}
                  initialMiddleName={selectedPatient?.subscriber?.middleName}
                  initialLastName={selectedPatient?.subscriber?.lastName}
                  initialDob={convertDobFormat(selectedPatient?.subscriber?.dateOfBirth)}
                  initialDos={convertDobFormat(selectedPatient?.claimInformation?.serviceLines[0].serviceDate)}
                  initialGender={selectedPatient?.subscriber?.gender}
                  initialmemberId={selectedPatient?.subscriber?.memberId}
                  initialaddress1={selectedPatient?.subscriber?.address.address1}
                  initialaddress2={selectedPatient?.subscriber?.address.address2}
                  initialcity={selectedPatient?.subscriber?.address.city}
                  initialstate={selectedPatient?.subscriber?.address.state}
                  initialzip={selectedPatient?.subscriber?.address.postalCode}
                  initialTradingPartnerName={selectedPatient?.receiver?.organizationName}
                  patientId={selectedClaim.patientId}
                  resubmitClaimControlNumber={resubmitClaimControlNumber}
                />
              </div>
            </div>
          </>
        ) : (
          <>

            {showBatchMenu && (
              <div className='batchListContainer' ref={scrollingTableRef}>
                <div className='batchListContent'>
                  <div className='run-claim-status-popup-container'>
                    <div className='run-claim-status-popup'>
                      {loading ? (
                        <HexSpinner />
                      ) : (
                        <div className='flex-container'>
                          <button onClick={fetchTierAndCalculateCost} className="primary">
                            <FontAwesomeIcon className='mr-3' icon={faPlay} /> Run Claim Status on Batch
                          </button>
                        </div>
                      )}
                       <button onClick={handleDownload} className="btn btn-primary">
                          <FontAwesomeIcon icon={faDownload} /> Download Batch Data
                        </button>
                      {largeFileSizeMessage && <p style={{ color: 'red' }}>{largeFileSizeMessage}</p>}
                      {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
                    </div>
                  </div>
                  <Modal isOpen={showRunBatchModal} onRequestClose={() => setShowRunBatchModal(false)} className="confirmModal">
                    <h3>Confirming that you want claim status for {numberOfClaims} claims?</h3>
                    <p>It will cost ${batchClaimsStatusCost.toFixed(2)}</p>
                    <div className='confirmButtons'>
                      <button className="secondaryButton" onClick={() => setShowRunBatchModal(false)}>
                        Cancel
                      </button>
                      <button className="primaryButton" onClick={handleSendToAPI}>
                        Run
                      </button>
                    </div>
                  </Modal>
                  <div className='batch-summary-container'>
                    <div>
                      <h4 className='batch-popup-title'>Sent</h4>
                      <p className='batch-popup-text'>${totalCharges.toFixed(2)}</p>
                    </div>
                    <div>
                      <h4 className='batch-popup-title'>Claims</h4>
                      <p className='batch-popup-text'>{numberOfClaims}</p>
                    </div>
                  </div>
                  {loading ? (
                    Array(8).fill().map((_, idx) => (
                      <div key={idx} className="batch-placeholder"></div>
                    ))
                  ) : (
                    <>
                      {numberOfClaims > 1 && patientsData.map((patient, index) => {
                        const { subscriber, claimInformation, tradingPartnerName } = patient.requestData;

                        const latestReport = patient.reports;
                        const claimStatus = interpretClaimStatus(latestReport);

                        return (
                          <div
                            onClick={() => handleClaimSelection(index)}
                            className={`batch-wrapper ${selectedClaimIndex === index ? 'batch-selected' : ''}`} // Use selectedClaimIndex for styling
                          >
                            <div className="batch-header">
                              <FontAwesomeIcon icon={faNotesMedical} className='medical-icon' />
                              <h3>{subscriber.firstName} {subscriber.lastName}</h3>
                            </div>
                            <div className="batch-header-trading">
                              <h3>{tradingPartnerName}</h3>
                            </div>
                            <p className='batch-meta'>
                              ${parseFloat(claimInformation.claimChargeAmount || 0).toFixed(2)}
                              <br />
                              {formatPaidDate(claimInformation.serviceLines[0]?.serviceDate)}
                              <br />
                              {claimStatus}
                            </p>
                            {/* Additional details can be added here if needed */}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className='scrollButtonContainer'>
                  <button className={`scrollToTopButtonClaims ${showScrollToTop ? 'visible' : ''}`} onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                </div>
              </div>
            )}

            <div className={`popupContent ${showBatchMenu ? 'popupContentWithList' : ''}`}>
              <button onClick={modifiedOnClose} className="filesCloseButtonClaimsSingleView">X</button>
              <div className='batch-popup-content'>
                {selectedClaim && (
                  <div>
                    <div className='claimsTopView'>
                      <div className="claimsButtonsleft">
                        <div className='claimsButtonsRight'>
                          <ClaimsViewEligibility
                            claimId={selectedClaim.claimId}
                            patientId={selectedClaim.patientId}
                          />
                          <ClaimsViewClaimStatus
                            claimId={selectedClaim.claimId}
                            patientId={selectedClaim.patientId}
                          />
                          <button
                            onClick={() => handleResubmitClick(null, selectedClaim)}
                            className="btn btn-outline-secondary"
                            disabled={loading}
                            title="Resubmit this claim"
                          >
                            {loading ? 'Resubmitting...' : <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ marginRight: '5px' }} />}
                            Resubmit Claim
                          </button>
                        </div>

                        <h3>{selectedClaim.requestData.subscriber.firstName} {selectedClaim.requestData.subscriber.lastName}</h3>
                        <h4>{selectedClaim.requestData.subscriber.gender} | {formatPaidDate(selectedClaim.requestData.subscriber.dateOfBirth)}</h4>
                        <span
                          onClick={() => handleViewPatient(selectedClaim.patientId)}
                          style={{ color: '#3abada', cursor: 'pointer' }}
                        >
                          View Patient's File {'>'}
                        </span>
                        <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
                        <h4>{selectedClaim.requestData.tradingPartnerName} | ${selectedClaim.requestData.claimInformation.claimChargeAmount}</h4>
                      </div>
                    </div>

                    {displayReportDetails(selectedClaim.reports)}

                    <table>
                      <thead>
                        <tr>
                          <th>Service Date</th>
                          <th>Procedure Code</th>
                          <th>Charge Amount</th>
                          <th>Diagnosis Codes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedClaim.requestData.claimInformation.serviceLines.map((line, index) => (
                          <tr key={index}>
                            <td>{formatPaidDate(line.serviceDate)}</td>
                            <td>{line.professionalService.procedureCode}</td>
                            <td>${line.professionalService.lineItemChargeAmount}</td>
                            <td>
                              {line.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers.map(pointer =>
                                selectedClaim.requestData.claimInformation.healthCareCodeInformation[pointer - 1]?.diagnosisCode
                              ).join(', ')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Additional claim details */}
                    <p>Control Number: {selectedClaim.requestData.controlNumber}</p>
                    <p>Provider: {selectedClaim.requestData.claimInformation.serviceFacilityLocation.organizationName}</p>
                    <p>Service Provider: {selectedClaim.requestData.claimInformation.serviceFacilityLocation.organizationName}{' | '}{selectedClaim.requestData.claimInformation.serviceFacilityLocation.address.address1}{', '}{selectedClaim.requestData.claimInformation.serviceFacilityLocation.address.city}{', '}{selectedClaim.requestData.claimInformation.serviceFacilityLocation.address.state}{' '}{selectedClaim.requestData.claimInformation.serviceFacilityLocation.address.zip}</p>
                    <p>Diagnosis Codes: {selectedClaim.requestData.claimInformation.healthCareCodeInformation.map(diagnosis => diagnosis.diagnosisCode).join(', ')}</p>
                    {/* Display other relevant details from requestData */}
                  </div>
                )}
                {selectedClaim &&
                  <div className='StatusandEligibility'>

                    <div>
                      <h3>Reports (ERAs)</h3>
                      <ClaimsReportsView claimId={selectedClaim.claimId} patientId={selectedClaim.patientId} />
                    </div>

                    <div>
                      <h3>Claim Status</h3>
                      <ClaimsStatusCards claimId={selectedClaim.claimId} patientId={selectedClaim.patientId} />
                    </div>
                    
                  </div>
                }
              </div>

              {/* API error message */}
              {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClaimsPopup;