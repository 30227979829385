import React, { useState, useEffect } from 'react';
import { auth, db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import PayerSelection from '../Payers/PayerSelection';
import useUID from '../General/useUID'
import { useNavigate } from 'react-router-dom';

function EditPatientPage({ handleSave, patientId, firstName, middleName = "", lastName, dob, gender, memberid, address1, address2, city, state, zip, tradingPartnerName, email, phone, handleShowEditPage, handleDelete, onClose }) {

  // Utility function to convert DOB format from MM/DD/YYYY to YYYY-MM-DD
  const convertDateFormat = (inputDate) => {
    const [month, day, year] = inputDate.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  const navigate = useNavigate();

  // Convert DOB to the correct format for the date input
  const formattedDOB = dob ? convertDateFormat(dob) : '';

  const initialPatientData = {
    patient: {
      firstName,
      middleName, // Initialize with the provided value or empty string
      lastName,
      dob: formattedDOB,
      gender,
      email: email || "", // Initialize optional fields as empty strings if undefined
      phone: phone || "",
      address: {
        address1,
        address2: address2 || '',
        city,
        state,
        zip
      }
    },
    payers: {
      memberId: memberid,
      name: tradingPartnerName
    }
  };

  const [patientData, setPatientData] = useState(initialPatientData);
  const [errorMsg, setErrorMsg] = useState(null);
  const [payerData, setPayerData] = useState({
    memberId: memberid || "", // Default to empty string if memberid is undefined
    name: tradingPartnerName || "", // Similarly for tradingPartnerName
  });
  const [selectedPayer, setSelectedPayer] = useState(null); // Renamed for clarity

  const formatDateToDb = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
  };


  const getValidationErrors = () => {
    if (!patientData?.patient) return "Patient data is missing or incomplete.";

    const { firstName, lastName, gender, dob, address } = patientData.patient;

    if (!firstName || !lastName || !gender || !dob) return "Required fields (First Name, Last Name, Gender, Date of Birth) are missing.";

    if (address?.state && address.state.length !== 2) return "State must be a 2-letter abbreviation.";

    const zip = address?.zip ? String(address.zip) : "";
    // Adjust ZIP code validation based on the environment
    if (process.env.NODE_ENV === "development") {
      if (zip && zip.trim().length !== 9) return "ZIP code must be 9 digits in development mode.";
    } else {
      if (zip && zip.trim().length !== 5) return "ZIP code must be 5 digits in production mode.";
    }

    return null; // No errors found
  };

  const handlePayerSelect = (payer) => {
    console.log("Selected payer:", payer);
    if (!payer) {
      console.error('No payer selected');
      setSelectedPayer(null); // Reset the selected payer if none is selected
      return;
    }
    setSelectedPayer(payer); // Update the selected payer
  };

  const handleInputChange = (event, field, subfield = null, isPayerData = false) => {
    const value = event.target.value || "";
    if (isPayerData) {
      setPayerData(prevData => ({ ...prevData, [field]: value }));
    } else {
      setPatientData(prevData => {
        const updatedData = { ...prevData };
        if (subfield) {
          if (!updatedData.patient[field]) {
            updatedData.patient[field] = {};
          }
          updatedData.patient[field][subfield] = value;
        } else {
          updatedData.patient[field] = value;
        }
        return updatedData;
      });
    }
  };

  const saveEditedPatientData = async () => {
    try {
        const validationErrors = getValidationErrors();
        if (validationErrors) {
            console.error("Validation Error:", validationErrors);
            setErrorMsg(validationErrors);
            return;
        }

        const uid = auth.currentUser.uid;
        const patientDataRef = doc(db, "patients", uid, "patientData", patientId);

        

        if (patientData.patient && patientData.patient.dob) {
            patientData.patient.dob = formatDateToDb(patientData.patient.dob);
        }

        const currentPatientData = await getDoc(patientDataRef);
        const currentData = currentPatientData.data();

        // Preserve the clinicToken if it exists
        const clinicToken = currentData.patient?.clinicToken;

        const updatedPatientData = {
            ...currentData,
            patient: {
                ...currentData.patient,
                ...patientData.patient,
                ...(clinicToken && { clinicToken }) // Add clinicToken back if it exists
            },
            payers: { ...payerData }
        };

        // Integrate selectedPayer data into the patient data to be saved
        if (selectedPayer) {
          updatedPatientData.payers = { ...payerData, ...selectedPayer };
        }
        await updateDoc(patientDataRef, updatedPatientData);

        handleSave(updatedPatientData); // Pass updatedPatientData to handleSave
        navigate(`/patients/${patientId}`);
        onClose();
        // Navigate to the desired URL after successful save
    } catch (error) {
        console.error("Error updating patient data:", error);
    }
};



  return (
    <div className="popupContainer">
      <div className="popupContent">

        <div className="cancelButton">
          <button onClick={onClose} className="filesCloseButton">X</button>
        </div>

        <div className="topBar content-center">
          {/* <span className="editText" onClick={handleShowEditPage}>Cancel</span> */}
          <span className="deleteText" onClick={handleDelete}>Delete</span>
        </div>

        <form
          className="editPatient"
          onSubmit={(event) => {
            event.preventDefault();
            saveEditedPatientData();
          }}
        >
          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                value={patientData.patient.firstName || ""}
                onChange={(event) => handleInputChange(event, 'firstName')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="middleName">Middle Name</label>
              <input
                type="text"
                value={patientData.patient.middleName || ""}
                onChange={(event) => handleInputChange(event, 'middleName')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                value={patientData.patient.lastName || ""}
                onChange={(event) => handleInputChange(event, 'lastName')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                value={patientData.patient.dob || ""} // Use the state value
                onChange={(event) => handleInputChange(event, 'dob')}
              />
            </div>

            {/* Gender Buttons */}
            <div className="input-field">
              <div className="gender-buttons">
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "M" ? "selected" : ""}`}
                  onClick={() => handleInputChange({ target: { value: "M" } }, 'gender')}
                >
                  M
                </button>
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "F" ? "selected" : ""}`}
                  onClick={() => handleInputChange({ target: { value: "F" } }, 'gender')}
                >
                  F
                </button>
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                value={patientData.patient.address.address1 || ""}
                onChange={(event) => handleInputChange(event, 'address', 'address1')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                value={patientData.patient.address.address2 || ""}
                onChange={(event) => handleInputChange(event, 'address', 'address2')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="city">City</label>
              <input
                type="text"
                value={patientData.patient.address.city || ""}
                onChange={(event) => handleInputChange(event, 'address', 'city')}
              />
            </div>


            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  value={patientData.patient.address.state || ""}
                  onChange={(event) => handleInputChange(event, 'address', 'state')}
                />
              </div>

              <div className="input-field">
                <label htmlFor="zip">Zip</label>
                <input
                  type="text"
                  value={patientData.patient.address.zip || ""}
                  onChange={(event) => handleInputChange(event, 'address', 'zip')}
                />
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                value={patientData.patient.email || ""}
                onChange={(event) => handleInputChange(event, 'email')}
              />
            </div>
            <div className="input-field">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                value={patientData.patient.phone || ""}
                onChange={(event) => handleInputChange(event, 'phone')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="phone">MemberId</label>
              <input
                type="tel"
                value={payerData.memberId}
                onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                  handleInputChange({ target: { value: sanitizedValue } }, 'memberId', null, true);
                }}
              />

            </div>
          </div>

          {/* Payer Selection */}
          <div className="edit-group-payerSelection content-center">
            <PayerSelection
              mode={'claims'}
              TradingPartnerName={payerData.name}
              onSelect={handlePayerSelect}
              required
            />
          </div>

          {errorMsg && <div className="error-message">{errorMsg}</div>}
          <div className="input-group-row">
            <div className="input-field">
              <button type="submit" className="done-button">
                Done
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPatientPage;
