import React, { useState } from 'react';
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';

function Medications({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [medication, setMedication] = useState({
    medicationName: '',
    dosage: '',
    frequency: '',
    startDate: '',
    endDate: '',
    notes: ''
  });

  const handleChange = (e) => {
    setMedication({
      ...medication,
      [e.target.name]: e.target.value
    });
  };

  const isFormEmpty = () => {
    return !medication.medicationName || !medication.dosage || !medication.frequency;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in at least the medication name, dosage, and frequency.");
      return;
    }

    const medicalHistoryRef = collection(
      doc(db, 'patients', uid, 'patientData', patientId),
      'medicalHistory'
    );

    try {
      await addDoc(medicalHistoryRef, { type: 'Medications', timestamp: serverTimestamp(),
      ...medication });
      console.log('Medication added:', medication);

      setMedication({
        medicationName: '',
        dosage: '',
        frequency: '',
        startDate: '',
        endDate: '',
        notes: ''
      });
      onClose();
    } catch (error) {
      console.error('Error adding medication record: ', error);
    }
  };

  return (
    <div className={styles.medicationForm}>
      <form onSubmit={handleSubmit}>
      <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="medicationName">Medication Name:</label>
          <input
            type="text"
            id="medicationName"
            name="medicationName"
            value={medication.medicationName}
            onChange={handleChange}
            placeholder="Enter medication name"
          />
        </div>
      </div>

      <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="dosage">Dosage:</label>
          <input
            type="text"
            id="dosage"
            name="dosage"
            value={medication.dosage}
            onChange={handleChange}
            placeholder="Enter dosage"
          />
        </div>
        <div className='input-field'>
          <label htmlFor="frequency">Frequency:</label>
          <input
            type="text"
            id="frequency"
            name="frequency"
            value={medication.frequency}
            onChange={handleChange}
            placeholder="e.g., once daily, every 8 hours"
          />
        </div>
      </div>

      <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={medication.startDate}
            onChange={handleChange}
          />
        </div>
        <div className='input-field'>
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={medication.endDate}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="notes">Notes:</label>
          <textarea
            id="notes"
            name="notes"
            value={medication.notes}
            onChange={handleChange}
            placeholder="Special instructions or additional information"
          />
        </div>
        </div>

        <button type="submit" className={styles.submitButton} disabled={isFormEmpty()}>
          Add Medication
        </button>
      </form>
    </div>
  );
}

export default Medications;
