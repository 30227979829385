import React from "react";
import { Draggable } from "react-beautiful-dnd";
import FormElementTypes from "./FormElementTypes";
import FormElementSideBar from "./FormElementSideBar";

const InputTemplate = ({
  item,
  index,
  setFormElementsData,
  formElementSubTypes,
}) => {
  // Handles any change to any sub form element and is also used to set the defaults
  const handleChange = (e, id = 0, field = "label") => {
    setFormElementsData((prevElements) => {
      const updatedElements = prevElements.map((element, idx) => {
        if (idx === index) {
          if (field === "options") {
            const updatedOptions = e.target ? e.target.value : e;
            const updatedFields = element.fields.map((fieldItem, fieldIndex) =>
              fieldIndex === id ? { ...fieldItem, options: updatedOptions } : fieldItem
            );
            return { ...element, fields: updatedFields };
          } else {
            const updatedFields = element.fields.map((fieldItem, fieldIndex) =>
              fieldIndex === id
                ? { ...fieldItem, [field]: e.target ? e.target.value : e }
                : fieldItem
            );
            return { ...element, fields: updatedFields };
          }
        } else {
          return element;
        }
      });
      return updatedElements;
    });
  };

  const toggleExpandableArea = () => {
    setFormElementsData((prevElements) => {
      const updatedElements = prevElements.map((element, idx) => {
        const isCurrentElement = idx === index;
        return {
          ...element,
          expandableAreaOpen: isCurrentElement
            ? !element.expandableAreaOpen
            : false,
        };
      });
      return updatedElements;
    });
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className="DragItem"
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <FormElementTypes
              item={item}
              handleChange={handleChange}
              formElementSubTypes={formElementSubTypes}
            />
            <FormElementSideBar
              toggleExpandableArea={toggleExpandableArea}
              expandableAreaOpen={item.expandableAreaOpen}
              index={index}
              setFormElementsData={setFormElementsData}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default InputTemplate;