import React, { useState, useEffect } from 'react';
import { db } from "../../firebase";
import { collection, addDoc, doc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import useUID from '../General/useUID';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faStopCircle } from '@fortawesome/free-regular-svg-icons';
import Encounters from './Encounters/Encounters';

const PatientFileTimer = ({ patientSelected }) => {
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [firestoreTimerId, setFirestoreTimerId] = useState(null);
    const [uid] = useUID();
    const patientId = patientSelected.id;
    const [showEncounters, setShowEncounters] = useState(false);
    const [calculatedDuration, setCalculatedDuration] = useState(null);

    useEffect(() => {
        if (!uid || !patientId) {
            // Reset timer states if uid or patientId is not available
            resetTimerStates();
            return;
        }
    
        const timerCollectionRef = collection(db, `patients/${uid}/patientData/${patientId}/Timers`);
        const fetchRunningTimer = async () => {
            const q = query(timerCollectionRef, where("isRunning", "==", true));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const timerDoc = querySnapshot.docs[0]; // Get the first running timer
                const timerData = timerDoc.data();
                const start = timerData.startTime.toDate();
    
                setStartTime(start);
                setElapsedTime(new Date() - start);
                setFirestoreTimerId(timerDoc.id); // Save the Firestore ID
                continueTimer(start);
            } else {
                // No running timer found, reset timer states
                resetTimerStates();
            }
        };
    
        fetchRunningTimer();
    }, [uid, patientId]);
    
    // Function to reset timer states
    const resetTimerStates = () => {
        if (intervalId) clearInterval(intervalId);
        setStartTime(null);
        setElapsedTime(0);
        setFirestoreTimerId(null);
        setIntervalId(null);
    };    
    

    const continueTimer = (start) => {
        if (intervalId) {
            clearInterval(intervalId);
        }

        const interval = setInterval(() => {
            setElapsedTime(new Date() - start);
        }, 1000);
        setIntervalId(interval);
    };

    const startTimer = async () => {
        if (intervalId) {
            return;
        }
    
        const start = new Date();
        setStartTime(start);
    
        const timerCollectionRef = collection(db, `patients/${uid}/patientData/${patientId}/Timers`);
    
        // Fetch any existing running timers
        const q = query(timerCollectionRef, where("isRunning", "==", true));
        const querySnapshot = await getDocs(q);
    
        // If there's an existing running timer, update it
        if (!querySnapshot.empty) {
            const existingTimerDoc = querySnapshot.docs[0];
            await updateDoc(doc(db, `patients/${uid}/patientData/${patientId}/Timers`, existingTimerDoc.id), {
                endTime: new Date(),
                isRunning: false
            });
        }
    
        // Create a new timer document
        const newTimerDoc = await addDoc(timerCollectionRef, {
            uid: uid,
            patientId: patientId,
            startTime: start,
            isRunning: true
        });
        setFirestoreTimerId(newTimerDoc.id);
        continueTimer(start);
    };
    

    const stopTimer = async () => {
    if (!firestoreTimerId) {
        console.log('No running timer found in Firestore.');
        return;
    }

    clearInterval(intervalId);
    const endTime = new Date();
    const duration = endTime - startTime;

    try {
        await updateDoc(doc(db, `patients/${uid}/patientData/${patientId}/Timers`, firestoreTimerId), {
            endTime: endTime,
            duration: duration,
            isRunning: false
        });
    } catch (error) {
        console.error('Error updating timer document:', error);
    }

    setElapsedTime(0);
    setStartTime(null);
    setFirestoreTimerId(null);
    setIntervalId(null);
    setShowEncounters(true); // Set showEncounters to true to display Encounters component

    // This will re-render the component and Encounters should receive the new duration
    setCalculatedDuration(duration);
};
    

    return (
        <>
            {intervalId ? (
                // Timer running
                <div className="timerContainer">
                    <FontAwesomeIcon style={{paddingLeft: '.5rem'}} onClick={stopTimer} icon={faStopCircle} />
                    <p className="timerText">
                        {Math.floor(elapsedTime / 60000)}:{Math.floor((elapsedTime % 60000) / 1000).toString().padStart(2, '0')}
                    </p>
                </div>
            ) : (
                // Timer not running
                <FontAwesomeIcon style={{paddingLeft: '.5rem'}} onClick={startTimer} icon={faPlayCircle} />
            )}
    
            {showEncounters && calculatedDuration != null && (
                // Only render Encounters when showEncounters is true and the duration has been calculated
                <Encounters 
                    patientId={patientId}
                    onClose={() => setShowEncounters(false)}
                    duration={calculatedDuration} 
                />
            )}
    
            {/* Modal logic */}
            {/* ... */}
        </>
    );
};

export default PatientFileTimer;