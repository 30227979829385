import React from "react";
import styles from './Website.css';
import { Link } from 'react-router-dom';
import logo from './assetts/Specto_logo_specto.png'
import Footer from "./Footer";
import Header from "./Header";

const TermsOfUsePage = () => {
  return (
    <div className="page-container">
      <Header />
      <div style={{ flex: 1, padding: '100px' }}>
      <h1>Terms of Service for specto Health</h1>
      <h3>
        Thank you for using specto Health, a web application designed for large volume medical clients such as hospitals, labs, and large clinics. To ensure the proper use of our service, we require all users to agree to the following terms of service and business associate agreement (BAA):
      </h3>
      <h2>Use of Service</h2>
      <p>
        By using specto Health, you agree to use the service for its intended purposes only. You agree not to use the service for any unlawful, fraudulent, or malicious activity.
      </p>
      <h2>Security and Privacy</h2>
      <p>
        specto Health is committed to protecting your privacy and the confidentiality of your data. We use industry-standard security measures to protect your data, and we comply with all relevant data protection laws. As a medical client, you acknowledge that you are a covered entity or business associate under HIPAA and that specto Health is a business associate. As such, you agree to enter into a BAA with us to ensure compliance with HIPAA regulations.
      </p>
      <h2>Billing and Payment</h2>
      <p>
        specto Health provides batch billing and eligibility services. By using these services, you agree to pay any applicable fees. You also agree to provide accurate and up-to-date billing and payment information. By agreeing to these terms of service, you authorize specto Health to conduct daily credit card billing based on your usage of our app, specifically the number of requests made, until you terminate your use of our service.
      </p>
      <h2>Third-Party Payer Downtime</h2>
      <p>specto connects with multiple third-party payers to provide eligibility, claims, and claims status information. These third-party systems may, from time to time, undergo maintenance or experience unforeseen technical issues, causing temporary unavailability. When specto is made aware of a payer's system going offline or becoming unavailable, we will take measures to temporarily remove the affected payer from the selectable options within our tool until the issue is resolved. This is done to prevent disruptions in your workflows and ensure accurate data transmission. specto cannot control or predict these downtime occurrences and is not liable for any disruptions or inconveniences they may cause. We strive to communicate any known issues as swiftly as possible to mitigate their impact on your operations. Your understanding and patience during such times is appreciated.</p>
      <h2>AI-Assisted Doctor's Note Generation</h2>
        <p>
          specto Health includes a feature that assists users in creating doctors' notes using artificial intelligence ("AI"). You understand and agree that these AI-generated doctors' notes are provided for convenience and should be used responsibly. You acknowledge that while specto Health has implemented a range of measures to ensure the accuracy and appropriateness of information generated by our AI, these measures do not guarantee 100% accuracy. Therefore, we are not responsible for any errors, inaccuracies, or omissions that may occur in the AI-generated doctors' notes. 
        </p>
        <p>
          We have implemented a document ID system that embeds a hash at the bottom of each PDF-created note. This system serves to verify the authenticity of a doctors' note generated using specto Health. It does not validate or endorse the content of the note. specto Health cannot be held accountable for the misuse of this feature. Any use of the AI-assisted doctors' note generation feature is subject to the full terms and conditions outlined in this document. 
        </p>
      
      <h2>Intellectual Property</h2>
      <p>
        The specto Health web application, its content, and any associated trademarks and copyrights are the property of specto Health. You agree not to reproduce, distribute, or otherwise use any of these materials without our express written consent.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        specto Health is not responsible for any damages, including but not limited to direct, indirect, incidental, consequential, or punitive damages, arising from the use or inability to use our service.
      </p>
      <h2>Indemnification</h2>
      <p>
      You agree to indemnify and hold specto Health, its affiliates, officers, directors, employees, agents, legal representatives, and licensors harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the AI-assisted doctors' note generation feature or your violation of these Terms of Service.
      </p>
      <h2>Termination</h2>
      <p>
        specto Health reserves the right to terminate your use of the service at any time for any reason. You may also terminate your use of the service at any time.
      </p>
      <h2>Business Associate Agreement (BAA)</h2>
      <p>
        <strong>Purpose and Scope:</strong> The purpose of this BAA is to establish the terms and conditions under which specto Health will comply with the HIPAA regulations as a business associate of the medical client.
      </p>
      <p>
        <strong>Obligations of specto Health:</strong> specto Health agrees to:
          <li>Implement appropriate safeguards to prevent the unauthorized use or disclosure of protected health information (PHI).</li>
          <li>Report any unauthorized use or disclosure of PHI to the medical client promptly.</li>
          <li>Make available PHI in accordance with the HIPAA regulations.</li>  
          <li>Per HIPAA, state, and federal regulations, we soft-delete patient data within the system and are obligated to retain it for 7 years.</li>
          <li>In accordance with HIPAA guidance, we maintain a disaster recovery system with our cloud data storage partner to protect all medical data in the event of a catastrophe.</li>
          </p>
  <p>
    <strong>Limitation on Subcontracting:</strong> specto Health will not disclose or permit access to PHI by any subcontractors, agents, or third-party service providers without the prior written consent of the medical client, except as required by law.
  </p>
  <p>
    <strong>Obligations of the Medical Client:</strong> The medical client agrees to:
    <ul>
      <li>Inform specto Health of any restrictions on the use or disclosure of PHI.</li>
      <li>Make any necessary changes to the PHI directly within the specto system or notify specto Health if unable to do so.</li>
      <li>Provide specto Health with any notices required by the HIPAA regulations.</li>
      <li>Obtain any necessary authorizations for the use or disclosure of PHI.</li>
    </ul>
  </p>
  <p>
    <strong>Term and Termination:</strong> This BAA will remain in effect for as long as specto Health maintains PHI or performs services for the medical client. Either party may terminate this BAA upon written notice if the other party breaches any of its obligations under this BAA.
  </p>
  <p>
    By using specto Health, you agree to these terms of service and the business associate agreement. If you have any questions, please contact us at support@spectohealth.com.
  </p>
  
</div>
<Footer/ >

    </div>
  );
};

export default TermsOfUsePage;