import React, { useState } from 'react';
import BillingCodesSelector from './BillingCodesSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';

const CodesMenu = ({ onClose }) => {
    const [showBillingCodes, setShowBillingCodes] = useState(false);

    const handleBillingCodesClick = () => {
        setShowBillingCodes(true);
    };

    const handleBillingCodeSelect = () => {
        console.log('selected');
    };

    // Determine the button class
    const buttonClass = `MainActionButton ${showBillingCodes ? 'selected' : ''}`;

    return (
        <>
            {showBillingCodes && 
                <button className="filesCloseButton" onClick={() => {
                    setShowBillingCodes(false);
                    onClose();
                }}>
                    X
                </button>
            }

            {!showBillingCodes && 
                <button
                    id="BillingCodesButton"
                    onClick={handleBillingCodesClick}
                    className={buttonClass}
                >
                    <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '10px' }} />
                    My Billing Codes
                </button>
            }

            {showBillingCodes && (
                <div className='Claims-inner-container'>
                    <div className='claimsMenus'>
                        <BillingCodesSelector onBillingCodeSelect={handleBillingCodeSelect} />
                    </div>
                </div>
            )}
        </>
    );
};

export default CodesMenu;
