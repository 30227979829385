import React, { useState, useEffect } from 'react';
import { storage, auth, db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserIcon = () => {
  const [initials, setInitials] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const name = userData.Name; // assuming the field name is "Name"
            const namePieces = name.split(' ');
            const initials = `${namePieces[0].charAt(0)}${namePieces[1].charAt(0)}`.toUpperCase();
            setInitials(initials);
          } else {
            console.log('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserName();
  }, []);

  return (
    <div className='usercircleContainer'>
      {auth.currentUser ? (
        <span className="initialsCircle">
          {initials}
        </span>
      ) : (        
      <span className="initialsCircle">
        <FontAwesomeIcon icon={faUser} />        
        </span>
      )}
    </div>
  );
};

export default UserIcon;
