import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const PaymentPosting = ({ onClose }) => {
    const [showPaymentPosting, setShowPaymentPosting] = useState(false);
    const [uid] = useUID();
    const [paymentPostings, setPaymentPostings] = useState([]);
    const [claimStatus277, setClaimStatus277] = useState([]); // New state for 277 data
    const [viewType, setViewType] = useState('835'); // Initialize viewType state

    useEffect(() => {
        if (uid) {
            // Fetch 835 data
            const paymentPostingRef = collection(db, `paymentPosting/${uid}/835`);
            getDocs(paymentPostingRef)
                .then(querySnapshot => {
                    const postings = querySnapshot.docs.map(doc => doc.data());
                    setPaymentPostings(postings);
                })
                .catch(error => {
                    console.error('Error fetching payment postings:', error);
                });

            // Fetch 277 data
            const claimStatus277Ref = collection(db, `paymentPosting/${uid}/277`);
            getDocs(claimStatus277Ref)
                .then(querySnapshot => {
                    const statusData = querySnapshot.docs.map(doc => doc.data());
                    setClaimStatus277(statusData);
                })
                .catch(error => {
                    console.error('Error fetching claim status 277:', error);
                });
        }
    }, [uid]);

    const handleProviderSelectionClick = () => {
        setShowPaymentPosting(true);
    };

    const handleToggleChange = (event) => {
        setViewType(event.target.value);
    };

    const buttonClass = `MainActionButton ${showPaymentPosting ? 'selected' : ''}`;

    return (
        <>
            {!showPaymentPosting && 
                <button
                    id="ClaimStatusButton"
                    onClick={handleProviderSelectionClick}
                    className={buttonClass}
                >
                    <FontAwesomeIcon icon={faUserMd} style={{ marginRight: '10px' }} />
                    Payment Posting
                </button>
            }
            {showPaymentPosting && (
                <div className='Claims-inner-container'>
                   
                    <div className='popupContent'> 
                            <button className="filesCloseButton" onClick={() => setShowPaymentPosting(false)}>
                                X
                            </button>
                        <label>
                            <input
                                type="checkbox"
                                className='custom-checkbox'
                                value="835"
                                checked={viewType === '835'}
                                onChange={handleToggleChange}
                            />
                            835
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                className='custom-checkbox'
                                value="277"
                                checked={viewType === '277'}
                                onChange={handleToggleChange}
                            />
                            277
                        </label>
                        
                        {viewType === '835' && (
    <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Procedure Code</th>
                <th>Line Item Charge Amount</th>
                <th>Allowed Actual</th>
                <th>Adjustment Amount</th>
                <th>Adjustment Reason Code</th>
                <th>Claim Adjustment Group Code</th>
                <th>Claim Status Code</th>
                <th>Line Item Provider Payment Amount</th>
                <th>Payer</th>
                <th>Member ID</th>
                <th>NPI</th>
                <th>Patient Control Number</th>
                <th>Service Date</th>
            </tr>
        </thead>
        <tbody>
            {paymentPostings.map((posting, index) => (
                <tr key={index}>
                    <td>{`${posting.firstName} ${posting.lastName}`}</td>
                    <td>{posting.adjudicatedProcedureCode}</td>
                    <td>{`$${posting.lineItemChargeAmount}`}</td>
                    <td>{`$${posting.allowedActual}`}</td>
                    <td>
                        {posting.adjustments.map((adj, adjIndex) => (
                            <div key={adjIndex}>
                                {`$${adj.adjustmentAmount} (${adj.claimAdjustmentGroupCode})`}
                            </div>
                        ))}
                    </td>
                    <td>{posting.adjustments.map(adj => adj.adjustmentReasonCode).join(', ')}</td>
                    <td>{posting.adjustments.map(adj => adj.claimAdjustmentGroupCode).join(', ')}</td>
                    <td>{posting.claimStatusCode}</td>
                    <td>{`$${posting.lineItemProviderPaymentAmount}`}</td>
                    <td>{posting.payerName}</td>
                    <td>{posting.memberId}</td>
                    <td>{posting.npi}</td>
                    <td>{posting.patientControlNumber}</td>
                    <td>{posting.serviceDate}</td>
                </tr>
            ))}
        </tbody>
    </table>
)}

                      {viewType === '277' && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Control Number</th>
                                    <th>Effective Date</th>
                                    <th>Status Category Code</th>
                                    <th>Status Code</th>
                                    <th>Tracking Number</th>
                                    <th>Service Provider NPI</th>
                                    <th>Type</th>
                                    {/* Add other fields as needed */}
                                </tr>
                            </thead>
                            <tbody>
                            {claimStatus277.map((status, index) => (
                                <tr key={index}>
                                    <td>{status.mainStatus.controlNumber}</td>
                                    <td>{status.mainStatus.effectiveDate}</td>
                                    <td>{status.mainStatus.statusCategoryCode}</td>
                                    <td>{status.mainStatus.statusCode}</td>
                                    <td>{status.mainStatus.trackingNumber}</td>
                                    <td>{status.serviceProviderNPI}</td>
                                    <td>{status.type}</td>
                                    {/* Render other fields as needed */}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentPosting;
