// PosCodes.js
import React from 'react';

export const posCodes = [
        { code: "01", description: "Pharmacy" },
        { code: "02", description: "Telehealth" },
        { code: "03", description: "School" },
        { code: "04", description: "Homeless shelter" },
        { code: "05", description: "Indian Health Free-standing Facility" },
        { code: "06", description: "Indian Health Provider-based Facility" },
        { code: "07", description: "Tribal 638 Free-standing Facility" },
        { code: "08", description: "Tribal 638 Provider-based Facility" },
        { code: "09", description: "Prison/Correctional Facility" },
        { code: "10", description: "Telehealth Provided in Patients Home" },
        { code: "11", description: "Office" },
        { code: "12", description: "Home" },
        { code: "13", description: "Assisted Living Facility" },
        { code: "14", description: "Group Home" },
        { code: "15", description: "Mobile Unit" },
        { code: "16", description: "Temporary Lodging" },
        { code: "17", description: "Walk-in Retail Health Clinic" },
        { code: "18", description: "Place of Employment/Worksite" },
        { code: "19", description: "Off Campus-Outpatient Hospital" },
        { code: "20", description: "Urgent Care Facility" },
        { code: "21", description: "Inpatient Hospital" },
        { code: "22", description: "On Campus-Outpatient Hospital" },
        { code: "23", description: "Emergency Room Hospital" },
        { code: "24", description: "Ambulatory Surgical Center" },
        { code: "25", description: "Birthing Center" },
        { code: "26", description: "Military Treatment Facility" },
        { code: "31", description: "Skilled Nursing Facility" },
        { code: "32", description: "Nursing Facility" },
        { code: "33", description: "Custodial Care Facility" },
        { code: "34", description: "Hospice" },
        { code: "41", description: "Ambulance - Land" },
        { code: "42", description: "Ambulance - Air or Water" },
        { code: "49", description: "Independent Clinic" },
        { code: "50", description: "Federally Qualified Health Center" },
        { code: "51", description: "Inpatient Psychiatric Facility" },
        { code: "52", description: "Psychiatric Facility-Partial Hospitalization" },
        { code: "53", description: "Community Mental Health Center" },
        { code: "54", description: "Intermediate Care Facility/ Intellectual Disabilities" },
        { code: "55", description: "Residential Substance Abuse Treatment Facility" },
        { code: "56", description: "Psychiatric Residential Treatment Center" },
        { code: "57", description: "Non-residential Substance Abuse Treatment Facility" },
        { code: "58", description: "Non-residential Opioid Treatment Facility" },
        { code: "60", description: "Mass Immunization Center" },
        { code: "61", description: "Comprehensive Inpatient Rehabilitation Facility" },
        { code: "62", description: "Comprehensive Outpatient Rehabilitation Facility" },
        { code: "65", description: "End-Stage Renal Disease Treatment Facility" },
        { code: "71", description: "Public Health Clinic" },
        { code: "72", description: "Rural Health Clinic" },
        { code: "81", description: "Independent Laboratory" },
        { code: "99", description: "Other Place of Service" },
    ];

export function PosCodeDropdown({ selectedValue, onChange }) {
    return (
        <select
            className="dropdown-select"
            value={selectedValue}
            onChange={onChange}
            required
        >
            <option value="">-- Select a code --</option>
            {posCodes.map((posCode) => (
                <option key={posCode.code} value={posCode.code}>
                    {`${posCode.code} - ${posCode.description}`}
                </option>
            ))}
        </select>
    );
}
