import React, { useState, useEffect } from "react";
import { PosCodeDropdown } from '../Claims/PosCodes';
import HexagonSpinner from '../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from "../../firebase";
import {
    collection,
    onSnapshot,
    getDocs,
    setDoc,
    addDoc,
    deleteDoc,
    doc
} from "firebase/firestore";
import useUID from '../General/useUID'


function ProviderForm(props) {
    const { provider, onClose } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [npi, setNpi] = useState('');
    const [ein, setEin] = useState('');
    const [medicareNumber, setMedicareNumber] = useState('');
    const [medicaidNumber, setMedicaidNumber] = useState('');
    const [cliaNumber, setCliaNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [selectedPosCode, setSelectedPosCode] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState(null);
    const [uid, subUserUID, isLoading] = useUID();


    const states = [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];

    // Initialize Firestore database reference
    const providersRef = collection(db, `users/${uid}/providers`);

    useEffect(() => {
        if (!uid) return;
        if (provider) {
            populateFormForEdit(provider);
            setEditing(true)
        }
    }, [uid]);


    const populateFormForEdit = (provider) => {
        setOrganizationName(provider.organizationName);
        setFirstName(provider.firstName);
        setLastName(provider.lastName);
        setNpi(provider.npi);
        setEin(provider.ein);
        setAddress1(provider.address1);
        setAddress2(provider.address2 || "");
        setCity(provider.city);
        setState(provider.state);
        setZip(provider.zip);
        setSelectedPosCode(provider.posCode);
        setMedicareNumber(provider.medicareNumber || "");
        setMedicaidNumber(provider.medicaidNumber || "");
        setCliaNumber(provider.cliaNumber || "");
        setEditing(true);
        setCurrentEditingId(provider.id);
    };

    const validateAddress = (address, city, state, zip) => {
        let errors = [];

        if (!address.trim()) {
            errors.push("Address is required.");
        }

        if (!city.trim()) {
            errors.push("City is required.");
        }

        if (!state.trim()) {
            errors.push("State is required.");
        }

        if (!zip.trim()) {
            errors.push("Zip code is required.");
        } else if (!/^\d{5}(-\d{4})?$/.test(zip)) {
            errors.push("Invalid zip code format. Must be 5 or 9 digits (xxxxx or xxxxx-xxxx).");
        }

        return errors;
    };


    const closeAddProvider = () => {
        if (onClose) onClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (npi.length !== 10) {
            setError('NPI must be 10 digits.');
            return;
        }

        if (ein.length !== 9) {
            setError('EIN must be 9 digits.');
            return;
        }

        if (!validateAddress(address1, city, state, zip)) {
            setError('Invalid address provided.');
            return;
        }

        setIsSubmitting(true);
        e.preventDefault();
        // Calling the limiter function before adding a provider
        const functions = getFunctions();

        console.log("Before calling the limiter function...");

        const limiterResponse = await httpsCallable(functions, 'limiter')({
            userId: uid, // assuming 'uid' is the ID of the currently logged-in user
            limiterTag: 'provider'
        });

        console.log("After calling the limiter function. Response received:", limiterResponse.data);

        // Check the response
        if (!limiterResponse.data) {
            console.log("Limit reached for this user's account tier.");
            setError(`You've reached the limit for adding providers at your tier.`);
            return;
        } else {
            console.log("User has not reached the limit. Proceeding...");
        }

        setError("");
        setIsSubmitting(true);

        // Formulating provider data
        const providerData = {
            organizationName,
            firstName,
            lastName,
            npi,
            ein,
            address1,
            address2,
            city,
            state,
            zip,
            posCode: selectedPosCode,
        };

        if (medicareNumber) providerData.medicareNumber = medicareNumber;
        if (medicaidNumber) providerData.medicaidNumber = medicaidNumber;
        if (cliaNumber) providerData.cliaNumber = cliaNumber;

        try {
            if (editing) {
                console.log("Editing ID:", currentEditingId);
                const providerDocRef = doc(db, `users/${uid}/providers`, currentEditingId);
                await setDoc(providerDocRef, providerData);
                setEditing(false);
                setCurrentEditingId(null);
            } else {
                await addDoc(providersRef, providerData);
            }

            // Reset form fields
            setOrganizationName("");
            setFirstName("");
            setLastName("");
            setNpi("");
            setEin("");
            setAddress1("");
            setAddress2("");
            setCity("");
            setState("");
            setZip("");
            setSelectedPosCode("");
            setEditing(false);
            setCurrentEditingId(null);

        } catch (error) {
            console.error("Error:", error);
            setError("Error adding provider:", error.message);
        }
        finally {
            setIsSubmitting(false);
            onClose(true);
        }
    };


    return (
        <div className="add-popup over-add-popup">
            <div className="add-form">
                <div className="content-center">
                    {/* <button className='filesCloseButton' onClick={() => {
          }}>X</button> */}
                    <h3 style={{ marginTop: 30 }}>Add Provider</h3>
                    <form onSubmit={handleSubmit}>

                        <div className="input-field">
                            <label htmlFor="organizationName">Organization Name</label>
                            <input
                                type="text"
                                name="organizationName"
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value.toUpperCase())}
                                required
                            />
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="npi">NPI</label>
                                <input
                                    type="text"
                                    name="npi"
                                    maxLength='10'
                                    pattern="^\d{10}$"
                                    value={npi}
                                    onChange={(e) => setNpi(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="ein">Tax ID</label>
                                <input
                                    type="text"
                                    name="ein"
                                    maxLength='9'
                                    pattern="^\d{9}$"
                                    value={ein}
                                    onChange={(e) => setEin(e.target.value)}
                                    required
                                />
                            </div>

                        </div>
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="medicareNumber">Medicare ID (optional)</label>
                                <input
                                    type="text"
                                    name="medicareNumber"
                                    value={medicareNumber}
                                    onChange={(e) => setMedicareNumber(e.target.value)}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="medicaidNumber">Medicaid Provider Number (optional)</label>
                                <input
                                    type="text"
                                    name="medicaidNumber"
                                    value={medicaidNumber}
                                    onChange={(e) => setMedicaidNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="input-field">
                            <label htmlFor="cliaNumber">CLIA Number (optional)</label>
                            <input
                                type="text"
                                name="cliaNumber"
                                value={cliaNumber}
                                onChange={(e) => setCliaNumber(e.target.value)}
                            />
                        </div>
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="address1">Address 1</label>
                                <input
                                    type="text"
                                    name="address1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="address2">Address 2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="state">State</label>
                                <select 
                                    name="state" 
                                    className="w-100" 
                                    value={state} 
                                    onChange={(e) => setState(e.target.value)} 
                                    required
                                >
                                    <option value="">Select a State</option> {/* Default option */}
                                    {states.map(s => <option key={s} value={s}>{s}</option>)}
                                </select>
                            </div>
                            <div className="input-field">
                                <label htmlFor="zip">Zip (9 digit)</label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                    required
                                    pattern="^\d{9}$"
                                    title="ZIP code must be 9 digits (xxxxxxxxx)"
                                />
                            </div>
                        </div>

                        <div className="input-field">
                            <label htmlFor="">Place of Service</label>
                            <PosCodeDropdown
                                selectedValue={selectedPosCode}
                                onChange={(e) => setSelectedPosCode(e.target.value)}
                            />
                        </div>

                        {error && <div className="error">{error}</div>}

                        {isSubmitting ? (
                            <HexagonSpinner />
                        ) : (
                            <div className='display-flex mt-3'>
                                <button className='primary'>
                                    {editing ? (
                                        <>
                                            <FontAwesomeIcon icon={faPencilAlt} className='mr-3' />
                                            Update Provider
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} className='mr-3' />
                                            Add Provider
                                        </>
                                    )}
                                </button>

                                <button onClick={closeAddProvider} className='primary'>
                                    Cancel
                                </button>

                            </div>

                        )}
                    </form>
                </div>

            </div>
        </div>


    );
}

export default ProviderForm;