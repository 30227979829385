import { useEffect, useState, React } from 'react';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

function AuthSuccess() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const userId = query.get('user_id');
  const resource = query.get('resource');
  const referenceId = query.get('reference_id');
  const language = query.get('lan');

  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  // Splitting the referenceId into uid and patientId
  const [uid, patientId] = referenceId.split('-');

  const createTerraSubcollection = async () => {
    const functions = getFunctions();
    const createFunction = httpsCallable(functions, 'createTerraSubcollection');
    try {
        const result = await createFunction({ uid, patientId, userId, resource });
        if(result.data.status === 'success') {
            setIsSuccess(true);
        }
    } catch (error) {
        console.error('Error creating Terra subcollection:', error);
        setError(error.message);
    }
};
  // Call the update function when the component mounts
  useEffect(() => {
    createTerraSubcollection().catch(error => {
      console.error('Error creating Terra subcollection:', error);
      setError(error.message);
    });
  }, [uid, patientId, userId, resource]);

  return (
    <div className='content-center'>
      <h1>Congradulations! Authentication was Successful</h1>
      <p>Resource: {resource}</p>
      <p>Language: {language}</p>
      {error && <p style={{color: 'red'}}>Error: {error}</p>}
      {isSuccess && <p style={{color: 'green'}}>Data added successfully!</p>}
    </div>
  );
}

export default AuthSuccess;
