import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const ProtectedRoute = ({ children, allowNoSubscription }) => {
  const [authUser, setAuthUser] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        const subscription = userDoc.exists() && userDoc.data().subscriptionId;

        setHasSubscription(subscription);
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
      setIsLoading(false);
    });

    return () => {
      listen();
    };
  }, [allowNoSubscription]);

  if (isLoading) {
    return null; // or a loading indicator
  }

  if (!authUser) {
    return <Navigate to="/" />;
  }

  if (allowNoSubscription && hasSubscription) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default ProtectedRoute;
