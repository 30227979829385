import React, { useRef, useState, useEffect } from 'react';
import { db, storage, auth } from '../../firebase';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { marked } from 'marked';
import { ref, put, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const UploadArticle = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('support'); // Default value
  const [keywords, setKeywords] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const textareaRef = useRef();
  const navigate = useNavigate();

  const uid = auth.currentUser.uid;
  console.log(uid)

//   useEffect(() => {
//     // Check user's role when the component mounts
//     checkUserRole();
//   }, []);

  const checkUserRole = async () => {
    // Check if user is an admin
    try {
      const userDocRef = doc(db, 'Users', uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userDocData = userDocSnap.data();
        console.log(userDocData.role)


        if (userDocData.role !== 'admin') {
          return;
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error('Error checking user role: ', error);
      // Handle the error appropriately
      return;
    }
  };
  

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (image) {
      const storageRef = ref(storage, `Articles/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          alert(error.message);
        },
        async () => {
          try {
            const url = await getDownloadURL(storageRef);
            setImageUrl(url);
            setProgress(0);

            // Generate the sitemap after image upload completion
            const generateSitemapFunction = firebase.functions().httpsCallable('generateSitemap');
            generateSitemapFunction()
              .then((result) => {
                console.log(result.data); // Should print 'Sitemap successfully updated.'
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          } catch (error) {
            console.log(error);
            alert('Failed to get download URL');
          }
        }
      );
    } else {
      alert('No file selected');
    }
  };

  const insertImage = () => {
    const imgTag = `<img src="${imageUrl}" alt="Uploaded Image" />`;
    const updatedMarkdown =
      markdown.slice(0, textareaRef.current.selectionStart) +
      imgTag +
      markdown.slice(textareaRef.current.selectionEnd);
    setMarkdown(updatedMarkdown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert markdown to HTML
    const article = marked(markdown);

    // Convert keywords to array
    const keywordArray = keywords.split(',').map((keyword) => keyword.trim());

    // Replace special characters and spaces in title with '-'
    const docId = title.replace(/[^a-zA-Z0-9]/g, '-');

    try {
      await setDoc(doc(db, 'articles', docId), {
        title,
        category,
        keywords: keywordArray,
        article,
      });

      // Reset form
      setTitle('');
      setCategory('support');
      setKeywords('');
      setMarkdown('');
      setImageUrl('');
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="container">
      <progress value={progress} max="100" />
      <input type="file" onChange={handleChange} style={{ display: 'block' }} />
      <button onClick={handleUpload}>Upload</button>
      {imageUrl && (
        <button onClick={insertImage}>Insert Image</button>
      )}
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Category:
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="support">Support</option>
            <option value="blog">Blog</option>
          </select>
        </label>
        <label>
          Keywords (separate with commas):
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            required
          />
        </label>
        <label>
          Article:
          <textarea
            id="article-input"
            ref={textareaRef}
            value={markdown}
            onChange={(e) => setMarkdown(e.target.value)}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
    </div>
  );
};

export default UploadArticle;
