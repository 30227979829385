import React, { useEffect, useState } from "react";
import InputTypeText from "../FormElements/SubInputTypes/InputTypeText";
import InputTypeTextArea from "../FormElements/SubInputTypes/InputTypeTextArea";
import InputTypeHeader from "../FormElements/SubInputTypes/InputTypeHeader";
import InputTypeParagraph from "../FormElements/SubInputTypes/InputTypeParagraph";
import InputTypeSelectState from "../FormElements/SubInputTypes/InputTypeSelectState";
import InputTypeChoice from "../FormElements/SubInputTypes/InputTypeChoice";
import InputTypeSpacer from "../FormElements/SubInputTypes/InputTypeSpacer";

const FormDisplay = ({ formData, onFormInputChange = () => {} }) => {
  const [formInputValues, setFormInputValues] = useState({});

  useEffect(() => {
    setFormInputValues({});
    handleInputChange();
  }, [formData]);

 const handleInputChange = (event, fieldItem) => {
  // key behind here

    // Check if event is defined (not undefined) before accessing its properties
    if (event) {
      const { name, value } = event.target;
      setFormInputValues((prevValues) => ({
        ...prevValues,
        [name]: { value, type: fieldItem.type, required: fieldItem.required },
      }));
      // Pass the updated formInputValues to the onFormInputChange callback
      onFormInputChange({ formData, formInputValues });
    }
  };

  return (
    <div className="">
      <form>
        {formData.map((formElement) => (
          <div
            className="input-group-row w-100 mb-0 FormRow"
            key={formElement.id}
          >
            {formElement.fields.map((fieldItem, index) => (
              <React.Fragment key={index}>
                <div className="input-field pt-2 FormElement">
                  {(() => {
                    const components = {
                      textarea: InputTypeTextArea,
                      state: InputTypeSelectState,
                      header: InputTypeHeader,
                      paragraph: InputTypeParagraph,
                      choice: InputTypeChoice,
                      Spacer: InputTypeSpacer,
                    };
                    const Component =
                      components[fieldItem.type] || InputTypeText;
                    return (
                      <Component
                        fieldItem={fieldItem}
                        handleInputChange={(event) =>
                          handleInputChange(event, fieldItem)
                        }
                        preview={false}
                        formElementId={formElement.id}
                      />
                    );
                  })()}
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </form>
    </div>
  );
};

export default FormDisplay;
