import { forwardRef } from "react";
import styles from './SingleClaim.module.css';

const CollapsibleSection = forwardRef((
  {
    title,
    number,
    children,
    activeSection,
    setActiveSection,
  },
  ref
) => {
  const isOpen = activeSection === number;

  const handleSectionClick = () => {
    if (isOpen) {
      setActiveSection(0);  // or any other logic to close all sections if needed
    } else if (number <= activeSection) {  // Only allow moving backward or to the same section
      setActiveSection(number);
    }
  };

  return (
    <div className="collapsibleSection" ref={ref}>
      <div className="sectionHeader" onClick={handleSectionClick}>
        <span className="sectionNumber">{number}.</span>
        <span className="sectionTitle">{title}</span>
        <span className="indicator">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
});

export default CollapsibleSection;
