import React from 'react';
import { Link } from 'react-router-dom';
import whitelogo from './assetts/Specto_logo_specto.png';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      
      <div className="logo-container">
        <img src={whitelogo} alt="specto Health Technologies" className="logoFooter" />
      </div>
      <Link to="/signup" className="create-account-button">
        Create Account
      </Link>
      <ul className="footer-links">
        <li><Link to="/techspecs" className="footer-link">Tech Specs</Link></li>
        {/* <li><Link to="/pricing" className="footer-link">Pricing</Link></li> */}
        <li><Link to="/terms" className="footer-link">Terms of Service</Link></li>
        <li><Link to="/privacy" className="footer-link">Privacy Policy</Link></li>
        <li><Link to="/signup" className="footer-link">Sign Up</Link></li>
        <li><Link to="/signin" className="footer-link">Sign In</Link></li>
      </ul>
            
      <p className="all-rights-reserved">&copy; 2024 Specto Health. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
