import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { htmlToText } from 'html-to-text';
import Header from '../Website/Header';
import Footer from '../Website/Footer';

const Blog = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollection = collection(db, 'articles');
      const articleSnapshot = await getDocs(articlesCollection);
      const articleList = articleSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })).filter(article => article.category === 'blog');  // Only include articles with the category 'Blog'

      setArticles(articleList);
    };

    fetchArticles();
  }, []);

  return (
    <>
    <div className="app-container">
      <Header />
      <div className="container">
      <h1>Our Blog</h1>
      <ul>
        {articles.map((article) => (
          <li key={article.id}>
            <Link to={`/article/${article.id}`}>
              <h2>{article.title}</h2>
            </Link>
            {/* Convert the HTML to plain text and show the first 100 characters as the summary */}
            {/* Find the first <p> tag and its content */}
            <p>{
              htmlToText(article.article.match(/<p>(.*?)<\/p>/)[0], {
                wordwrap: null,
                ignoreHref: true,
                ignoreImage: true,
                hideLinkHrefIfSameAsText: true,
                uppercaseHeadings: false,
                singleNewLineParagraphs: true,
                ignoreTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div']
              }).slice(0, 100)}...</p>
            <Link to={`/article/${article.id}`}>Read More</Link>
          </li>
        ))}
      </ul>
    </div>
    </div>
        <Footer />
        </>
  );
};

export default Blog;
