import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import {
  collection,
  onSnapshot,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  doc
} from "firebase/firestore";
import SearchBox from './ICD10'
import './Claims.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import HexagonSpinner from "../General/Animations/Hexspinner";
import Modal from 'react-modal';

function BillingCodesSelector(props) {
  const { onBillingCodeSelect } = props;
  const [billingCodes, setBillingCodes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [billAmount, setBillAmount] = useState("0.00");
  const [modifierA, setModifierA] = useState("");
  const [modifierB, setModifierB] = useState("");
  const [diagnosisCode, setDiagnosisCode] = useState("");
  const [error, setError] = useState("");
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [overrideDiagnosisCode, setOverrideDiagnosisCode] = useState(false);
  const [editingCodeId, setEditingCodeId] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);

  // Initialize Firestore database reference
  const uid = auth.currentUser.uid;
  const billingCodesRef = collection(db, `users/${uid}/billingCodes`);

  useEffect(() => {
    async function getBillingCodes() {
      setIsLoading(true);
      // Check if billing codes collection exists, create if not
      const querySnapshot = await getDocs(billingCodesRef);
      if (querySnapshot.empty) {
        await addDoc(billingCodesRef, {});
      }

      // Load billing codes from database on mount
      const unsubscribe = onSnapshot(billingCodesRef, (querySnapshot) => {
        const billingCodesData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          billingCodesData.push({
            id: doc.id,
            name: data.name,
            code: data.code,
            diagnosisCode: data.diagnosisCode,
            billAmount: data.billAmount,
            modifierA: data.modifierA,
            modifierB: data.modifierB,
          });
        });
        setBillingCodes(billingCodesData);
        setIsLoading(false); 
      });
      return unsubscribe;
    }

    getBillingCodes();
  }, []);

  // Handle form submission to add new billing code
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
  
    // Convert the bill amount to cents
    const billAmountInDollars = parseFloat(billAmount);
  
    // Add new billing code to Firestore
    await addDoc(billingCodesRef, {
      name,
      code,
      diagnosisCode,
      billAmount: billAmountInDollars,
      modifierA,
      modifierB,
    });
  
    // Reset form fields
    setName("");
    setCode("");
    setDiagnosisCode("");
    setBillAmount("0.00");
    setModifierA("");
    setModifierB("");
    setShowPopup(false);
  };
  
  

  const handleDelete = async (e, billingCodeId) => {
    e.stopPropagation(); // Prevent row selection when clicking delete button

    // Delete billing code from Firestore
    await deleteDoc(doc(billingCodesRef, billingCodeId));

    // Update billing codes state to remove deleted billing code
    const updatedBillingCodes = billingCodes.filter((billingCode) => billingCode.id !== billingCodeId);
    setBillingCodes(updatedBillingCodes);
  };

  const handleDeleteRequest = (e, billingCodeId) => {
    e.stopPropagation(); // Prevent row selection when clicking delete button
    setDeleteCandidateId(billingCodeId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDelete = async () => {
    await deleteDoc(doc(billingCodesRef, deleteCandidateId));

    // Update billing codes state to remove deleted billing code
    const updatedBillingCodes = billingCodes.filter((billingCode) => billingCode.id !== deleteCandidateId);
    setBillingCodes(updatedBillingCodes);
    setShowDeleteConfirmModal(false);
  };

  // Handle selection of existing billing code
  const handleSelect = (billingCodeId) => {
    // Find the selected billing code from the billing codes array
    const billingCode = billingCodes.find(
      (billingCode) => billingCode.id === billingCodeId
    );

    // Check if the selected billing code is already in the array
    const alreadySelected = selectedBillingCodes.some(
      (selectedCode) => selectedCode.id === billingCodeId
    );

    // Update the selected billing codes state
    setSelectedBillingCodes((prevState) => {
      let updatedState;
      if (!alreadySelected && prevState.length < 5) {
        updatedState = [
          ...prevState,
          {
            id: billingCodeId,
            name: billingCode.name,
            code: billingCode.code,
            diagnosisCode: billingCode.diagnosisCode,
            billAmount: billingCode.billAmount,
            modifierA: billingCode.modifierA,
            modifierB: billingCode.modifierB,
          },
        ];
      } else if (alreadySelected) {
        updatedState = prevState.filter(
          (selectedCode) => selectedCode.id !== billingCodeId
        );
      } else {
        updatedState = prevState;
      }

      // Call the parent component's function with the array of updated selected billing codes
      onBillingCodeSelect(
        updatedState.map((selectedCode) => ({
          name: selectedCode.name,
          code: selectedCode.code,
          diagnosisCode: selectedCode.diagnosisCode,
          billAmount: selectedCode.billAmount,
          modifierA: selectedCode.modifierA,
          modifierB: selectedCode.modifierB,
        }))
      );

      return updatedState;
    });
  };


    const resetFormFields = () => {
            setName("");
            setCode("");
            setDiagnosisCode("");
            setBillAmount(0);
            setModifierA("");
            setModifierB("");
    };

    const handleDiagnosisCodeSelect = (selectedCode) => {
      setDiagnosisCode(selectedCode);
    };

    const startEdit = (billingCode) => {
      setName(billingCode.name);
      setCode(billingCode.code);
      setDiagnosisCode(billingCode.diagnosisCode || []);      
      setBillAmount((billingCode.billAmount));
      setModifierA(billingCode.modifierA);
      setModifierB(billingCode.modifierB);
      setEditingCodeId(billingCode.id);
      setShowPopup(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const billAmountInDollars = parseFloat(billAmount);
    await setDoc(doc(billingCodesRef, editingCodeId), {
        name,
        code,
        diagnosisCode,
        billAmount: billAmountInDollars,
        modifierA,
        modifierB,
    });
    resetFormFields();
    setShowPopup(false);
    setEditingCodeId(null);
};

const handleFormSubmit = (event) => {
  event.preventDefault();
  if (editingCodeId) {
      handleUpdate(event);
  } else {
      handleSubmit(event);
  }
};




return (
    <div className="billing-codes-selector-container">
            <div className="billing-codes-header">
            <h3 className="billing-codes-label">Billing Codes</h3>
                    <button
                    className="add-billing-code-button"
                    onClick={() => setShowPopup(true)}
                    >
                    Add Billing Code
                    </button>
                </div>
            {showPopup && (
            <div className="add-billing-code-popup">
              <div className="billingInput">
            <h3>Add Billing Codes</h3>
                    <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value.toUpperCase())}
                    placeholder="Name"
                    required
                    />
                    <SearchBox 
                      onCodeSelect={handleDiagnosisCodeSelect} 
                      initialSelectedCodes={diagnosisCode}  // Pass the current diagnosis codes of the billing code being edited
                    />
                    
                    <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Billing Code"
                    required
                    />

                    <input
                        type="text"
                        value={`$${billAmount}`}
                        placeholder="Bill Amount"
                        onChange={(e) => {
                            let value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                            setBillAmount(value);
                        }}
                        required
                    />

                    <input
                    type="text"
                    value={modifierA}
                    onChange={(e) => setModifierA(e.target.value)}
                    placeholder="Modifier A:"

                    />
                    <input
                    type="text"
                    value={modifierB}
                    onChange={(e) => setModifierB(e.target.value)}
                    placeholder="Modifier B:"
                    />

                      <button onClick={handleFormSubmit}>
                          {editingCodeId ? 'Update Billing Code' : 'Add Billing Code'}
                      </button>

                    {error && <div className="error">{error}</div>}
                    <button
                    onClick={() => {
                    resetFormFields();
                    setShowPopup(false);
                    }}
                    >
                    Cancel
                    </button>
                    </div>
                    </div>

                  )}

{!showPopup && (
        <div className="billingCodesContainer">
          {isLoading ? (
            <HexagonSpinner /> // Show spinner while loading
          ) : billingCodes.length > 0 ? (
          billingCodes.map((billingCode) => (
      <div 
          key={billingCode.id}
          className={`Card ${selectedBillingCodes.some(code => code.id === billingCode.id) ? 'selected' : ''}`}
          onClick={() => handleSelect(billingCode.id)}
      >
          <h4>{billingCode.name}</h4>
          <p><strong>Billing Code:</strong> {billingCode.code}</p>
          <p><strong>Diagnosis Code:</strong>
              {Array.isArray(billingCode.diagnosisCode) ? 
                billingCode.diagnosisCode.map((code, index) => (
                    <span key={index}>{code}</span>
                )) : <span>N/A</span>
              }
            </p>
          <p><strong>Bill Amount:</strong> ${billingCode.billAmount ? parseFloat(billingCode.billAmount).toFixed(2) : "0.00"}</p>

          {/* Conditionally render Modifier A */}
          {billingCode.modifierA && (
            <p><strong>Modifier A:</strong> {billingCode.modifierA}</p>
          )}

          {/* Conditionally render Modifier B */}
          {billingCode.modifierB && (
            <p><strong>Modifier B:</strong> {billingCode.modifierB}</p>
          )}
          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button 
              className="delete" 
              style={{ color: 'white'}}
              onClick={(e) => { 
                  e.stopPropagation();  // Prevent triggering the card's click event
                  startEdit(billingCode); 
              }}
              title={'Edit codes'}
          >
              <FontAwesomeIcon className='mr-3' icon={faEdit} />
          </button>
          
          <button 
              className="delete" 
              style={{ color: 'white'}}
              onClick={(e) => { 
                e.stopPropagation();  // Prevent triggering the card's click event
                handleDeleteRequest(e, billingCode.id);  // Open the confirmation modal
              }}
              title={'Delete code'}
            >
              <FontAwesomeIcon className='mr-3' icon={faTrash} />  
            </button>
      </div>

      </div>
      ))
      ) : (
        <div className="no-billing-codes-message">
          <h4>You don't have any codes, please add your billing codes.</h4>
        </div>
      )}
    </div>
    )}
    <Modal
        isOpen={showDeleteConfirmModal}
        onRequestClose={() => setShowDeleteConfirmModal(false)}
        className="confirmModal"
      >
        <h2>Delete Billing Code?</h2>
        <div>
          <button onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </button>
          <button onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </Modal>
    </div>
    );
}
                
export default BillingCodesSelector;
