import React from "react";
import styles from './Website.css';
import { Link } from 'react-router-dom';
import logo from './assetts/Specto_logo_specto.png'
import Footer from "./Footer";
import Header from "./Header";

const PrivacyPolicyPage = () => {
  return (
    <>
    <Header />
    <div className="privacy-policy-container">
         
      <h1>Privacy Policy for specto Health</h1>
      <h3>
        This privacy policy outlines how specto Health collects, uses, and safeguards personal information. specto Health is dedicated to protecting the privacy and security of our users. By utilizing specto Health, you consent to the collection and use of your personal information as described in this privacy policy.
      </h3>
      <h2>Information We Collect</h2>
      <p>
        We collect personal information that you provide to us when you use specto Health. This may include your name, email address, phone number, and other necessary information to provide our healthcare services, such as claim processing and eligibility checks.
      </p>
      <p>
        We may also collect information about your use of specto Health, such as the pages you visit, the features you use, and the time and date of your visits.
      </p>
      <h2>AI-Assisted Document Processing and HIPAA Compliance</h2>
      <p>
      specto Health incorporates state-of-the-art Natural Language Processing (NLP) to maintain the privacy and security of your data. Users have the ability to generate their own AI models by uploading documents to a secure vector database. Before the AI model processes any uploaded document, our NLP system scrubs identifiable patient and provider information, ensuring no protected health information (PHI) is included in the AI training process. This crucial step ensures our compliance with the Health Insurance Portability and Accountability Act (HIPAA) and provides our users with a safe, efficient, and compliant AI customization process.
      </p>
      <h2>AI-Assisted Doctor's Note Generation and Patient Information</h2>
      <p>
        specto Health includes a feature that uses artificial intelligence to assist in the creation of doctor's notes. In utilizing this feature, it's important to note that we prioritize the privacy and security of our users' data. Any patient or provider information needed for the creation of these doctor's notes is not sent to the AI model. Instead, a prompt excluding identifiable patient and provider information is sent to the AI. The patient and provider data is then added later in the process on your local system. This measure ensures that we maintain compliance with the Health Insurance Portability and Accountability Act (HIPAA) and protect your confidential information.
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        We use your personal information to provide our services to you, including processing healthcare claims, checking claim statuses, and enabling communication with healthcare providers. We may also use your information to communicate with you about specto Health, send you relevant updates, and continually improve our services.
      </p>
      <p>
        We do not sell or rent your personal information to third parties.
      </p>
      <h2>How We Protect Your Information</h2>
      <p>
        We employ industry-standard security measures to safeguard your personal information from unauthorized access, use, or disclosure. We also comply with all relevant data protection laws, including the Health Insurance Portability and Accountability Act (HIPAA).
      </p>
      <h2>Data Retention</h2>
      <p>
        We retain your personal information for as long as necessary to provide our services to you and to comply with our legal obligations.
      </p>
      <h2>Your Rights</h2>
      <p>
        You have the right to access and correct your personal information, as well as to request that we delete your personal information. You may also object to the processing of your personal information and request that we restrict the processing of your personal information.
      </p>
      <h2>Third-Party Links</h2>
      <p>
        specto Health may contain links to third-party websites. We are not responsible for the privacy practices of these websites and encourage you to read their privacy policies.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you of any changes by posting the updated privacy policy on our website.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this privacy policy or how we use your personal information, please contact us at support@spectohealth.com.
      </p>
      <p>
        By using specto Health, you agree to this privacy policy. specto Health is HIPAA-compliant and we are committed to protecting the privacy and security of your personal information.
</p>
</div>
  <Footer/ >
</>
);
};

export default PrivacyPolicyPage;
