
import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import * as XLSX from 'xlsx';
import './AddPatient.modules.css';
import { Timestamp } from 'firebase/firestore';
import PayerMatchingModal from './PayerMatching'; // or wherever you save it
import { getFunctions, httpsCallable, } from 'firebase/functions';
import { collection, addDoc, updateDoc, doc, arrayUnion, setDoc, getDoc, query, orderBy, onSnapshot, getDocs  } from "firebase/firestore";
import HexagonSpinner from '../../General/Animations/Hexspinner';
import Confetti from '../../General/Animations/Confetti';

const functions = getFunctions();
import useUID from '../../General/useUID'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

function BatchUploadPopup({ onClose, selectedFolder }) {
    
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const fileInputRef = useRef();
    const [rowErrors, setRowErrors] = useState([]);
    const [isPayerModalOpen, setIsPayerModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [genericErrors, setGenericErrors] = useState([]);
    const [uid, subUserUID] = useUID();
    const [eligibilityCheckModalVisible, setEligibilityCheckModalVisible] = useState(false);
    const [batchEligibilityCost, setBatchEligibilityCost] = useState(0);
    const [processedData, setProcessedData] = useState();
    const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
    const [showPayerMatchingInfo, setShowPayerMatchingInfo] = useState(false);
    const handleDownload = () => {
        const message = document.getElementById('message');
        message.style.display = 'block';
    
        // Call your download function here
        downloadXLSX();
    };
    
    // This function downloads the XLSX file template from Firebase storage
    const downloadXLSX = async () => {
        // Define the path of your template
        const templatePath = 'xlsxtemplates/batchpatients.xlsx';
    
        // Get a reference to the storage service
        const storageRef = ref(storage, templatePath);
    
        // Get the download URL
        const url = await getDownloadURL(storageRef);
    
        // Use that URL to initiate a download
        let link = document.createElement("a");
        link.href = url;
        link.download = "batchpatients.xlsx"; // Set the filename
        document.body.appendChild(link);
        link.click();
    };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            if (e.dataTransfer.items[0].kind === 'file') {
                const droppedFile = e.dataTransfer.items[0].getAsFile();
                handleFile(e, droppedFile);
            }
        }
    
        // Reset the file input
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    

    const handleFile = (e, droppedFile) => {
        const selectedFile = droppedFile ? droppedFile : e.target.files[0];
    
        setRowErrors([]);
        setGenericErrors([]);
        setMessage('');
        setIsPayerModalOpen(false);
        setEligibilityCheckModalVisible(false);
        setProcessedData(null);    
        setFile(selectedFile);
        handleFileChange(selectedFile);
    
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    

    const handleFileChange = async (file) => {
        setIsProcessing(true);
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
    
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];
                const jsonData = XLSX.utils.sheet_to_json(ws);
                const numberOfPatients = jsonData.length;
                await fetchTierAndCalculateCost(numberOfPatients);
    
                try {
                    const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
                    const payerSnapshot = await getDocs(payerMatchesCollection);
                    let payerMap = {};
                    payerSnapshot.docs.forEach(doc => {
                        const payerData = doc.data();
                        payerMap[payerData.name.toUpperCase()] = payerData; // Store entire payer data
                    });
    
                    let hasPayer = false;
                    jsonData.forEach(entry => {
                        if (payerMap[entry.PAYER]) {
                            entry.RealtimePayerID = payerMap[entry.PAYER].payer.RealtimePayerID;
                            hasPayer = true;
                        }
                    });
    
                    const validationErrors = validateData(jsonData, payerMap);
    
                    if (validationErrors.length > 0) {
                        setRowErrors(validationErrors);
                    } else if (hasPayer) {
                        setEligibilityCheckModalVisible(true);
                        setProcessedData(jsonData);
                    } else {
                        await saveToFirestore(jsonData, false);
                    }
                } catch (error) {
                    console.error("Error processing file:", error);
                    setGenericErrors(["Error processing file. Please check the file and try again."]);
                }
                setIsProcessing(false);
            };
            reader.readAsArrayBuffer(file);
        }
    };
    
    const validateData = (data, payerMap) => {
        const errors = [];
        const isDev = process.env.NODE_ENV === 'development';
    
        data.forEach((entry, index) => {
            let rowErrors = [];
    
            // Check if any part of the address is provided
            const isAddressProvided = ['address1', 'city', 'state', 'zip'].some(field => entry[field]);
    
            if (isAddressProvided) {
                // Validate address fields if any part of address is provided
                if (!entry['address1']) rowErrors.push('Address1 is required.');
                if (!entry['city']) rowErrors.push('City is required.');
                if (!entry['state']) rowErrors.push('State is required.');
                if (!entry['zip']) rowErrors.push('Zip is required.');
            }
    
            // Validate required fields
            ['firstName', 'lastName'].forEach(field => {
                if (!entry[field]) {
                    rowErrors.push(`${field} is required.`);
                } else if (typeof entry[field] === 'string') {
                    // Trim the value and update the entry
                    entry[field] = entry[field].trim();
                }
            });
    
            // Validate optional fields, skip if not present
            ['address1', 'address2', 'memberId', 'state', 'payer', 'zip'].forEach(field => {
                if (entry[field] && typeof entry[field] === 'string') {
                    // Trim the value and update the entry
                    entry[field] = entry[field].trim();
                }
            });
    
            // Gender validation
            if (entry['gender'] !== 'M' && entry['gender'] !== 'F') {
                rowErrors.push('Gender must be "M" or "F".');
            }
    
            // DOB validation
            const dob = formatDate(entry['dateOfBirth']);
            if (!dob) {
                rowErrors.push('Date of birth must be in a valid format.');
            } else {
                entry['dateOfBirth'] = dob;
            }
    
            // State validation
            if (entry['state'] && !/^[A-Za-z]{2}$/.test(entry['state'])) {
                rowErrors.push('State must be exactly two letters.');
            }
    
            // Zip validation
            const zipRegex = isDev ? /^\d{9}$/ : /^\d{5}$/;
            if (entry['zip'] && !zipRegex.test(entry['zip'])) {
                const zipLength = isDev ? 'nine' : 'five';
                rowErrors.push(`ZIP must be exactly ${zipLength} numbers with no spaces.`);
            }
    
            // Payer validation
            const payerName = entry['PAYER'] ? entry['PAYER'].toUpperCase() : '';
            if (payerName && !payerMap.hasOwnProperty(payerName)) {
                rowErrors.push(`No payer match found for: ${entry['PAYER']}. Please use a matched payer. Review step 2 above for more information`);
            }
                if (rowErrors.length > 0) {
                    errors.push({ row: index + 1, errors: rowErrors.join(', ') });
                }
            });
        
            return errors;
        };
    


const formatDate = (input) => {
    // If input is a number (Excel's internal date-time value)
    if (typeof input === "number") {
        const excelEpoch = new Date(1899, 11, 30);
        const date = new Date(excelEpoch.getTime() + (input * 24 * 60 * 60 * 1000));
        return `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`;
    }
    // If input is already in mm/dd/yyyy format
    else if (typeof input === "string" && input.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        return input;
    }
    // Any other format is not supported
    else {
        return null;
    }
};

const handleCancelEligibilityCheck = () => {
    setEligibilityCheckModalVisible(false);
    setIsProcessing(false); // Reset processing state
};

const handleEligibilityCheck = async (runCheck, skipCheck = false) => {
    setEligibilityCheckModalVisible(false);
    setIsProcessing(true);

    if (skipCheck) {
        // If eligibility check is skipped, upload patients directly
        await saveToFirestore(processedData, false);
    } else {
        if (!hasRemainingRequests) {
            setError("You have reached the limit of 25 API requests for the free trial.");
            console.log('API request limit reached');
            setLoading(false);
            return;
        }
        await saveToFirestore(processedData, runCheck);
    }
    setIsProcessing(false);
};


const saveToFirestore = async (data, runEligibilityCheckDecision) => {
    // Convert payer names to uppercase in the data before sending
    const processedData = data.map(entry => {
        return {
            ...entry,
            PAYER: entry.PAYER ? entry.PAYER.toUpperCase() : entry.PAYER
        };
    });

    console.log('Data received in saveToFirestore:', processedData);
    try {
        const batchImportFunction = httpsCallable(functions, 'batchUploadToFirestore');
        const response = await batchImportFunction({ 
            jsonData: processedData,  // Send processed data with uppercase payer names
            uid: uid, 
            selectedFolder: selectedFolder,
            runEligibilityCheck: runEligibilityCheckDecision 
        });

        setMessage(response.data.message);
    } catch (error) {
        console.error("An error occurred during batch import:", error);
        setMessage('An error occurred during batch import. Please try again.');
    }
};


useEffect(() => {
    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const uid = auth.currentUser.uid;
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);
    
      const AccountTier = userDocSnap.data().AccountTier || '';
    
      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));
    
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();
    
          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });
    
        if (AccountTier === 'Freebie' && total >= 25) {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });
    
      return () => unsubscribe();
    };
    
    if (auth.currentUser) {
      fetchAccountTierAndCheckRemainingRequests();
    }
    }, [auth.currentUser]);
    
    
    // Function to fetch the account tier and calculate the cost
    const fetchTierAndCalculateCost = async (numberOfPatients) => {
        const accountTierRef = doc(db, `users/${uid}`);
        const accountTierSnap = await getDoc(accountTierRef);
        let tierCharge = 0;
        if (accountTierSnap.exists()) {
            const accountTier = accountTierSnap.data().AccountTier;
            switch (accountTier) {
                case 'Professional':
                    tierCharge = 0.35;
                    break;
                case 'Enterprise':
                    tierCharge = 0.25;
                    break;
                case 'Freebie':
                default:
                    tierCharge = 0
                ;
                break;
        }
    }
    const cost = numberOfPatients * tierCharge;
    setBatchEligibilityCost(cost);
};
    

    return (
        <div className="add-batch-popup">
        {isPayerModalOpen ? <PayerMatchingModal onClose={() => setIsPayerModalOpen(false)} /> : null}
        <div className="stepsContainer">
            <div className="centerHeader">
            <h3>Batch Patient Upload</h3>
            </div>
            <div className="stepsContainer">
            <button id="templateButton" onClick={handleDownload}>Download XLSX Template</button>
            <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
            
            <button 
            title="Match payers in your spreadsheet with those in Specto."
            onClick={() => setIsPayerModalOpen(true)}
            >
            Payer Matching for Template
            </button>
    
            <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
    
            <input
                type="file"
                accept=".xlsx"
                ref={fileInputRef}
                onChange={handleFile}
                style={{ display: "none" }}
            />
            {!isProcessing && (
                <div
                className="dropbox"
                onClick={handleFileInputClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                title="Upload files"
                style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    alignItems: "center", 
                    justifyContent: "center" 
                }}
            >
                <div className='dropBoxContent'>
                    <FontAwesomeIcon icon={faCloudArrowUp}
                        style={{ height: "3rem", marginBottom: ".5rem" }}/>                
                </div>
                <p>Click to browse or drop files in here.</p>
            </div>
            

            )}
            {isProcessing && <HexagonSpinner />}
            </div>
    
            <div id="message" style={{ display: 'none' }}>Please fill out all fields. Do not modify headers.</div>
            {selectedFolder && selectedFolder.name && selectedFolder.name !== "All Patients" && 
            <p>Adding patients to {selectedFolder.name} clinic.</p>}
    
            <div className='genericErrors'>
            {genericErrors.map((error, index) => (
                <p key={index} className="errorText">{error}</p>
            ))}
            </div>
    
            <div className='errorsBatch'>
            <p>{message}</p>
            {rowErrors.length > 0 && (
                <table className="errorTable">
                <thead>
                    <tr>
                    <th>Row</th>
                    <th>Errors</th>
                    </tr>
                </thead>
                <tbody>
                    {rowErrors.map((error, index) => (
                    <tr key={index}>
                        <td>{error.row}</td>
                        <td>{error.errors}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            )}
            </div>
          </div>

            <Modal
                isOpen={eligibilityCheckModalVisible}
                onRequestClose={handleCancelEligibilityCheck}
                className="confirmModal"
            >
                <button onClick={handleCancelEligibilityCheck} className='filesCloseButton'>
                    X
                </button>
                <div className='modalContent'>
                <h3>Do you want to run eligibility on these patients?</h3>
                <p>It will cost ${batchEligibilityCost.toFixed(2)}. Confirming eligibility is recommended for ensuring payment by insurance.</p>
                <div className='confirmButtons'>
                    <button className="primaryButton" onClick={() => handleEligibilityCheck(true)}>
                        Run Batch Eligibility
                    </button>
                    <button className="primaryButton" onClick={() => handleEligibilityCheck(false, true)}>
                        Skip and Upload
                    </button>
                </div>
                </div>
            </Modal>
        </div>
    );
}

export default BatchUploadPopup;