import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../../../../firebase";
import { collection, addDoc, updateDoc, doc, getDoc, arrayUnion, setDoc, getDocs, where, query } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import '../../AddPatient/AddPatient.modules.css';
import SmallLogo from "../../../Auth/smalllogo";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import PersonalInformation from "./PersonalInformation";
import InsuranceInformation from "./InsuranceInformation";
import Appointments from "./Appointments/Appointments";
import { Stepper, Step, StepLabel, Button } from '@mui/material'; // Assuming using Material-UI

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const functions = getFunctions();

function AddPatientIntakeForm() {
  let { clinic } = useParams() || {};

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientNote, setPatientNote] = useState("");
  const [uid, setUid] = useState(null);
  const [token, setToken] = useState(null);
  const [requiredFields, setRequiredFields] = useState({ address: false, payer: false, addInsuranceCardCamera: false, requirePhone: false });
  const [file, setFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [enableAppointments, setEnableAppointments] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const steps = [
    'Personal Information',
    'Insurance Information',
    enableAppointments ? 'Appointments' : null,
    'Form'
  ].filter(Boolean); // Filter out null values if appointments are not enabled

  
  useEffect(() => {
    const fetchOwnerIdAndToken = async () => {
      try {
        // Search for the clinic document where the clinicNameURL matches the 'clinic' from useParams
        const clinicQuery = query(collection(db, "clinics"), where("clinicNameURL", "==", clinic));
        const querySnapshot = await getDocs(clinicQuery);
  
        if (!querySnapshot.empty) {
          const clinicDocData = querySnapshot.docs[0].data(); // Assuming there's only one document that matches
          const ownerId = clinicDocData.ownerId;
          const clinicToken = querySnapshot.docs[0].id;
          
          setClinicId(clinicToken)

          // Extract clinic data including the appointmentsEnabled field
          const clinicData = clinicDocData;
  
          // Update state based on clinic data
          setRequiredFields({
            address: clinicData.requireAddress ?? false,
            addInsuranceCardCamera: clinicData.addInsuranceCardCamera ?? false,
            payer: clinicData.requireInsuranceInfo ?? false,
            phone: clinicData.requirePhone ?? false
          });
  
          // Set enableAppointments based on clinic data
          setEnableAppointments(clinicData.appointmentsEnabled ?? false);
  
          setToken(clinicToken);
          setUid(ownerId);
          setClinicExists(true);
        } else {
          console.error('No such document found with the clinic name:', clinic);
          setClinicExists(false);
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
  
    fetchOwnerIdAndToken();
  }, [clinic]);  // Watch for changes in the 'clinic' variable
  
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  
  const handleAddPatient = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
  
    const missingFields = [];
    
    if (!firstName) missingFields.push("first name");
    if (!lastName) missingFields.push("last name");
    if (!dob) missingFields.push("date of birth");
    if (!gender) missingFields.push("gender");

    if (requiredFields.address && (!address.address1 || !address.city || !address.state || !address.zip)) {
        missingFields.push("address");
    }
    
    if (requiredFields.addInsuranceCardCamera && isCameraAccessible && !file) {
        missingFields.push("picture of insurance card");
    }
    
    if (requiredFields.payer && !selectedPayer) {
        missingFields.push("insurance information");
    }
    
    if (requiredFields.phone && !phone) {
        missingFields.push("phone number");
    }

    if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(', ');
        alert(`Please fill in all required fields, including: ${missingFieldsString}.`);
        setIsLoading(false);
        return;
    }

    try {
      const formattedDob = formatDate(dob);

      const newPatientData = {
        patient: {
        firstName,
        lastName,
        dob: formattedDob,
        gender,
        address,
        clinicToken: token,
        email,
        phone,
        primaryDoctor,
        },
      };

      // Create a new document inside the 'patientData' folder and set the patient data in the 'patient' field
      const patientDoc = await addDoc(collection(db, "patients", uid, "patientData"), newPatientData);
      const patientId = patientDoc.id;

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: 'New patient from intake form',
        activityType: 'intakePatientAdd',
        uid: uid
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      // For handling file upload to Firebase Storage
      if (file) {  
        const filePath = `PatientDocuments/${uid}/${file.name}`;
        const fileRef = storageRef(storage, filePath); // Use storageRef instead of ref
        
        try {
          await uploadBytes(fileRef, file);
                        
          const downloadUrl = await getDownloadURL(fileRef);
          const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
          await addDoc(filesRef, { name: file.name, url: downloadUrl, timestamp: new Date() });
        } catch (error) {
          console.error("Error uploading the file:", error); // Error during the upload
        }
      }

              
        // //Logs
        // const addLogFunction = httpsCallable(functions, 'addLog');
        // await addLogFunction({ uid: auth.currentUser.uid, message: 'Intake form patient added.' });


      // Save the patient note
      const noteContent = `Patient Input: ${patientNote}`;
      const notesRef = collection(db, "patients", uid, "patientData", patientId, "notes");
      await addDoc(notesRef, { text: noteContent, timestamp: new Date()  });

      if (selectedPayer) {
        // Add the member ID to the selectedPayer object
        const payerWithMemberId = { ...selectedPayer, memberId };

        // Update the patient's document with the payer's data
        await updateDoc(doc(db, "patients", uid, "patientData", patientId), { payers: payerWithMemberId });
      }

      // If all the operations above succeed, set the success state to true:
      setSuccess(true);
    } catch (error) {
      console.error("Error adding patient: ", error);
    } finally {
      setIsLoading(false);
    }
  };

 

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
};

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return <PersonalInformation />
    case 1:
      return <InsuranceInformation />
    case 2:
      // This case will only be reached if enableAppointments is true
      return (
        enableAppointments && (
          <Appointments clinicId={clinicId} onNext={handleNext} />        
        )
      );
    case 3:
      return <FormComponent />; // Component for the form
    default:
      return 'Unknown Step';
  }
};


return (
  <div className="IPA-background">
      <div className="IPA-container">
      <div className='IPA-form'>

    {/* Stepper UI */}
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>

    {/* Current Step Content */}
    {getStepContent(activeStep)}

    {/* Navigation Buttons */}
    <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
    <Button variant="contained" onClick={handleNext}>
      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
    </Button>

    {isLoading && <HexagonSpinner />}
    {success && (
      <div className="successMessage">
        <SmallLogo /> <h3>Submission Successful.</h3>
      </div>
    )}
  </div>
  </div>
  </div>

);
}

export default AddPatientIntakeForm;
