import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import { collection, addDoc, updateDoc, doc, arrayUnion, setDoc, getDoc, query, orderBy, onSnapshot  } from "firebase/firestore";
import ProviderSelection from '../../Providers/ProviderSelection';
import PayerSelection from '../../Payers/PayerSelection';
import './AddPatient.modules.css';
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from '../../General/useUID'
import { ProviderProvider } from '../../../context/ProviderContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
const functions = getFunctions();
import Modal from 'react-modal';
import HexagonSpinner from "../../General/Animations/Hexspinner";
import QuicksearchData from '../../Eligibility/QuicksearchData'
import { useNavigate } from "react-router-dom";

function AddPatientPopup({ onClose, selectedFolder, onDataUpdated }) {
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [selectedServiceProvider, setSelectedServiceProviders] = useState(null);
  const [selectedBillingProvider, setSelectedBillingProviders] = useState(null);
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [tradingPartnerId, setTradingPartnerId] = useState("");
  const [payers] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [newPatient, setNewPatient] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [patientNote, setPatientNote] = useState("");
  const [errors, setErrors] = useState({});
  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];
    const [uid, subUserUID] = useUID();
    const [eligibilityCheckModalVisible, setEligibilityCheckModalVisible] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [providerOrganizationName, setProviderOrganizationName] = useState('');
    const [npi, setNPI] = useState('');
    const [currentPatientId, setCurrentPatientId] = useState(null);
    const [currentPatientData, setCurrentPatientData] = useState(null);
    const [eligibilityData, setEligibilityData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(""); // New state for error message
    const [showEligibilityErrorModal, setShowEligibilityErrorModal] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
      if (!uid) return;
      const fetchUserData = async () => {
        const userDocRef = doc(db, "users", uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setProviderOrganizationName(userData.OrganizationName);
            setNPI(userData.NPI);
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      };
  
      if (uid) {
        fetchUserData();
      }
    }, [uid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPatient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const validate = () => {
    const errors = {};

    if (!newPatient.firstName) errors.firstName = "First name is required.";
    if (!newPatient.lastName) errors.lastName = "Last name is required.";
    if (!dob) errors.dob = "Date of birth is required.";
    if (!gender) errors.gender = "Gender is required.";
    if (memberId && !selectedPayer) errors.payer = "Please select a payer.";
    if (selectedPayer && !memberId) errors.memberId = "Please enter member ID.";

    setErrors(errors);

    return Object.keys(errors).length === 0;  // returns true if no errors
  };

  const handleAddPatient = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!validate()) return;
    try {
      const formattedDob = formatDate(dob);
      const newPatientData = {
          patient: {
              ...newPatient,
              dob: formattedDob,
              gender,
              address,
              email,
              phone,
              ...(selectedFolder && selectedFolder.id ? { clinicToken: selectedFolder.id } : {}),
          },
      };

      // Create a new document inside the 'patientData' folder and set the patient data in the 'patient' field
      const patientDoc = await addDoc(collection(db, "patients", uid, "patientData"), newPatientData);
      setCurrentPatientData(newPatientData)
      const patientId = patientDoc.id;
      setCurrentPatientId(patientDoc.id);

      if (selectedPayer) {
        // Construct the payer object with the member ID and systemPayerID
        const payerWithMemberIdAndSystemId = {
          ...selectedPayer,
          memberId,
          name: selectedPayer.name // Ensure this is the correct key for the systemPayerID
        };

        // Update the patient's document with the payer's data
        await updateDoc(doc(db, "patients", uid, "patientData", patientId), { payers: payerWithMemberIdAndSystemId });
      }

      if (selectedPayer) {
        setEligibilityCheckModalVisible(true);
      } else {
        setEligibilityCheckModalVisible(false);
        onClose()
        navigate(`/patients/${patientDoc.id}`);
        onDataUpdated()
      }

    } catch (error) {
          console.error("Error adding patient:", error);
      }
};


  const handlePayerSelect = (payer) => {
    console.log("Selected payer:", payer); // Check if payer data is logged
    setSelectedPayer(payer);
    setTradingPartnerId(payer.tradingPartnerId)
  };


  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };
  
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };
  
  
  const handleEligibilityCheck = async (runCheck) => {
    console.log("Eligibility check for the patient.");
    setEligibilityCheckModalVisible(false);
  
    if (runCheck) {
      if (!hasRemainingRequests) {
        setError("You have reached the limit of 25 API requests for the free trial.");
        console.log('API request limit reached');
        setLoading(false);
        return;
      }
      try {
        console.log(currentPatientData)

        const { firstName, lastName, dob, gender } = currentPatientData.patient;
        const dos = getTodayDate(); // Today's date in YYYYDDMM format
        const payersArray = [{ tradingPartnerId }];
  
        const eligibilityCheckData = {
          firstName,
          lastName,
          dob,
          dos,
          gender,
          memberId,
          payers: payersArray,
          uid,
          organizationName: providerOrganizationName,
          npi,
          patientId: currentPatientId,
        };
        console.log(eligibilityCheckData);
  
        const EligibilitySingleAPI = httpsCallable(functions, 'EligibilitySingleAPI');
        const result = await EligibilitySingleAPI(eligibilityCheckData);
  
        if (result && result.data && result.data.success) {
          console.log("Eligibility check successful: ", result.data);
          setEligibilityData(result.data);
          setIsProcessing(false);
          navigate(`/patients/${currentPatientId}`); 
          onDataUpdated()
        } else {
          setShowEligibilityErrorModal(true); // Show the error modal
          navigate(`/patients/${currentPatientId}`); 
          setIsProcessing(false);
          onDataUpdated()

      }
      } catch (error) {
        console.error("Error in eligibility check: ", error);
        setErrorMessage("An error occurred during the eligibility check.");
        setIsProcessing(false);
      }
    } else {
      console.log("Eligibility check skipped.");
      onClose(); // Close the modal
      navigate(`/patients/${currentPatientId}`);
      setIsProcessing(false);
      onDataUpdated()

    }
  };

  const closeEligibilityErrorModal = () => {
    setShowEligibilityErrorModal(false);
    onClose();
    navigate(`/patients/${currentPatientId}`);
    onDataUpdated()
};


useEffect(() => {
const fetchAccountTierAndCheckRemainingRequests = async () => {
  const uid = auth.currentUser.uid;
  const userDocRef = doc(db, "users", uid);
  const userDocSnap = await getDoc(userDocRef);

  const AccountTier = userDocSnap.data().AccountTier || '';

  const requestsRef = collection(db, "users", uid, "API");
  const q = query(requestsRef, orderBy("timestamp", "desc"));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let total = 0;
    querySnapshot.forEach((doc) => {
      const requestData = doc.data();

      if (requestData.APIRequests) {
        const apiRequestsCount = parseInt(requestData.APIRequests, 10);
        total += apiRequestsCount;
      }
    });

    if (AccountTier === 'Freebie' && total >= 25) {
      setHasRemainingRequests(false);
    } else {
      setHasRemainingRequests(true);
    }
  });

  return () => unsubscribe();
};

if (auth.currentUser) {
  fetchAccountTierAndCheckRemainingRequests();
}
}, [auth.currentUser]);

  


  return (
    <div className="add-patient-popup">
      <div className="add-patient-form">
        {/* <button className='filesCloseButton' onClick={() => { }}>X</button> */}
        <div className="centerHeader">
          <h3>Add Patient</h3>
        </div>
        {/* Display message if a folder is selected and it's not the "All Patients" folder */}
        {selectedFolder && selectedFolder.name && selectedFolder.name !== "All Patients" && (
          <p>Adding patient to {selectedFolder.name} clinic.</p>
        )}

        {/* Personal Information */}
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="firstName">First Name</label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={newPatient.firstName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="input-field">
            <label htmlFor="middleName">Middle Name (optional)</label>
            <input
              id="middleName"
              type="text"
              name="middleName"
              value={newPatient.middleName}
              onChange={handleInputChange}
            />
          </div>

          <div className="input-field">
            <label htmlFor="lastName">Last Name</label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={newPatient.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* Date of Birth and Gender */}
        <div className="input-group">
          <label htmlFor="dob">Date of Birth</label>
          <input
            id="dob"
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
          <div className="gender-buttons">
            <button
              id="genderMale"
              type="button"
              className={`gender-button ${gender === "M" ? "selected" : ""}`}
              onClick={() => setGender("M")}
            >
              M
            </button>
            <button
              id="genderFemale"
              type="button"
              className={`gender-button ${gender === "F" ? "selected" : ""}`}
              onClick={() => setGender("F")}
            >
              F
            </button>
          </div>
        </div>

        {/* Address */}
        <div className="input-group-row">
          {/* Address line 1 and 2 */}
          <div className="input-field">
            <label htmlFor="address1">Address line 1</label>
            <input
              id="address1"
              type="text"
              name="address1"
              value={address.address1}
              onChange={(e) =>
                setAddress({ ...address, address1: e.target.value })
              }
            />
          </div>

          <div className="input-field">
            <label htmlFor="address2">Address line 2</label>
            <input
              id="address2"
              type="text"
              name="address2"
              value={address.address2}
              onChange={(e) =>
                setAddress({ ...address, address2: e.target.value })
              }
            />
          </div>
        </div>
        <div className="input-group-row">
          {/* City, State, and Zip */}
          <div className="input-field">
            <label htmlFor="city">City</label>
            <input
              id="city"
              type="text"
              name="city"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
            />
          </div>

          <div className="input-field">
            <label htmlFor="state">State</label>
            <select
              id="state"
              name="state"
              value={address.state}
              onChange={(e) => setAddress({ ...address, state: e.target.value })}
              required
            >
              <option value="">Select a State</option> {/* Default option */}
              {states.map(s => <option key={s} value={s}>{s}</option>)}
            </select>
          </div>

          <div className="input-field">
            <label htmlFor="zip">Zip</label>
            <input
              id="zip"
              type="text"
              name="zip"
              maxLength={process.env.NODE_ENV === "development" ? "9" : "5"}
              pattern={process.env.NODE_ENV === "development" ? "^\\d{9}$" : "^\\d{5}$"}
              title={process.env.NODE_ENV === "development" ? "ZIP should be exactly 9 digits in development mode." : "ZIP should be exactly 5 digits in production mode."}
              value={address.zip}
              onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              className="zipInput"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="email">Email (optional)</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-field">
            <label htmlFor="phone">Phone (optional)</label>
            {/* Ensure the `InputMask` component can receive an id prop */}
            <InputMask
              id="phone"
              mask="(999) 999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="patientNote">Notes</label>
          <textarea
            id="patientNote"
            name="patientNote"
            value={patientNote}
            onChange={(e) => setPatientNote(e.target.value)}
            placeholder="Enter your note"
          ></textarea>
        </div>


        {/* Member ID */}
        <div className="input-group">
          <label htmlFor="memberId">Member ID</label>
          <input
            id="memberId"
            type="text"
            name="memberId"
            value={memberId}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
              setMemberId(sanitizedValue);
            }}
          />
        </div>


        {/* <ProviderProvider uid={uid}>

          <div className="input-group">
            <h3>Service Provider</h3>
            <ProviderSelection
              type="service"
              onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
                setSelectedServiceProviders({ organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode });
              }}
            />
          </div>
          <div className="input-group">
            <h3>Billing Provider</h3>
            <ProviderSelection
              type="billing"
              onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
                setSelectedBillingProviders({ organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode });
              }}
            />
          </div>

        </ProviderProvider> */}

        {/* Payer Selection */}
        <div className="input-group-payerSelection">
          <PayerSelection
            onSelect={handlePayerSelect}
            required
            mode={'claimStatus'}
          />
        </div>
        
         <div className="content-center">
          {errors.firstName && <p className="error">{errors.firstName}</p>}
          {errors.lastName && <p className="error">{errors.lastName}</p>}
          {errors.dob && <p className="error">{errors.dob}</p>}
          {errors.gender && <p className="error">{errors.gender}</p>}
          {errors.payer && <p className="error">{errors.payer}</p>}
          {errors.memberId && <p className="error">{errors.memberId}</p>}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>

        {!isProcessing && (
        
        <div className="addPatientButton">
          <button type='submit' onClick={handleAddPatient} className="primary"><FontAwesomeIcon icon={faUserPlus} />  Add Patient</button>
        </div>
        )}

        {isProcessing && (
                    <HexagonSpinner />
        )}

        
        {eligibilityData && (
                <div className='patientDataPopupContainer'>
                  <QuicksearchData onClose={() => { setEligibilityData(null); onClose(); }} patientData={eligibilityData}/>
                </div>
              )}

        <Modal
            isOpen={eligibilityCheckModalVisible}
            onRequestClose={() => setEligibilityCheckModalVisible(false)}
            className="confirmModal"
        >
            <h3>Do you want to run eligibility on this patient?</h3>
            <p>Confirming eligibility is recommended for ensuring payment by insurance.</p>
            <div className='confirmButtons'>
                <button className="secondaryButton" onClick={() => handleEligibilityCheck(false)}>
                    Skip
                </button>
                <button className="primaryButton" onClick={() => handleEligibilityCheck(true)}>
                    Run
                </button>
            </div>
        </Modal>

        <Modal
            isOpen={showEligibilityErrorModal}
            onRequestClose={() => setShowEligibilityErrorModal(false)}
            className="confirmModal"
        >
            <h3>No Eligibility Found</h3>
            <p>Please check the eligibility tab on the patient's file for more information.</p>
            <button 
              className="primaryButton" 
              onClick={closeEligibilityErrorModal}
              >
              Close
            </button>
        </Modal>
      </div>
    </div>
  );


}

export default AddPatientPopup;
