import React, { useState } from 'react';
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';

function ImmunizationsAllergies({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [immunizationsAllergies, setImmunizationsAllergies] = useState({
    immunizations: '',
    allergies: '',
    notes: ''
  });

  const handleChange = (e) => {
    setImmunizationsAllergies({
      ...immunizationsAllergies,
      [e.target.name]: e.target.value
    });
  };

  const isFormEmpty = () => {
    return !immunizationsAllergies.immunizations && !immunizationsAllergies.allergies;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in the immunizations or allergies field.");
      return;
    }

    const medicalHistoryRef = collection(
      doc(db, 'patients', uid, 'patientData', patientId),
      'medicalHistory'
    );

    try {
      await addDoc(medicalHistoryRef, { type: 'ImmunizationsAllergies', timestamp: serverTimestamp(), ...immunizationsAllergies });
      console.log('Immunizations and Allergies added:', immunizationsAllergies);

      setImmunizationsAllergies({
        immunizations: '',
        allergies: '',
        notes: ''
      });
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className={styles.immunizationsAllergiesForm}>
      <form onSubmit={handleSubmit}>
      <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="immunizations">Immunizations:</label>
          <textarea
            id="immunizations"
            name="immunizations"
            value={immunizationsAllergies.immunizations}
            onChange={handleChange}
            placeholder="List all immunizations with dates if possible"
          />
        </div>
        </div>
        <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="allergies">Allergies:</label>
          <textarea
            id="allergies"
            name="allergies"
            value={immunizationsAllergies.allergies}
            onChange={handleChange}
            placeholder="List all known allergies"
          />
        </div>
        </div>

        <button type="submit" className={styles.submitButton} disabled={isFormEmpty()}>
          Submit Immunizations & Allergies
        </button>
      </form>
    </div>
  );
}

export default ImmunizationsAllergies;
